import services from "@/http";
import { http } from "@/http";
// import * as types from "./mutation-types";

export const ActionGetListUsuarios = () => {
    return services.usuario.all().then(r => {
        return r.data;
    });
};
export const ActionGetListUsuariosAtivos = () => {
    return services.usuario.active().then(r => {
        return r.data;
    });
};
export const ActionGetUsuario = (context, payload) => {
    return services.usuario.obter({ id: payload }).then(r => {
        return r.data;
    });
};

export const ActionAlterPassword = (context, payload) => {

    let url = `usuario/password?pass=true`;
    return http.put(url, payload).then(r => r.data);
    // return services.usuario.updatePassword(payload).then(r => {
    //     return r.data;
    // });
};




export const ActionAlterUserCompleto = (context, payload) => {

    let url = `usuario/password`;
    return http.put(url, payload).then(r => r.data);
    // return services.usuario.updatePassword(payload).then(r => {
    //     return r.data;
    // });
};


export const ActionCreateUser = (context, payload) => {
    console.log(payload);
    return services.usuario.create(payload).then(r => {
        return r.data;
    });
};

export const ActionInativaUser = (context, payload) => {
    return services.usuario.inactive({ id: payload }).then(r => {
        return r.data;
    });
};