<template>
  <!--begin::Content-->
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Subheader-->
    <sub-header :titulo="titulo" :buttons="buttons"></sub-header>
    <!--end::Subheader-->
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Dashboard-->
        <!--begin::Row-->
        <div
          class="alert alert-custom alert-white alert-shadow fade show gutter-b"
          role="alert"
        >
          <div class="alert-icon">
            <span class="svg-icon svg-icon-primary svg-icon-2x">
              <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Search.svg-->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <rect x="0" y="0" width="24" height="24" />
                  <path
                    d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                    fill="#000000"
                    fill-rule="nonzero"
                    opacity="0.3"
                  />
                  <path
                    d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                    fill="#000000"
                    fill-rule="nonzero"
                  />
                </g>
              </svg>
              <!--end::Svg Icon-->
            </span>
          </div>
          <div class="alert-text">
            <div
              class="input-daterange input-group col-lg-6"
              id="kt_datepicker_5"
            >
              <input
                type="date"
                v-model="dataInicio"
                class="form-control"
                name="start"
              />
              <div class="input-group-append">
                <span class="input-group-text">
                  <i class="la la-ellipsis-h"></i>
                </span>
              </div>
              <input
                type="date"
                v-model="dataFim"
                class="form-control"
                name="end"
              />
            </div>
          </div>
          <div class="alert-text col-lg-3">
            <select-populado
              :data="conta"
              :list="dadosFiltro"
              :fieldKey="'id'"
              :fieldText="'text'"
              @onChange="changConta"
            />
          </div>
        </div>
        <ListagemChecked
          :dados="dados"
          :colunas="colunaPadrao"
          :colunasDetalhes="colunas"
          :monta="monta"
          :canBtnDownload="true"
        />

        <!-- Button trigger modal-->
        <!-- <button
          type="button"
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModal"
        >Visualizar Funcionário</button>-->

        <!-- Modal-->

        <!--end::Row-->
        <!--end::Dashboard-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
  <!--end::Content-->
</template>
<script>
import SubHeader from "../../components/layout/SubHeader";
import ListagemChecked from "../../components/forms/ListagemChecked.vue";
import SelectPopulado from "../../components/forms/SelectPopulado.vue";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    SubHeader,
    ListagemChecked,
    SelectPopulado,
  },
  data: () => ({
    titulo: "Arquivos Anexados",
    buttons: [],
    colunaPadrao: [
      /*       {
        label: "Caminho",
        field: "url",
      }, */

      {
        label: "Nome/Vencimento",
        field: "nome",
      },
      {
        label: "Data",
        field: "data_inclusao",
      },
      {
        label: "Conta",
        field: "conta",
      },
      {
        label: "Ação",
        field: "id_anexo",
      },
    ],
    colunas: [
      { nome: "Nome/Vencimento", index: "nome", isHtml: false },
      { nome: "Data", index: "data_inclusao", isHtml: false },
      { nome: "Conta", index: "conta", isHtml: false },
    ],
    dados: [],
    dadosFiltro: [
      { id: "TODOS", text: "TODOS" },
      { id: "PAGOS", text: "PAGOS" },
      { id: "RECEBIDOS", text: "RECEBIDOS" },
    ],
    monta: false,
    conta: "TODOS",
    dataFim: null,
    dataInicio: null,
  }),
  mounted() {
    let data = new Date();
    data = new Date(data.getFullYear(), data.getMonth(), 1);
    let data2 = new Date(data.getFullYear(), data.getMonth() + 1, 1);
    data2 = data2.setDate(data2.getDate() - 1);
    this.dataInicio = this.$moment(data).format("YYYY-MM-DD");
    this.dataFim = this.$moment(data2).format("YYYY-MM-DD");
    this.load();
  },
  computed: {
    ...mapState("auth", ["empresa"]),
  },
  methods: {
    ...mapActions("anexo", ["ActionGetListAnexo"]),
    changConta(item) {
      this.conta = item;
    },
    load() {
      window.KTApp.block("#kt_blockui_content", {
        overlayColor: "#000000",
        state: "primary",
        message: "Processando...",
      });
      this.ActionGetListAnexo().then((r) => {
        this.dados = r;
        this.dados.forEach((el) => {
          el["conta"] = el.movimentacao.id_conta_pagar ? "PAGOS" : "RECEBIDOS";
          el["data"] = el.data_inclusao;
          el.data_inclusao = this.$moment(el.data_inclusao).format(
            "DD/MM/YYYY"
          );
        });
        this.dados = this.dados.filter((f) => {
          return f.data >= this.dataInicio && f.data <= this.dataFim;
        });
        if (this.conta != "" && this.conta != "TODOS") {
          this.dados = this.dados.filter((f) => f.conta == this.conta);
        }

        this.monta = true;
        window.KTApp.unblock("#kt_blockui_content");
      });
    },
  },
  watch: {
    empresa(nValue) {
      this.empresa = nValue;
      this.load();
    },
    dataFim(nValue) {
      this.dataFim = nValue;
      this.load();
    },
    dataInicio(nValue) {
      this.dataInicio = nValue;
      this.load();
    },
    conta(nValue) {
      this.conta = nValue;
      this.load();
    },
  },
};
</script>