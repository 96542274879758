import store from "../store";
export default function (request, next) {
    next(function (response) {
        if (response.status === 401) {
            store.dispatch("auth/ActionSingnout");
            window._Vue.$swal.fire({
                icon: "warning",
                title: "Aviso",
                text: "Sua sessão expirou, favor logar novamente no sistema"
            }).then(() => {
                window._Vue.$router.push("/login");
            });

        } else if (response.status != 200) {

            if (response.data) {
                console.log(response.data);
                window._Vue.$swal.fire({
                    icon: "error",
                    title: response.data.error.title || "Oops...",
                    text: response.data.error.message,
                });
            } else {
                window._Vue.$swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Ocorreu um erro inesperado, favor contactar o suporte.",
                });
            }
            window.KTApp.unblock("#kt_blockui_content");
        }

    })

}