import services from "@/http";
import { http } from "@/http";
// import * as types from "./mutation-types";

export const ActionGetListFuncionarios = (context) => {
    return http.get(`funcionario/all?id_empresa=${context.rootState.auth.empresa.id_empresa}`).then(r => {
        return r.data;
    });
};

export const ActionGetListFuncionarioActive = (context) => {
    return http.get(`funcionario/active?id_empresa=${context.rootState.auth.empresa.id_empresa}`).then(r => {
        return r.data;
    });
};


export const ActionGetFuncionario = (context, payload) => {
    return services.funcionario.get({ id: payload }).then(r => {
        return r.data;
    });
};

export const ActionUpdateFuncionario = (context, payload) => {
    return services.funcionario.update(payload).then(r => {
        return r.data;
    });
};

export const ActionCreateFuncionario = (context, payload) => {
    return services.funcionario.create(payload).then(r => {
        return r.data;
    });
};


export const ActionInactiveFuncionario = (context, payload) => {
    return services.funcionario.inactive({ id: payload }).then(r => {
        return r.data;
    });
};

export const ActionUploadFoto = (context, payload) => {

    return http.post("funcionario/anexo/" + payload.id, payload.foto, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(r => r.data);


    // return services.funcionario.upload({ id: id }, foto).then(r => {
    //     return r.data;
    // });
};