import services from "@/http";
import * as storage from "../storage";
import * as types from "./mutation-types";


export const ActionDoLogin = ({ dispatch }, payload) => {
    return services.auth.login(payload).then(r => {
        dispatch("ActionSetUser", r.data.dados);
        dispatch("ActionSetToken", r.data.token);
        dispatch("ActionSetMantem", { ...payload.mantem });
        dispatch("ActionSetEmpresa", { ...r.data.dados.empresasHabilitadas.map((x) => x.empresa)[0] });
    });
};
export const ActionCheckToken = ({ dispatch, state }) => {
    const token = storage.getLocalToken();
    if (state.token) {
        return Promise.resolve(state.token);
    } else
        if (!token) {
            return Promise.reject(new Error("Token Inválido"));
        } else {
            dispatch("ActionSetToken", token);
            dispatch("ActionSetUser", storage.getLocalUsuario());
            dispatch("ActionSetEmpresa", { ...storage.getLocalEmpresa() });
            return Promise.resolve(token);
        }
}

export const ActionSetUser = ({ commit }, payload) => {
    storage.setLocalUsuario(payload);
    commit(types.SET_USER, payload);
};

export const ActionSetMantem = ({ commit }, payload) => {
    commit(types.SET_MANTEM_CONECTADO, payload);
};


export const ActionSetEmpresa = ({ commit }, payload) => {
    storage.setLocalEmpresa(payload);
    commit(types.SET_EMPRESA, { ...payload });
};

export const ActionGetEmpresa = ({ state }) => {
    return state.empresa;
};


export const ActionSetMantemConectado = ({ commit }, payload) => {
    commit(types.SET_MANTEM_CONECTADO, payload);
};



export const ActionSetToken = ({ commit }, payload) => {
    storage.setLocalToken(payload);
    storage.setHeaderToken(payload);
    commit(types.SET_TOKEN, payload);
};
export const ActionSingnout = ({ dispatch }) => {
    storage.setHeaderToken("");
    storage.deleteLocalToken();
    storage.deleteLocalUsuario();
    dispatch("ActionSetUser", {});
    dispatch("ActionSetToken", "");

};


export const ActionForgotPassword = (context, payload) => {
    return services.auth.forgotPass(payload).then(r => {
        return r.data;
    });
};
