import services from "@/http";
// import * as types from "./mutation-types";

export const ActionGetListTipoRecebimento = () => {
    return services.tipoRecebimento.all().then(r => {
        return r.data;
    });
};

export const ActionGetListTipoRecebimentoActive = () => {
    return services.tipoRecebimento.active().then(r => {
        return r.data;
    });
};


export const ActionGetTipoRecebimento = (context, payload) => {
    return services.tipoRecebimento.get({ id: payload }).then(r => {
        return r.data;
    });
};

export const ActionUpdateTipoRecebimento = (context, payload) => {
    return services.tipoRecebimento.update(payload).then(r => {
        return r.data;
    });
};

export const ActionCreateTipoRecebimento = (context, payload) => {
    return services.tipoRecebimento.create(payload).then(r => {
        return r.data;
    });
};


export const ActionInactiveTipoRecebimento = (context, payload) => {
    return services.tipoRecebimento.inactive({ id: payload }).then(r => {
        return r.data;
    });
};