<template>
  <div class="card card-custom">
    <div class="card-body" id="kt_blockui_content">
      <div
        class="card-toolbar"
        style="text-align: right; margin: 6px; margin-top: -10px"
      >
        <button
          v-if="canBtnDownload"
          :disabled="!selected"
          type="button"
          class="btn btn-outline-info btn-sm"
          @click="baixarArquivos()"
        >
          <i class="fa fa-download fa-2x"></i>
          <span class="nav-text">DOWNLOAD TODOS</span>
        </button>
      </div>
      <!-- :fixed-header="true" -->
      <vue-good-table
        @on-selected-rows-change="selectionChanged"
        :columns="colunas"
        :rows="dadosFiltro"
        :select-options="{
          enabled: true,
          selectionText: 'linha(s) selecionadas',
          clearSelectionText: 'limpar',
          selectOnCheckboxOnly: true,
        }"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          nextLabel: 'Próximo',
          prevLabel: 'Anterior',
          ofLabel: 'de',
          rowsPerPageLabel: 'Linhas por página',
          perPageDropdown: [10, 20, 50],
          pageLabel: 'página',
          allLabel: 'Todos',
        }"
        :search-options="{
          enabled: true,
          placeholder: 'Pesquisar',
        }"
        styleClass="table table-bordered table-hover table-checkable marginApoio"
      >
        <div slot="emptystate" class="text-center">Não há registros</div>
        <template slot="table-row" slot-scope="props">
          <span v-if="isAction(props)">
            <a
              v-if="canDownload == true"
              id="listaVer"
              class="btn btn-sm btn-clean btn-icon"
              title="Download"
              @click="DownloadUnico(props.row)"
            >
              <i class="fa fa-download"></i>
            </a>
          </span>
          <span v-else-if="isHTMLField(props.column)">
            <span v-html="montarField(props.row, props.column)"></span>
          </span>
          <span v-else>{{ montarField(props.row, props.column) }}</span>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

     
<script>
function resolve(path, obj) {
  return path.split(".").reduce(function (prev, curr) {
    return prev ? prev[curr] : null;
  }, obj || self);
}

import axios from "axios";
export default {
  props: {
    dados: Array,
    colunas: Array,
    colunasDetalhes: Array,
    canDownload: {
      type: Boolean,
      default: true,
    },
    canBtnDownload: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    ativo: true,
    dadosFiltro: [],
    selected: false,
    dadosAnexo: [],
  }),

  methods: {
    isAction(props) {
      return props.column.label == "Ação";
    },
    selectionChanged(item) {
      if (item.selectedRows.length > 0) {
        this.selected = true;
        this.dadosAnexo = item.selectedRows;
      } else {
        this.selected = false;
        this.dadosAnexo = [];
      }
    },
    async baixarArquivos() {
      let values = this.dadosAnexo;
      for (let iterator of values) {
        let dados = iterator.url_fisica.split("/");
        if (dados.length == 1) {
          dados = iterator.url_fisica.split("\\");
        }

        iterator.filename = dados[dados.length - 1];
        let response = await axios({
          url: iterator.url,
          method: "GET",
          responseType: "blob",
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", iterator.filename);
        document.body.appendChild(link);
        link.click();
      }
      // values.forEach((el) => {
      //   const link = document.createElement("a");
      //   link.target = "_blank";
      //   link.href = el.url;
      //   link.download = true;
      //   link.click();
      //   URL.revokeObjectURL(link.href);
      // });

      console.log(values);
    },
    async DownloadUnico(item) {
      let dados = item.url_fisica.split("/");
      if (dados.length == 1) {
        dados = item.url_fisica.split("\\");
      }

      var filename = dados[dados.length - 1];
      axios({
        url: item.url,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      });
    },

    montarField(rows, cols) {
      let apx = this.colunasDetalhes.find((f) => f.nome == cols.label);

      if (apx.isHtml == true) {
        let colStrinfg = apx.html;
        apx.colsFormatar.forEach((element, index) => {
          colStrinfg = colStrinfg.replace(`{${index}}`, resolve(element, rows));
        });
        return colStrinfg;
      } else {
        return resolve(apx.index, rows);
      }
    },
    isHTMLField(cols) {
      let apx = this.colunasDetalhes.find((f) => f.nome == cols.label);
      if (apx.isHtml == true) {
        return apx.isHtml;
      } else {
        return false;
      }
    },
    AplicarHTML(col, item) {
      if (col.isHtml == true) {
        let colStrinfg = col.html;
        col.colsFormatar.forEach((element, index) => {
          colStrinfg = colStrinfg.replace(`{${index}}`, resolve(element, item));
        });
        return colStrinfg;
      } else {
        return "";
      }
    },
    montarTela(col, item) {
      return col.isHtml == false ? resolve(col.index, item) : "";
    },
  },

  mounted() {
    if (this.canBtnAtivo) {
      this.dadosFiltro = this.dados.filter((f) => f.ativo == this.ativo);
    } else {
      this.dadosFiltro = this.dados;
    }
  },

  watch: {
    dados(nValue) {
      if (nValue) {
        this.dados = nValue;
        if (this.canBtnAtivo) {
          this.dadosFiltro = this.dados.filter((f) => f.ativo == this.ativo);
        } else {
          this.dadosFiltro = this.dados;
        }
      }
    },
    colunas(nValue) {
      if (nValue) {
        this.colunas = nValue;
      }
    },
    colunasDetalhes(nValue) {
      if (nValue) {
        this.colunas = nValue;
      }
    },
  },
};
</script>
<style >
.marginApoio {
  margin-top: 13px !important;
}
</style>