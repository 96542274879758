import services from "@/http";
// import * as types from "./mutation-types";

export const ActionGetListContaReceber = () => {
    return services.contaReceber.all().then(r => {
        return r.data;
    });
};

export const ActionGetListContaReceberActive = () => {
    return services.contaReceber.active().then(r => {
        return r.data;
    });
};


export const ActionGetContaReceber = (context, payload) => {
    return services.contaReceber.get({ id: payload }).then(r => {
        return r.data;
    });
};

export const ActionUpdateContaReceber = (context, payload) => {
    return services.contaReceber.update(payload).then(r => {
        return r.data;
    });
};

export const ActionCreateContaReceber = (context, payload) => {
    return services.contaReceber.create(payload).then(r => {
        return r.data;
    });
};

export const ActionCreateConciliadoContaReceber = (context, payload) => {
    return services.contaReceber.createConciliado(payload).then(r => {
        return r.data;
    });
};

export const ActionInactiveContaReceber = (context, payload) => {
    return services.contaReceber.inactive({ id: payload.id_conta, idMov: payload.id_movimentacao }).then(r => {
        return r.data;
    });
};