<template>
  <!--begin::Content-->
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Subheader-->
    <sub-header :titulo="titulo" :buttons="buttons"></sub-header>
    <!--end::Subheader-->
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Row-->

        <div class="row">
          <div class="col-lg-12">
            <div class="card card-custom gutter-b example example-compact">
              <div class="card-header">
                <h3 class="card-title">1. Cadastrando Perfil de Acesso</h3>
                <div class="card-toolbar"></div>
              </div>
              <!--begin::Form-->
              <form class="form">
                <div class="card-body">
                  <div class="form-group row">
                    <div class="col-lg-4">
                      <label>Usuário:</label>
                      <select-populado
                        :action="'usuario/ActionGetListUsuariosAtivos'"
                        :data="idUsuario"
                        :fieldKey="'id_usuario'"
                        :fieldText="'login'"
                        @onChange="changeUsuario"
                      />
                    </div>
                  </div>
                  <div v-for="(item,index) of menu" :key="index">
                    <div class="form-group row">
                      <div class="col-lg-11">
                        <br />
                        <br />
                        <label>Acessos:</label>
                        <b>&nbsp;&nbsp; {{item.menu}}</b>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div
                        v-for="(el,idx) of item.funcionalidades"
                        :key="idx"
                        class="checkbox-list col-lg-2"
                        style="margin-top: 32px;"
                      >
                        <label class="checkbox">
                          <input type="checkbox" name="Checkboxes1" v-model="el.checked" />
                          <span></span>
                          {{el.descricao}}
                        </label>
                        <label class="checkbox checkbox-disabled">
                          <input
                            type="checkbox"
                            disabled="disabled"
                            checked="checked"
                            name="Checkboxes1"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card-footer">
                  <div class="row">
                    <div class="col-lg-12 ml-lg-auto">
                      <button @click="gravar()" type="button" class="btn btn-primary mr-2">Adicionar</button>
                      <button type="reset" class="btn btn-secondary">Cancelar</button>
                    </div>
                  </div>
                </div>
              </form>
              <!--end::Form-->
            </div>
          </div>
        </div>

        <!--end::Dashboard-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
  <!--end::Content-->
</template>
<script>
import SubHeader from "../../components/layout/SubHeader";
import SelectPopulado from "../../components/forms/SelectPopulado";
import * as _ from "lodash";
import { mapActions, mapState } from "vuex";
export default {
  data: () => ({
    obj: {
      id_usuario: 0,
    },
    funcionalidades: [],
    funcionalidadesUsuario: [],
    titulo: "Perfil de Acesso",
    buttons: [],
    usuario: {},
  }),
  computed: {
    ...mapState("auth", ["user"]),
    idUsuario: {
      set(item) {
        this.obj.id_usuario = item;
      },
      get() {
        return this.obj.id_usuario;
      },
    },
    menu() {
      let dados = [];

      this.funcionalidadesUsuario.forEach((el) => {
        this.funcionalidades.find(
          (x) => x.id_funcionalidade == el.id_funcionalidade
        )["checked"] = true;
        this.funcionalidades.find(
          (x) => x.id_funcionalidade == el.id_funcionalidade
        )["id_usuario_funcionalidade"] = el.id_usuario_funcionalidade;
      });

      let initial = _.groupBy(this.funcionalidades, "menu");
      for (let i in initial) {
        dados.push({
          menu: i,
          funcionalidades: initial[i],
        });
      }
      return dados;
    },
  },
  components: {
    SubHeader,
    SelectPopulado,
  },
  methods: {
    ...mapActions("perfilAcesso", [
      "ActionGetFuncionalidades",
      "ActionPostFuncionalidadeUsuario",
    ]),
    async changeUsuario(item) {
      console.log(item);
      this.usuario = await this.$store.dispatch(
        "usuario/ActionGetUsuario",
        item
      );
      this.funcionalidadesUsuario = this.usuario.funcionalidadesHabilitadas;
    },
    async gravar() {
      try {
        let obj = {
          idUsuario: this.usuario.id_usuario,
          funcionalidades: this.funcionalidades.filter(
            (f) => f.checked == true
          ),
        };

        let r = await this.ActionPostFuncionalidadeUsuario(obj);
        this.$swal
          .fire({
            icon: r.sucesso == true ? "success" : "error",
            title: r.sucesso == true ? `Sucesso` : "Atenção / Erro",
            text:
              r.sucesso == true
                ? "Perfil do Usuário atualizado com suscesso"
                : r.error.message,
          })
          .then(() => {
            location.reload();
          });
      } catch (error) {
        if (error.status != 401) {
          if (error.data) {
            console.log(error.data);
            this.$swal.fire({
              icon: "error",
              title: error.data.error.title || "Oops...",
              text: error.data.error.message,
            });
          } else {
            this.$swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Ocorreu um erro inesperado, favor contactar o suporte.",
            });
          }
        }
      }
    },
  },
  async mounted() {
    this.funcionalidades = await this.ActionGetFuncionalidades();
  },
  watch: {
    idUsuario(nvalue) {
      if (nvalue) {
        this.obj.id_usuario = nvalue;
        this.$store.dispatch("usuario/ActionGetUsuario").then((f) => {
          this.usuario = f;
        });
      }
    },
  },
};
</script>