import services from "@/http";
// import * as types from "./mutation-types";

export const ActionGetListFormasPagamento = () => {
    return services.formasPagamento.all().then(r => {
        return r.data;
    });
};

export const ActionGetListFormasPagamentoActive = () => {
    return services.formasPagamento.active().then(r => {
        return r.data;
    });
};


export const ActionGetFormasPagamento = (context, payload) => {
    return services.formasPagamento.get({ id: payload }).then(r => {
        return r.data;
    });
};

export const ActionUpdateFormasPagamento = (context, payload) => {
    return services.formasPagamento.update(payload).then(r => {
        return r.data;
    });
};

export const ActionCreateFormasPagamento = (context, payload) => {
    return services.formasPagamento.create(payload).then(r => {
        return r.data;
    });
};


export const ActionInactiveFormasPagamento = (context, payload) => {
    return services.formasPagamento.inactive({ id: payload }).then(r => {
        return r.data;
    });
};