<template>
  <!--begin::Content-->
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Subheader-->
    <sub-header :titulo="titulo" :buttons="buttons"></sub-header>
    <!--end::Subheader-->
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Dashboard-->
        <!--begin::Row-->

        <Listagem
          :dados="dados"
          :colunas="colunaPadrao"
          :colunasDetalhes="colunas"
          :monta="monta"
          :exportacao="exportarLista"
          :canInativar="user.leitura != true"
          @onVisualizar="visualizar"
          @onDelete="inativar"
          @onEditar="editar"
        />

        <!-- Button trigger modal-->
        <!-- <button
          type="button"
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModal"
        >Visualizar Funcionário</button>-->

        <!-- Modal-->

        <div
          class="modal fade bd-example-modal-xl"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myExtraLargeModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-xl modal-dialog-scrollable"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Visualizando Empresa
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i aria-hidden="true" class="ki ki-close"></i>
                </button>
              </div>
              <div class="modal-body" style="height: 500px">
                <div class="container">
                  <b>1. Informações Pessoais</b>
                  <hr />
                  <br />

                  <div>
                    <img
                      v-if="
                        empresaVer.url_logo != undefined &&
                        empresaVer.url_logo != null
                      "
                      :src="
                        empresaVer.url_logo ? empresaVer.url_logo : undefined
                      "
                      height="60"
                    />
                  </div>
                  <br />
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td colspan="2">
                          <b
                            v-if="
                              empresaVer.pessoa.tipo_pessoa == 'Pessoa Jurídica'
                            "
                            >Nome Fantasia:</b
                          >
                          <b
                            v-if="
                              empresaVer.pessoa.tipo_pessoa == 'Pessoa Física'
                            "
                            >Nome</b
                          >
                          {{ empresaVer.pessoa.nome }}
                        </td>
                        <td
                          colspan="2"
                          v-if="
                            empresaVer.pessoa.tipo_pessoa == 'Pessoa Jurídica'
                          "
                        >
                          <b>Responsável:</b>
                          {{ empresaVer.responsavel }}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table
                    class="table table-bordered"
                    v-if="empresaVer.pessoa.tipo_pessoa == 'Pessoa Jurídica'"
                  >
                    <tbody>
                      <tr>
                        <td colspan="2">
                          <b>Razão Social:</b>
                          {{ empresaVer.pessoa.razao_social }}
                        </td>

                        <td>
                          <b>CNPJ:</b>
                          {{ empresaVer.pessoa.identificador }}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <b>Inscrição Estadual:</b>
                          {{ empresaVer.pessoa.insc_estadual }}
                        </td>
                        <td>
                          <b>Inscrição Municipal:</b>
                          {{ empresaVer.pessoa.insc_municipal }}
                        </td>
                        <td>
                          <b>Optante pelo simples:</b>
                          {{ empresaVer.optante_simples ? "Sim" : "Não" }}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table
                    class="table table-bordered"
                    v-if="empresaVer.pessoa.tipo_pessoa == 'Pessoa Física'"
                  >
                    <tbody>
                      <td>
                        <b>Data de Nascimento:</b>
                        {{ empresaVer.pessoa.data_nascimento }}
                      </td>
                      <td>
                        <b>CPF:</b>
                        {{ empresaVer.pessoa.identificador }}
                      </td>
                      <td>
                        <b>RG:</b>
                        {{ empresaVer.pessoa.rg }}
                      </td>
                    </tbody>
                  </table>

                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td colspan="4">
                          <b>Observação:</b>
                          {{ empresaVer.observacao }}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br />
                  <b>2. Informações de Contato</b>
                  <hr />
                  <br />

                  <table class="table table-bordered">
                    <tbody>
                      <b>Email(s):</b>
                      <tr
                        v-for="item of empresaVer.pessoa.emails"
                        :key="item.id_email"
                      >
                        <td>
                          <b>Nome:</b>
                          {{ item.observacao }}
                        </td>
                        <td>
                          <b>Email:</b>
                          {{ item.email }}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td>
                          <b>CEP:</b>
                          {{
                            empresaVer.pessoa.enderecos.length > 0
                              ? empresaVer.pessoa.enderecos[0].cep
                              : ""
                          }}
                        </td>
                        <td>
                          <b>Endereço:</b>
                          {{
                            empresaVer.pessoa.enderecos.length > 0
                              ? empresaVer.pessoa.enderecos[0].logradouro
                              : ""
                          }}
                        </td>
                        <td>
                          <b>Número:</b>
                          {{
                            empresaVer.pessoa.enderecos.length > 0
                              ? empresaVer.pessoa.enderecos[0].numero
                              : ""
                          }}
                        </td>
                        <td>
                          <b>Complemento:</b>
                          {{
                            empresaVer.pessoa.enderecos.length > 0
                              ? empresaVer.pessoa.enderecos[0].complemento
                              : ""
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <b>Bairro:</b>
                          {{
                            empresaVer.pessoa.enderecos.length > 0
                              ? empresaVer.pessoa.enderecos[0].bairro
                              : ""
                          }}
                        </td>
                        <td>
                          <b>Cidade:</b>
                          {{
                            empresaVer.pessoa.enderecos.length > 0
                              ? empresaVer.pessoa.enderecos[0].cidade
                              : ""
                          }}
                        </td>
                        <td>
                          <b>Estado:</b>
                          {{
                            empresaVer.pessoa.enderecos.length > 0
                              ? empresaVer.pessoa.enderecos[0].estado
                              : ""
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table class="table table-bordered">
                    <tbody>
                      <b>Telefone(s):</b>
                      <tr
                        v-for="item of empresaVer.pessoa.telefones"
                        :key="item.id_telefone"
                      >
                        <td>
                          <b>Nome:</b>
                          {{ item.observacao }}
                        </td>
                        <td>
                          <b>Telefone:</b>
                          {{ item.telefone }}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br />
                  <b>2. Informações Bancária</b>
                  <hr />
                  <br />

                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td>
                          <b>Código do Banco:</b>
                          {{
                            empresaVer.contasBancarias.length > 0
                              ? empresaVer.contasBancarias[0].codigo_banco
                              : ""
                          }}
                        </td>
                        <td>
                          <b>Nome do Banco:</b>
                          {{
                            empresaVer.contasBancarias.length > 0
                              ? empresaVer.contasBancarias[0].banco
                              : ""
                          }}
                        </td>
                        <td>
                          <b>Agência:</b>
                          {{
                            empresaVer.contasBancarias.length > 0
                              ? empresaVer.contasBancarias[0].agencia
                              : ""
                          }}
                        </td>
                        <td>
                          <b>Conta:</b>
                          {{
                            empresaVer.contasBancarias.length > 0
                              ? empresaVer.contasBancarias[0].conta
                              : ""
                          }}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <b>Tipo de Conta:</b>
                          {{
                            empresaVer.contasBancarias.length > 0
                              ? empresaVer.contasBancarias[0].tipo
                              : ""
                          }}
                        </td>
                      </tr>

                      <tr>
                        <td colspan="4">
                          <b>Observação:</b>
                          {{
                            empresaVer.contasBancarias.length > 0
                              ? empresaVer.contasBancarias[0].observacao
                              : ""
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-light-primary font-weight-bold"
                  data-dismiss="modal"
                >
                  Fechar
                </button>
              </div>
            </div>
          </div>
        </div>

        <!--end::Row-->
        <!--end::Dashboard-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
  <!--end::Content-->
</template>
<script>
import Listagem from "../../components/forms/Listagem.vue";
import SubHeader from "../../components/layout/SubHeader";
import { mapActions, mapState } from "vuex";
import utils from "@/utils";
export default {
  components: {
    Listagem,
    SubHeader,
  },
  data: () => ({
    export: [
      {
        descricaoPai: "Empresa",
        criaSheet: true,
        campos: [
          {
            header: "ID",
            field: "id_empresa",
            checked: false,
          },
          {
            header: "Tipo de Pessoa",
            field: "pessoa.tipo_pessoa",
            checked: false,
          },
          {
            header: "Nome / Nome fantasia",
            field: "pessoa.nome",
            checked: false,
          },
          {
            header: "CNPJ / CPF",
            field: "pessoa.identificador",
            checked: false,
          },
          {
            header: "Razão Social",
            field: "razao_social",
            checked: false,
          },
          {
            header: "Responsável",
            field: "responsavel",
            checked: false,
          },
          {
            header: "Optante pelo Simples",
            field: "optante_simples",
            checked: false,
          },
          {
            header: "Inscrição Estadual",
            field: "pessoa.insc_estadual",
            checked: false,
          },
          {
            header: "Inscrição Municipal",
            field: "pessoa.insc_municipal",
            checked: false,
          },
          {
            header: "RG",
            field: "pessoa.rg",
            checked: false,
          },
          {
            header: "Data de Nascimento",
            field: "pessoa.data_nascimento",
            checked: false,
          },
          {
            header: "Observação",
            field: "observacao",
            checked: false,
          },
        ],
      },
      {
        descricaoPai: "E-mail",
        criaSheet: true,
        dados: "emails",
        campos: [
          {
            header: "Nome / Nome fantasia",
            field: "pessoa.nome",
            checked: false,
            hidden: true,
          },
          {
            header: "CNPJ / CPF",
            field: "pessoa.identificador",
            checked: false,
            hidden: true,
          },
          {
            header: "Nome",
            field: "observacao",
            checked: false,
          },
          {
            header: "Email",
            field: "email",
            checked: false,
          },
        ],
      },
      {
        descricaoPai: "Endereço",
        criaSheet: true,
        dados: "enderecos",
        campos: [
          {
            header: "Nome / Nome fantasia",
            field: "pessoa.nome",
            checked: false,
            hidden: true,
          },
          {
            header: "CNPJ / CPF",
            field: "pessoa.identificador",
            checked: false,
            hidden: true,
          },
          {
            header: "Cep",
            field: "cep",
            checked: false,
          },
          {
            header: "Endereço",
            field: "logradouro",
            checked: false,
          },
          {
            header: "Número",
            field: "numero",
            checked: false,
          },
          {
            header: "Complemento",
            field: "complemento",
            checked: false,
          },
          {
            header: "Bairro",
            field: "bairro",
            checked: false,
          },
          {
            header: "Estado",
            field: "estado",
            checked: false,
          },
        ],
      },
      {
        descricaoPai: "Telefone",
        criaSheet: true,
        dados: "telefones",
        campos: [
          {
            header: "Nome / Nome fantasia",
            field: "pessoa.nome",
            checked: false,
            hidden: true,
          },
          {
            header: "CNPJ / CPF",
            field: "pessoa.identificador",
            checked: false,
            hidden: true,
          },
          {
            header: "Nome",
            field: "observacao",
            checked: false,
          },
          {
            header: "Telefone",
            field: "telefone",
            checked: false,
          },
        ],
      },
      {
        descricaoPai: "Informações bancárias",
        criaSheet: true,
        dados: "contasBancarias",
        campos: [
          {
            header: "Nome / Nome fantasia",
            field: "pessoa.nome",
            checked: false,
            hidden: true,
          },
          {
            header: "CNPJ / CPF",
            field: "pessoa.identificador",
            checked: false,
            hidden: true,
          },
          {
            header: "Codigo do Banco",
            field: "codigo_banco",
            checked: false,
          },
          {
            header: "Nome do Banco",
            field: "banco",
            checked: false,
          },
          {
            header: "Tipo de Conta",
            field: "tipo",
            checked: false,
          },
          {
            header: "Agência",
            field: "agencia",
            checked: false,
          },
          {
            header: "DV Agencia",
            field: "dv_agencia",
            checked: false,
          },
          {
            header: "Conta",
            field: "conta",
            checked: false,
          },
          {
            header: "Conta",
            field: "dv_conta",
            checked: false,
          },
          {
            header: "Favorecido",
            field: "favorecido",
            checked: false,
          },
        ],
      },
    ],
    buttons: [
      {
        link: "/empresa/0",
        titulo: "Add nova empresa",
      },
    ],
    titulo: "Empresas",
    colunaPadrao: [
      {
        label: "ID",
        field: "id_empresa",
      },
      {
        label: "Empresa/Responsável",
        field: "pessoa.nome",
      },
      {
        label: "Tipo (PJ/PF)",
        field: "pessoa.tipo_pessoa",
        filterOptions: {
          enabled: true,
          filterDropdownItems: ["Pessoa Jurídica", "Pessoa Física"],
          placeholder: "Filtrar",
        },
      },
      {
        label: "Razão Social",
        field: "pessoa.razao_social",
      },
      {
        label: "CNPJ / CPF",
        field: "pessoa.identificador",
      },
      /* {
        label: "Insc. Estadual",
        field: "pessoa.insc_estadual",
      },
      {
        label: "Insc. Municipal",
        field: "pessoa.insc_municipal",
      },
      {
        label: "Telefone",
        field: "pessoa.primeiroTelefone",
      }, */
      {
        label: "Ação",
        field: "id_empresa",
      },
    ],
    colunas: [
      {
        nome: "ID",
        index: "id_empresa",
        isHtml: false,
      },
      {
        nome: "Empresa/Responsável",
        html: '<td><b>{0}</b><br><span style="font-size: 10px;">{1}</span>',
        isHtml: true,
        colsFormatar: ["pessoa.nome", "responsavel"],
      },
      {
        nome: "Tipo (PJ/PF)",
        index: "pessoa.tipo_pessoa",
        isHtml: false,
      },
      {
        nome: "Razão Social",
        index: "pessoa.razao_social",
        isHtml: false,
      },
      {
        nome: "CNPJ / CPF",
        index: "pessoa.identificador",
        isHtml: false,
      },
      /* {
        nome: "Insc. Estadual",
        isHtml: false,
        index: "pessoa.insc_estadual",
      },
      {
        nome: "Insc. Municipal",
        isHtml: false,
        index: "pessoa.insc_municipal",
      },
      {
        nome: "Telefone",
        isHtml: false,
        index: "pessoa.primeiroTelefone",
      }, */
    ],
    dados: [],
    monta: false,
    empresaVer: {
      tipo_servico: "",
      responsavel: "",
      contasBancarias: [
        {
          banco: "",
          codigo_banco: "",
          agencia: "",
          dv_agencia: "",
          conta: "",
          dv_conta: "",
          tipo: "",
          ativo: true,
          isPoupanca: false,
        },
      ],
      tiposRecebimentos: [],
      formasPagamento: [],
      servicos: [],
      pessoa: {
        tipo_pessoa: "J",
        nome: "",
        razao_social: "",
        identificador: "",
        ativo: true,
        data_nascimento: null,
        insc_estadual: "",
        insc_municipal: "",
        sexo: "",
        rg: "",
        telefones: [],
        enderecos: [
          {
            cep: "",
            logradouro: "",
            numero: "",
            complemento: "",
            bairro: "",
            cidade: "",
            estado: "",
            ativo: true,
          },
        ],
        emails: [],
      },
      contaBancaria: {
        banco: "",
        codigo_banco: "",
        agencia: "",
        dv_agencia: "",
        conta: "",
        dv_conta: "",
        tipo: "",
        ativo: true,
        isPoupanca: false,
      },
      endereco: {
        cep: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: "",
        estado: "",
        ativo: true,
      },
    },
  }),
  computed: {
    ...mapState("auth", ["user"]),
    exportarLista: {
      get() {
        return this.export;
      },
      set(nValue) {
        this.export = nValue;
      },
    },
  },
  mounted() {
    this.load();
    if (this.user.leitura == true) {
      this.buttons = [];
    }
  },
  methods: {
    ...mapActions("empresa", ["ActionGetEmpresas", "ActionInactiveEmpresas"]),
    load() {
      window.KTApp.block("#kt_blockui_content", {
        overlayColor: "#000000",
        state: "primary",
        message: "Processando...",
      });
      this.ActionGetEmpresas()
        .then((r) => {
          this.dados = r;
          this.dados.forEach((el) => {
            el.pessoa.data_nascimento =
              el.pessoa.tipo_pessoa == "J"
                ? (el.pessoa.data_nascimento = null)
                : el.pessoa.data_nascimento;
            el.pessoa.identificador = utils.formatarCnpj(
              el.pessoa.identificador
            );
            el.pessoa["primeiroTelefone"] =
              el.pessoa.telefones.length > 0
                ? el.pessoa.telefones[0].telefone
                : "";
            el.pessoa.tipo_pessoa == "J"
              ? (el.pessoa.data_nascimento = null)
              : (el.pessoa.data_nascimento = this.$moment(
                  el.pessoa.data_nascimento
                ).format("DD/MM/YYYY"));
            el.pessoa["tipo_pessoa"] =
              el.pessoa.tipo_pessoa == "J"
                ? "Pessoa Jurídica"
                : "Pessoa Física";
            el.responsavel = el.responsavel ? el.responsavel : "";
            el["emails"] = el.pessoa.emails;
            el["telefones"] = el.pessoa.telefones;
            el["enderecos"] = el.pessoa.enderecos;
            el.optante_simples = el.optante_simples == true ? "SIM" : "NÃO";
          });
          console.log(this.dados);
          this.monta = true;
          window.KTApp.unblock("#kt_blockui_content");
        })
        .catch((error) => {
          if (error.data) {
            console.log(error.data);
            this.$swal.fire({
              icon: "error",
              title: error.data.error.title || "Oops...",
              text: error.data.error.message,
            });
          } else {
            this.$swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Ocorreu um erro inesperado, favor contactar o suporte.",
            });
          }
        });
    },
    visualizar(item) {
      console.log(item);
      this.empresaVer = item;
      this.empresaVer.url_logo = this.empresaVer.url_logo
        ? this.empresaVer.url_logo.replace(":3000", "")
        : this.empresaVer.url_logo;
      console.log(this.empresaVer);
    },
    inativar(item) {
      console.log(item);
      this.$swal
        .fire({
          icon: "question",
          title: "Atenção",
          text: "Deseja realmente inativar esta Empresa?",
          showCloseButton: true,
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: "Não",
          confirmButtonText: "Sim",
        })
        .then((r) => {
          if (r.value) {
            this.ActionInactiveEmpresas(item.id_empresa).then((r) => {
              if (r.sucesso == true) {
                this.$swal.fire({
                  icon: "success",
                  title: "Sucesso",
                  text: "Empresa inativada com sucesso",
                });
              }
              this.load();
            });
          }
        });
    },
    editar(item) {
      this.$router.push(`/empresa/${item.id_empresa}`);
    },
  },
};
</script>