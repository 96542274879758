import services from "@/http";
// import * as types from "./mutation-types";

export const ActionGetListFornecedores = () => {
    return services.fornecedor.all().then(r => {
        return r.data;
    });
};

export const ActionGetListFornecedoresActive = () => {
    return services.fornecedor.active().then(r => {
        return r.data;
    });
};


export const ActionGetFornecedor = (context, payload) => {
    return services.fornecedor.get({ id: payload }).then(r => {
        return r.data;
    });
};

export const ActionUpdateFornecedor = (context, payload) => {
    return services.fornecedor.update(payload).then(r => {
        return r.data;
    });
};

export const ActionCreateFornecedor = (context, payload) => {
    return services.fornecedor.create(payload).then(r => {
        return r.data;
    });
};


export const ActionInactiveFornecedor = (context, payload) => {
    return services.fornecedor.inactive({ id: payload }).then(r => {
        return r.data;
    });
};