<template>
  <!--begin::Content-->
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Subheader-->
    <sub-header
      v-if="this.conciliado == undefined"
      :titulo="titulo"
      :buttons="buttons"
    ></sub-header>
    <!--end::Subheader-->
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Dashboard-->
        <!--begin::Row-->

        <div class="d-flex flex-column-fluid">
          <!--begin::Container-->
          <div class="container">
            <!--begin::Dashboard-->
            <!--begin::Row-->

            <div class="row">
              <div class="col-lg-12">
                <div class="card card-custom gutter-b example example-compact">
                  <div class="card-header">
                    <h3 class="card-title">1. Novo {{ tipo }}</h3>
                  </div>
                  <!--begin::Form-->
                  <form class="form">
                    <div class="card-body">
                      <div class="form-group row">
                        <div class="col-lg-6">
                          <label>Nome:</label>
                          <input
                            type="text"
                            v-model="data.nome"
                            class="form-control"
                            :disabled="
                              isRecebimento && this.conciliado == undefined
                            "
                            :placeholder="`Nome do ${tipo}`"
                          />
                        </div>

                        <div class="col-lg-6" v-if="tipo == 'Recebimento'">
                          <label>Serviço:</label>
                          <select-populado
                            :list="servicos"
                            :data="data.id_servico"
                            :fieldKey="'id_servico'"
                            :fieldText="'descricao'"
                            @onChange="ChangeServico"
                            :disable="
                              isRecebimento && this.conciliado == undefined
                            "
                          />
                        </div>

                        <div class="col-lg-6">
                          <label>Categoria:</label>
                          <select-populado
                            :list="categorias"
                            :data="data.id_categoria"
                            :fieldKey="'id_categoria'"
                            :fieldText="'nome'"
                            @onChange="ChangeCategoria"
                            :disable="
                              isRecebimento && this.conciliado == undefined
                            "
                          />
                        </div>

                        <div class="col-lg-12 card-toolbar" style="width: 30px">
                          <br />
                          <input type="checkbox" v-model="IsFuncionario" />
                          &nbsp; &nbsp; &nbsp;
                          <label>É Funcionário ?</label>
                        </div>

                        <div class="col-lg-4" v-if="IsFuncionario == false">
                          <br />
                          <label>Clientes/Fornecedores:</label>
                          <a @click="data.id_obj = undefined">
                            &nbsp; &nbsp;
                            <i class="far fa-trash-alt"></i>
                          </a>

                          <select-populado
                            :data="data.id_obj"
                            :list="fornecCliente"
                            :fieldKey="'id'"
                            :fieldText="'text'"
                            :disable="
                              isRecebimento && this.conciliado == undefined
                            "
                            @onChange="ChangeFonecCliente"
                          />
                        </div>
                        <div class="col-lg-4" v-if="IsFuncionario == true">
                          <br />
                          <label>Funcionario:</label>
                          <a @click="data.id_funcionario = undefined">
                            &nbsp; &nbsp;
                            <i class="far fa-trash-alt"></i>
                          </a>
                          <select-populado
                            :data="data.id_funcionario"
                            :list="funcionarios"
                            :fieldKey="'id_funcionario'"
                            :fieldText="'pessoa.nome'"
                            :disable="
                              isRecebimento && this.conciliado == undefined
                            "
                            @onChange="ChangeFuncionario"
                          />
                        </div>

                        <div class="col-lg-4">
                          <br />
                          <label>Data de Lançamento:</label>
                          <input
                            v-model="data.data_inclusao"
                            type="date"
                            class="form-control"
                            placeholder="Data de Lançamento"
                            disabled
                          />
                        </div>

                        <div class="col-lg-4">
                          <br />
                          <label>Data de Vencimento:</label>
                          <input
                            v-model="data.data_lancamento"
                            type="date"
                            class="form-control"
                            :disabled="
                              isRecebimento && this.conciliado == undefined
                            "
                            placeholder="Data de Vencimento"
                          />
                        </div>

                        <div class="col-lg-4">
                          <br />
                          <label>Valor:</label>
                          <money
                            v-model="data.valor"
                            class="form-control"
                            v-bind="money"
                            :disabled="isRecebimento"
                            :placeholder="`Valor do ${tipo}`"
                          ></money>
                        </div>

                        <div class="col-lg-8">
                          <br />
                          <label>Observação:</label>
                          <textarea
                            v-model="data.descricao"
                            rows="5"
                            :disabled="
                              isRecebimento && this.conciliado == undefined
                            "
                            class="form-control"
                            placeholder="Observação"
                          ></textarea>
                        </div>
                      </div>

                      <div
                        v-if="this.conciliado == undefined"
                        class="separator separator-dashed my-10"
                      ></div>

                      <div v-if="this.conciliado == undefined">
                        Ocorrências:
                      </div>
                      <br v-if="this.conciliado == undefined" />

                      <div
                        class="form-group row"
                        v-if="this.conciliado == undefined"
                      >
                        <div
                          class="checkbox-list col-lg-1"
                          style="margin-top: 8px"
                        >
                          <label class="checkbox">
                            <input
                              type="checkbox"
                              name="Checkboxes1"
                              :disabled="isRecebimento"
                              v-model="isOcorrencia"
                            />
                            <span></span>Repetir
                          </label>
                        </div>

                        <div class="col-lg-4">
                          <select-populado
                            :list="periodicidades"
                            :data="data.id_periodicidade"
                            :fieldKey="'id_periodicidade'"
                            :fieldText="'descricao'"
                            :disable="!isOcorrencia || isRecebimento"
                            @onChange="ChangePeriodicidade"
                          />
                          <!-- <select class="form-control select2" id="kt_select2_3" name="param">
                            <option>Diariamente</option>
                            <option>Semanalmente</option>
                            <option>Mensalmente</option>
                            <option>Trimestralmente</option>
                            <option>Semestralmente</option>
                            <option>Anualmente</option>
                          </select>-->
                        </div>
                        <div class="col-lg-1">
                          <input
                            type="number"
                            :disabled="!isOcorrencia || isRecebimento"
                            class="form-control"
                            v-model="data.ocorrencia"
                            min="1"
                            :placeholder="`Quant.`"
                          />
                        </div>
                      </div>
                      <div>
                        <div class="separator separator-dashed my-10"></div>

                        <div>{{ tipo }}:</div>
                        <br />

                        <div class="form-group row">
                          <div
                            class="checkbox-list col-lg-2"
                            style="margin-top: 12px"
                          >
                            <label
                              class="checkbox"
                              v-if="this.conciliado == undefined"
                            >
                              <input
                                type="checkbox"
                                name="Checkboxes1"
                                v-model="isRecebimento"
                              />
                              <span></span>
                              {{ tipo == "Recebimento" ? "Recebido" : "Pago" }}
                            </label>
                            <span v-if="this.conciliado != undefined">{{
                              tipo == "Recebimento" ? "Recebido" : "Pago"
                            }}</span>
                          </div>

                          <div class="col-lg-2">
                            <label>
                              <span></span>
                              Data {{ tipo.toLowerCase() }}
                            </label>
                            <input
                              v-model="recebimento.data_efetivacao"
                              :disabled="!isRecebimento"
                              type="date"
                              class="form-control"
                              placeholder="Data de Vencimento"
                            />
                          </div>

                          <div class="col-lg-2">
                            <label> <span></span>Descontos/Taxas </label>
                            <money
                              v-model="recebimento.descontos"
                              class="form-control"
                              :disabled="
                                !isRecebimento || this.conciliado != undefined
                              "
                              v-bind="money"
                              @blur.native="CalcMurosJutas()"
                              :placeholder="`R$`"
                            ></money>
                          </div>

                          <div class="col-lg-2">
                            <label> <span></span>Juros/Multas </label>
                            <money
                              v-model="recebimento.juros"
                              :disabled="
                                !isRecebimento || this.conciliado != undefined
                              "
                              @blur.native="CalcMurosJutas()"
                              class="form-control"
                              v-bind="money"
                              :placeholder="`R$`"
                            ></money>
                          </div>

                          <div class="col-lg-2">
                            <label> <span></span>Valor </label>
                            <money
                              v-model="recebimento.valor"
                              class="form-control"
                              :disabled="
                                !isRecebimento || this.conciliado != undefined
                              "
                              v-bind="money"
                              :placeholder="`R$`"
                            ></money>
                          </div>

                          <div class="col-lg-6" v-if="isRecebimento">
                            <br />
                            <label>Anexo:</label>
                            <VueFileAgent
                              ref="vueFileAgent"
                              :theme="'list'"
                              :multiple="false"
                              :deletable="true"
                              :meta="true"
                              :maxSize="'10MB'"
                              :maxFiles="1"
                              v-model="fileRecords"
                              @select="filesSelected($event)"
                              @beforedelete="onBeforeDelete($event)"
                              @delete="fileDeleted($event)"
                              :helpText="'Arraste ou clique para anexar comprovante'"
                            ></VueFileAgent>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card-footer">
                      <div class="row">
                        <div
                          class="col-lg-12 ml-lg-auto"
                          id="kt_blockui_content"
                        >
                          <button
                            type="button"
                            @click="salvar"
                            class="btn btn-primary mr-2"
                          >
                            {{ opcao == "A" ? "Adicionar" : "Atualizar" }}
                          </button>
                          <button
                            type="button"
                            @click="voltar"
                            class="btn btn-secondary"
                          >
                            Cancelar
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <!--end::Form-->
                </div>
                <!--end::Card-->
              </div>
            </div>

            <!--begin::Row-->

            <!--end::Row-->
            <!--end::Dashboard-->
          </div>
          <!--end::Container-->
        </div>

        <!--begin::Row-->

        <!--end::Row-->
        <!--end::Dashboard-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
  <!--end::Content-->
</template>
<script>
import { Money } from "v-money";
import SelectPopulado from "../../../components/forms/SelectPopulado";
import { mapActions, mapState } from "vuex";
import SubHeader from "../../../components/layout/SubHeader";
export default {
  components: {
    SelectPopulado,
    Money,
    SubHeader,
  },
  props: {
    data: Object,
    tipo: String,
    titulo: String,
    buttons: {
      type: Array,
      default: new Array(),
    },
    opcao: String,
    conciliado: {
      type: Object,
      default: undefined,
    },
  },
  data: () => ({
    IsFuncionario: false,
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      suffix: "",
      precision: 2,
      masked: false,
    },
    contaBackup: null,
    recebimento: {},
    peri: [],
    fonec: [],
    cat: [],
    func: [],
    serv: [],
    fileRecords: [],
    fileRecordsForUpload: [],
    isOcorrencia: false,
    isRecebimento: false,
  }),

  computed: {
    ...mapState("auth", ["empresa", "user"]),
    periodicidades() {
      return this.peri;
    },
    fornecCliente() {
      return this.fonec.map((x) => {
        return {
          id: x.id_cliente ? "C" + x.id_cliente : "F" + x.id_fornecedor,
          id_ob: x.id_cliente ? x.id_cliente : x.id_fornecedor,
          text: x.pessoa.nome,
        };
      });
    },
    funcionarios() {
      return this.func;
    },
    categorias() {
      return this.cat;
    },
    servicos() {
      return this.serv;
    },
  },
  mounted() {
    if (this.conciliado != undefined) {
      this.recebimento.codigo_efetivacao = this.conciliado.codigo_efetivacao;
      this.recebimento.valor = this.conciliado.valor;
      this.recebimento.data_efetivacao = this.$moment(new Date()).format(
        "YYYY-MM-DD"
      );
      this.data.ocorrencia = 1;
      this.data.nome = this.conciliado.nome;
      this.data.valor = this.conciliado.valor;
      this.isRecebimento = true;
      this.data.data_inclusao = this.$moment(new Date()).format("YYYY-MM-DD");
      this.data.data_lancamento = this.$moment(
        this.conciliado.data_lancamento
      ).format("YYYY-MM-DD");
      this.opcao = "A";
    }
    if (this.opcao == "A") {
      this.data.data_inclusao = this.$moment(new Date()).format("YYYY-MM-DD");
    } else {
      if (this.data.id_movimentacao) {
        this.contaBackup = this.data;
        this.IsFuncionario =
          this.data.id_funcionario == undefined ? false : true;
      }
    }

    if (this.data.categoria == null || this.data.categoria == undefined) {
      this.data.categoria = {
        id_categoria: undefined,
      };
    }

    if (this.tipo == "Recebimento") {
      if (this.data.servico == null || this.data.servico == undefined) {
        this.data.servico = {
          id_servico: undefined,
        };
      }
    }

    this.ActionGetListServicoActive().then((r) => {
      this.serv = r;
    });
    this.ActionGetListCategoriaActive().then((r) => {
      this.cat = r;
    });
    this.ActionGetListPeriodicidade().then((r) => {
      this.peri = r;
    });
    if (this.opcao == "A") {
      this.ActionGetListClienteActive().then((r) => {
        this.fonec = r;
      });
    } else {
      this.ActionGetListCliente().then((r) => {
        this.fonec = r;
      });
    }
    this.ActionGetListFuncionarioActive().then((r) => {
      this.func = r;
    });
  },
  methods: {
    ...mapActions("servico", ["ActionGetListServicoActive"]),
    ...mapActions("categoria", ["ActionGetListCategoriaActive"]),
    ...mapActions("funcionario", ["ActionGetListFuncionarioActive"]),
    ...mapActions("periodicidade", ["ActionGetListPeriodicidade"]),
    ...mapActions("cliente", [
      "ActionGetListClienteActive",
      "ActionGetListCliente",
    ]),
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(
        validFileRecords
      );
    },
    onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.$swal
          .fire({
            icon: "question",
            title: "Aviso",
            text: "Deseja realmente excluir este anexo?",
            showCloseButton: true,
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: "Não",
            confirmButtonText: "Sim",
          })
          .then((r) => {
            if (r.value) {
              this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
            }
          });
      }
    },
    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        // this.deleteUploadedFile(fileRecord);
      }
    },
    salvar() {
      if (this.isRecebimento == true) {
        this.data["recebimento"] = this.recebimento;
      }
      if (this.IsFuncionario == true) {
        this.data.id_cliente = undefined;
        this.data.id_fornecedor = undefined;
      } else {
        if (this.data.id_obj != 0) {
          let y = this.fornecCliente.filter((x) => this.data.id_obj == x.id)[0];

          if (y) {
            if (y.id.includes("C")) {
              this.data.id_cliente = y.id_ob;
            } else if (y.id.includes("F")) {
              this.data.id_fornecedor = y.id_ob;
            }
          } else {
            this.data.id_fornecedor = undefined;
            this.data.id_cliente = undefined;
          }
        } else {
          this.data.id_fornecedor = undefined;
          this.data.id_cliente = undefined;
        }
        this.data.id_funcionario = undefined;
      }

      this.data.id_periodicidade =
        this.data.id_periodicidade == 0
          ? undefined
          : this.data.id_periodicidade;

      this.data.anexo =
        this.fileRecordsForUpload.length == 0
          ? null
          : this.fileRecordsForUpload[0].file;
      this.$emit("onSave", this.data);
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.data.anexo = files[0];
    },
    voltar() {
      this.$emit("onCancel", true);
    },
    ChangeFonecCliente(item) {
      this.data.id_obj = item;
    },
    ChangeCategoria(item) {
      this.data.id_categoria = item;
    },
    ChangeServico(item) {
      this.data.id_servico = item;
    },
    ChangePeriodicidade(item) {
      this.data.id_periodicidade = item;
    },
    ChangeFuncionario(item) {
      this.data.id_funcionario = item;
    },
    CalcMurosJutas() {
      this.recebimento.valor =
        this.data.valor + this.recebimento.juros - this.recebimento.descontos;
    },
  },
  watch: {
    data(newValue) {
      if (newValue) {
        this.data = newValue;

        if (this.data.categoria == null || this.data.categoria == undefined) {
          this.data.categoria = {
            id_categoria: undefined,
          };
        }

        if (this.tipo == "Recebimento") {
          if (this.data.servico == null || this.data.servico == undefined) {
            this.data.servico = {
              id_servico: undefined,
            };
          }
        }

        if (this.data.recebimento) {
          this.recebimento = this.data.recebimento;
          this.isRecebimento = true;
        }
        if (this.contaBackup == null) {
          this.contaBackup = newValue;
        }
      }
    },
    isRecebimento(newValue) {
      this.isRecebimento = newValue;
      if (this.isRecebimento == true) {
        if (this.contaBackup) {
          this.data = this.contaBackup;
          this.recebimento.valor = this.contaBackup.valor;
        }
      }
    },
    opcao(newValue) {
      if (newValue == "A") {
        this.ActionGetListClienteActive().then((r) => {
          this.fonec = r;
        });
      } else {
        this.ActionGetListCliente().then((r) => {
          this.fonec = r;
        });
      }
    },
    conciliado(newValue) {
      this.conciliado = newValue;
      if (this.conciliado != undefined) {
        this.recebimento.codigo_efetivacao = this.conciliado.codigo_efetivacao;
        this.recebimento.valor = this.conciliado.valor;
        this.recebimento.data_efetivacao = this.$moment(new Date()).format(
          "YYYY-MM-DD"
        );
        this.data.ocorrencia = 1;
        this.data.nome = this.conciliado.nome;
        this.data.valor = this.conciliado.valor;
        this.isRecebimento = true;
        this.data.data_inclusao = this.$moment(new Date()).format("YYYY-MM-DD");
        this.data.data_lancamento = this.$moment(
          this.conciliado.data_lancamento
        ).format("YYYY-MM-DD");
        this.opcao = "A";
      }
    },
  },
};
</script>