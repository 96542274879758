<template>
  <!--begin::Content-->
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Subheader-->
    <sub-header :titulo="titulo" :buttons="buttons"></sub-header>
    <!--end::Subheader-->
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Dashboard-->
        <!--begin::Row-->

        <Listagem
          :dados="dados"
          :colunas="colunas"
          :monta="monta"
          :canVer="false"
          @onVisualizar="visualizar"
          @onDelete="inativar"
          @onEditar="editar"
        />

        <!-- Button trigger modal-->
        <!-- <button
          type="button"
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModal"
        >Visualizar Funcionário</button>-->

        <!-- Modal-->
        <div
          class="modal fade bd-example-modal-xl"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myExtraLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Visualizando Tipo de Recebimento</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <i aria-hidden="true" class="ki ki-close"></i>
                </button>
              </div>
              <div class="modal-body" style="height: 500px;">...</div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-light-primary font-weight-bold"
                  data-dismiss="modal"
                >Fechar</button>
              </div>
            </div>
          </div>
        </div>

        <!--end::Row-->
        <!--end::Dashboard-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
  <!--end::Content-->
</template>
<script>
import Listagem from "../../components/forms/Listagem.vue";
import SubHeader from "../../components/layout/SubHeader";
import { mapActions } from "vuex";
export default {
  components: {
    Listagem,
    SubHeader,
  },
  data: () => ({
    buttons: [
      {
        link: "/tipo-recebimento/0",
        titulo: "Add novo Tipo de Recebimento",
      },
    ],
    titulo: "Tipo de Recebimento",
    colunas: [
      {
        nome: "ID",
        index: "id_tipo_recebimento",
        isHtml: false,
      },

      {
        nome: "Descrição",
        index: "descricao",
        isHtml: false,
      },
    ],
    dados: [],
    monta: false,
  }),
  mounted() {
    this.ActionGetListTipoRecebimento()
      .then((r) => {
        this.dados = r;
        this.monta = true;
      })
      .catch((error) => {
        if (error.data) {
          console.log(error.data);
          this.$swal.fire({
            icon: "error",
            title: error.data.error.title || "Oops...",
            text: error.data.error.message,
          });
        } else {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Ocorreu um erro inesperado, favor contactar o suporte.",
          });
        }
      });
  },
  methods: {
    ...mapActions("tipoRecebimento", [
      "ActionGetListTipoRecebimento",
      "ActionInactiveTipoRecebimento",
    ]),
    visualizar(item) {
      console.log(item);
    },
    inativar(item) {
      console.log(item);
    },
    editar(item) {
      this.$router.push(`/tipo-recebimento/${item.id_tipo_recebimento}`);
    },
  },
};
</script>