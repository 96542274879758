<template>
  <!--begin::Content-->
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Subheader-->
    <sub-header :titulo="titulo" :buttons="buttons"></sub-header>
    <!--end::Subheader-->
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Dashboard-->
        <!--begin::Row-->
        <div
          class="alert alert-custom alert-white alert-shadow fade show gutter-b"
          role="alert"
        >
          <div class="alert-icon">
            <span class="svg-icon svg-icon-primary svg-icon-2x">
              <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Search.svg-->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <rect x="0" y="0" width="24" height="24" />
                  <path
                    d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                    fill="#000000"
                    fill-rule="nonzero"
                    opacity="0.3"
                  />
                  <path
                    d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                    fill="#000000"
                    fill-rule="nonzero"
                  />
                </g>
              </svg>
              <!--end::Svg Icon-->
            </span>
          </div>
          <div class="alert-text">
            <div
              class="input-daterange input-group col-lg-6"
              id="kt_datepicker_5"
            >
              <input
                type="date"
                v-model="dataInicio"
                class="form-control"
                name="start"
              />
              <div class="input-group-append">
                <span class="input-group-text">
                  <i class="la la-ellipsis-h"></i>
                </span>
              </div>
              <input
                type="date"
                v-model="dataFim"
                class="form-control"
                name="end"
              />
            </div>
          </div>

          <div style="margin-top: 10px">
            Total à Pagar:
            <b style="color: red">{{
              totalLista.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })
            }}</b>
            Total Pago:
            <b style="color: green">{{
              totalEfetivo.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })
            }}</b>
          </div>
        </div>
        <Listagem
          :dados="dados"
          :colunas="colunaPadrao"
          :colunasDetalhes="colunas"
          :monta="monta"
          :canBtnAtivo="false"
          :exportacao="exportarLista"
          :canInativar="user.leitura != true"
          @onVisualizar="visualizar"
          @onDelete="inativar"
          @onEditar="editar"
          @onConciliar="dadosConcliacao"
        />

        <!-- Button trigger modal-->
        <!-- <button
          type="button"
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModal"
        >Visualizar Funcionário</button>-->

        <!-- Modal-->
        <div
          class="modal fade bd-example-modal-xl"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myExtraLargeModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-xl modal-dialog-scrollable"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Visualizando Conta a Pagar
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i aria-hidden="true" class="ki ki-close"></i>
                </button>
              </div>
              <div class="modal-body" style="height: 500px">
                <div class="container" id="modaload">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td>
                          <b>Nome:</b>
                          {{ ver.pagar.nome }}
                        </td>
                        <td>
                          <b>Categoria:</b>
                          {{ ver.pagar.categoria.nome }}
                        </td>
                        <td>
                          <b>Credor:</b>
                          {{ ver.obj ? ver.obj.pessoa.nome : "" }}
                        </td>
                        <td colspan="2">
                          <b>Valor:</b>
                          <b style="color: #ff0000">
                            {{
                              ver.valor.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })
                            }}</b
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Data de Lançamento:</b>
                          {{ ver.data_inclusao }}
                        </td>
                        <td>
                          <b>Data de Vencimento:</b>
                          {{ ver.data_lancamento_up }}
                        </td>
                        <td>
                          <b>Data de Pagamento:</b>
                          {{ ver.data_efetivacao }}
                        </td>

                        <td>
                          <b>Ocorrências:</b>
                          {{
                            ver.pagar.periodicidade != null
                              ? ver.pagar.periodicidade.descricao
                              : ""
                          }}
                        </td>
                        <td>
                          <b>Qtd. Ocorrência:</b>
                          {{ ver.pagar.ocorrencia }}
                        </td>
                      </tr>

                      <tr>
                        <td colspan="5">
                          <b>Observação:</b>
                          {{ ver.descricao }}
                        </td>
                      </tr>
                      <br />

                      <tr>
                        <td colspan="5">
                          <b>Informações Bancárias</b>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Favorecido</b>
                          {{
                            ver.obj.conta_bancaria
                              ? ver.obj.conta_bancaria.favorecido
                              : ver.obj.contaBancaria.favorecido
                          }}
                        </td>
                        <td>
                          <b>CPF/CNPJ:</b>
                          {{
                            ver.obj.conta_bancaria
                              ? ver.obj.conta_bancaria.identificador
                              : ver.obj.contaBancaria.identificador
                          }}
                        </td>
                        <td>
                          <b>Banco</b>
                          {{
                            ver.obj.conta_bancaria
                              ? ver.obj.conta_bancaria.codigo_banco
                              : ver.obj.contaBancaria.codigo_banco
                          }}
                          -
                          {{
                            ver.obj.conta_bancaria
                              ? ver.obj.conta_bancaria.banco
                              : ver.obj.contaBancaria.banco
                          }}
                        </td>
                        <td>
                          <b>Agência:</b>
                          {{
                            ver.obj.conta_bancaria
                              ? ver.obj.conta_bancaria.agencia
                              : ver.obj.contaBancaria.agencia
                          }}
                          -
                          {{
                            ver.obj.conta_bancaria
                              ? ver.obj.conta_bancaria.dv_agencia
                                ? ver.obj.conta_bancaria.dv_agencia
                                : 0
                              : ver.obj.contaBancaria.dv_agencia
                              ? ver.obj.contaBancaria.dv_agencia
                              : 0
                          }}
                        </td>
                        <td>
                          <b>Conta:</b>
                          {{
                            ver.obj.conta_bancaria
                              ? ver.obj.conta_bancaria.conta
                              : ver.obj.contaBancaria.conta
                          }}
                          -
                          {{
                            ver.obj.conta_bancaria
                              ? ver.obj.conta_bancaria.dv_agencia
                                ? ver.obj.conta_bancaria.dv_agencia
                                : 0
                              : ver.obj.contaBancaria.dv_conta
                              ? ver.obj.contaBancaria.dv_conta
                              : 0
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="5">
                          <b>Tipo de Conta:</b>
                          {{
                            ver.obj.conta_bancaria
                              ? ver.obj.conta_bancaria.tipo
                              : ver.obj.contaBancaria.tipo
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <h5 v-if="podeAnexar()">Anexar Comprovante</h5>

                  <VueFileAgent
                    v-if="podeAnexar()"
                    ref="vueFileAgent"
                    :theme="'list'"
                    :multiple="false"
                    :deletable="true"
                    :meta="true"
                    :maxSize="'10MB'"
                    :maxFiles="1"
                    v-model="fileRecords"
                    @select="filesSelected($event)"
                    @beforedelete="onBeforeDelete($event)"
                    @delete="fileDeleted($event)"
                    :helpText="'Arraste ou clique para anexar'"
                  ></VueFileAgent>
                  <br />
                  <button
                    v-if="podeAnexar()"
                    :disabled="fileRecordsForUpload.length == 0"
                    @click="anexar()"
                    class="btn btn-sn btn-outline-primary"
                  >
                    Anexar Arquivo
                  </button>
                  <a
                    v-if="ver.anexo != null"
                    class="btn btn-outline-info btn-lg"
                    :href="ver.anexo.url"
                    :download="ver.anexo.nome"
                    target="_blank"
                  >
                    <i class="fa fa-download fa-3x" aria-hidden="true"></i>
                    BAIXAR COMPROVANTE
                  </a>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-light-primary font-weight-bold"
                  data-dismiss="modal"
                >
                  Fechar
                </button>
              </div>
            </div>
          </div>
        </div>

        <!--end::Row-->
        <!--end::Dashboard-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
  <!--end::Content-->
</template>
<script>
import Listagem from "../../../components/forms/ListagemContas";
import SubHeader from "../../../components/layout/SubHeader";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    Listagem,
    SubHeader,
  },
  data: () => ({
    fileRecords: [],
    fileRecordsForUpload: [],
    export: [
      {
        descricaoPai: "Contas à Pagar",
        criaSheet: true,
        campos: [
          {
            header: "ID",
            field: "id_movimentacao",
            checked: false,
          },
          {
            header: "Pagamento",
            field: "pagar.nome",
            checked: false,
          },
          {
            header: "Categoria",
            field: "pagar.categoria.nome",
            checked: false,
          },
          {
            header: "Forncededor/Cliente",
            field: "obj.pessoa.nome",
            checked: false,
          },
          {
            header: "Data Competência",
            field: "data_inclusao",
            checked: false,
          },
          {
            header: "Data Vencimento",
            field: "data_lancamento_up",
            checked: false,
          },
          {
            header: "Ocorrências",
            field: "pagar.periodicidade.descricao",
            checked: false,
          },
          {
            header: "Quantidade de Ocorrências",
            field: "pagar.ocorrencia",
            checked: false,
          },
          {
            header: "Valor",
            field: "pagar.valor_sem",
            checked: false,
          },
          {
            header: "Status",
            field: "status",
            checked: false,
          },
        ],
      },
      {
        descricaoPai: "Dados Bancários",
        criaSheet: false,

        campos: [
          {
            header: "Favorecido",
            field: "obj.bancarios.favorecido",
            checked: false,
          },
          {
            header: "CPF / CNPJ",
            field: "obj.bancarios.identificador",
            checked: false,
          },
          {
            header: "Cód Banco",
            field: "obj.bancarios.codigo_banco",
            checked: false,
          },
          {
            header: "Banco",
            field: "obj.bancarios.banco",
            checked: false,
          },
          {
            header: "Agência",
            field: "obj.bancarios.agencia",
            checked: false,
          },
          {
            header: "Dv Agência",
            field: "obj.bancarios.dv_agencia",
            checked: false,
          },
          {
            header: "Conta",
            field: "obj.bancarios.conta",
            checked: false,
          },
          {
            header: "Dv Conta",
            field: "obj.bancarios.dv_conta",
            checked: false,
          },
        ],
      },
      {
        descricaoPai: "Pagamento",
        criaSheet: false,
        campos: [
          {
            header: "Data de Pagamento",
            field: "data_efetivacao",
            checked: false,
          },
          {
            header: "Desconto/Taxas",
            field: "descontos_sem",
            checked: false,
          },
          {
            header: "Juros e Multas",
            field: "juros_sem",
            checked: false,
          },
          {
            header: "Valor Pago",
            field: "valor_export",
            checked: false,
          },
        ],
      },
    ],
    ver: {
      pagar: {
        nome: "",
        ocorrencia: 0,
        categoria: { nome: "" },
        periodicidade: { descricao: "" },
      },
      data_lancamento_up: "",
      descricao: "",
      data_inclusao: "",
      obj: { pessoa: { nome: "" }, conta_bancaria: {}, contaBancaria: {} },
      valor: 0,
    },
    buttons: [
      {
        link: "/conta-pagar/0",
        titulo: "Add nova Conta",
      },
    ],
    titulo: "Conta à Pagar",
    dataInicio: null,
    dataFim: null,
    colunaPadrao: [
      {
        label: "ID",
        field: "id_movimentacao",
      },
      {
        label: "Pagamento",
        field: "pagar.nome",
      },
      {
        label: "Categoria",
        field: "pagar.categoria.nome",
      },
      {
        label: "Credor",
        field: "obj.pessoa.nome",
      },

      {
        label: "Dt. Pagamento",
        field: "data_efetivacao",
      },
      {
        label: "Dt. Vencimento",
        field: "data_lancamento_up",
      },
      {
        label: "Periodicidade",
        field: "pagar.periodicidade.descricao",
      },
      {
        label: "Valor",
        field: "val",
        type: "decimal",
      },
      {
        label: "Status",
        field: "status",
        filterOptions: {
          enabled: true, // enable filter for this column
          placeholder: "Filtrar",
          filterDropdownItems: ["PAGO", "À PAGAR"],
        },
      },
      {
        label: "Ação",
        field: "id_movimentacao",
      },
    ],
    colunas: [
      {
        nome: "ID",
        index: "id_movimentacao",
        isHtml: false,
      },
      {
        nome: "Pagamento",
        isHtml: false,
        index: "pagar.nome",
      },
      {
        nome: "Categoria",
        index: "pagar.categoria.nome",
        isHtml: false,
      },
      {
        nome: "Credor",
        index: "obj.pessoa.nome",
        isHtml: false,
      },
      {
        nome: "Dt. Pagamento",
        index: "data_efetivacao",
        isHtml: false,
      },
      {
        nome: "Dt. Vencimento",
        isHtml: false,
        index: "data_lancamento_up",
      },
      {
        nome: "Periodicidade",
        isHtml: false,
        index: "pagar.periodicidade.descricao",
      },
      {
        nome: "Valor",
        index: "val",
        isValor: true,
      },
      {
        nome: "Status",
        isHtml: false,
        index: "status",
        img: "flaticon-attachment",
        conditionalImg: "anexo",
      },
    ],
    dados: [],
    ocorrencia: [],
    monta: false,
  }),
  computed: {
    ...mapState("auth", ["empresa", "user"]),
    exportarLista: {
      get() {
        return this.export;
      },
      set(nValue) {
        this.export = nValue;
      },
    },
    totalLista() {
      return this.dados
        .filter((f) => f.efetivado != true)
        .reduce((acc, value) => {
          return acc + Number(value.val);
        }, 0);
    },
    totalEfetivo() {
      return this.dados
        .filter((f) => f.efetivado == true)
        .reduce((acc, value) => {
          return acc + Number(value.val);
        }, 0);
    },
  },
  mounted() {
    let data = new Date();
    data = new Date(data.getFullYear(), data.getMonth(), 1);
    let data2 = new Date(data.getFullYear(), data.getMonth() + 1, 1);
    data2 = data2.setDate(data2.getDate() - 1);
    // this.dataInicio = this.$moment(data).format("YYYY-MM-DD");
    // this.dataFim = this.$moment(data2).format("YYYY-MM-DD");

    if (localStorage.getItem("dataInicioPagar")) {
      this.dataInicio = this.$moment(
        localStorage.getItem("dataInicioPagar")
      ).format("YYYY-MM-DD");
    } else {
      this.dataInicio = this.$moment(data).format("YYYY-MM-DD");
    }
    if (localStorage.getItem("dataFimPagar")) {
      this.dataFim = this.$moment(localStorage.getItem("dataFimPagar")).format(
        "YYYY-MM-DD"
      );
    } else {
      this.dataFim = this.$moment(data2).format("YYYY-MM-DD");
    }
    // this.dataFim = this.$moment(data2).format("YYYY-MM-DD");
    this.load();
    if (this.user.leitura == true) {
      this.buttons = [];
    }
  },
  methods: {
    ...mapActions("movimentacao", [
      "ActionGetListMovimento",
      "ActionDeleteMovimento",
      "ActionEfetivarMovimentacaoMuitos",
    ]),
    ...mapActions("contaPagar", ["ActionInactiveContaPagar"]),
    ...mapActions("anexo", ["ActionCriaAnexo", "ActionUploadAnexo"]),
    podeAnexar() {
      return this.ver.anexo == null && this.ver.status == "PAGO";
    },

    anexar() {
      window.KTApp.block("#modaload", {
        overlayColor: "#000000",
        state: "primary",
        message: "Carregando...",
      });
      console.log(this.fileRecordsForUpload);
      let dados = this.fileRecordsForUpload[0].file;
      this.ActionCriaAnexo({
        id_conta_pagar: this.ver.id_conta_pagar,
        id_movimentacao: this.ver.id_movimentacao,
        nome: `${this.ver.pagar.nome} - ${this.$moment(
          this.ver.data_lancamento
        ).format("DD/MM/YYYY")}`,
        descricao: this.ver.descricao,
      }).then((r) => {
        if (r.sucesso == true) {
          let obj = {
            id: r.anexo.id_anexo,
            anexo: dados,
            op: "contas-pagar",
          };
          this.ActionUploadAnexo(obj).then((x) => {
            this.$swal
              .fire({
                icon: x.sucesso == true ? "success" : "error",
                title: x.sucesso == true ? `Sucesso` : "Atenção / Erro",
                text:
                  x.sucesso == true
                    ? `Anexo adicionado com sucesso`
                    : x.error.message,
              })
              .then(() => {
                this.ver = undefined;
                this.fileRecordsForUpload = [];
                this.fileRecords = [];
                this.dados = [];

                this.load();
                window.$("#exampleModal").modal("hide");
                window.KTApp.unblock("#modaload");
                window.location.reload();
              });
          });
        }
      });

      this.fileRecordsForUpload = [];
    },
    deleteUploadedFile: function (fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord
      );
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload =
        this.fileRecordsForUpload.concat(validFileRecords);
    },
    onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.$swal
          .fire({
            icon: "question",
            title: "Aviso",
            text: "Deseja realmente excluir este anexo?",
            showCloseButton: true,
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: "Não",
            confirmButtonText: "Sim",
          })
          .then((r) => {
            if (r.value) {
              this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
            }
          });
      }
    },
    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
    load() {
      window.KTApp.block("#kt_blockui_content", {
        overlayColor: "#000000",
        state: "primary",
        message: "Carregando...",
      });
      this.dados = [];

      this.ActionGetListMovimento({
        id: this.empresa.id_empresa,
        isPagar: true,
        dataInicio: this.dataInicio,
        dataFim: this.dataFim,
      }).then((r) => {
        /* eslint-disable no-debugger */

        // this.dados = r;
        // r = r.filter((f) => {
        //   return (
        //     f.data_lancamento >= this.dataInicio &&
        //     f.data_lancamento <= this.dataFim
        //   );
        // });
        try {
          r.map((el) => {
            el["status"] = el.efetivado == true ? "PAGO" : "À PAGAR";
            el["obj"] =
              el.pagar.funcionario || el.pagar.fornecedor || el.pagar.cliente;
            if (el.obj)
              el.obj.bancarios =
                el.obj?.conta_bancaria || el.obj?.contaBancaria;

            el.data_inclusao = this.$moment(el.data_inclusao).format(
              "DD/MM/YYYY"
            );
            el["data_lancamento_up"] = el.data_lancamento
              ? this.$moment(el.data_lancamento).format("DD/MM/YYYY")
              : "";
            el["data_efetivacao"] = el.data_efetivacao
              ? this.$moment(el.data_efetivacao).format("DD/MM/YYYY")
              : "";
            el.val = Number(JSON.parse(JSON.stringify(Number(el.valor))));
            el.valor_sem = Number(
              JSON.parse(JSON.stringify(Number(el.valor)))
            ).toLocaleString("pt-BR");
            el.valor = Number(el.valor).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
            el.pagar.valor_sem = Number(
              JSON.parse(JSON.stringify(Number(el.pagar.valor)))
            ).toLocaleString("pt-BR");
            el.pagar.valor = Number(el.pagar.valor).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
            el.juros_sem = Number(
              JSON.parse(JSON.stringify(Number(el.juros)))
            ).toLocaleString("pt-BR");
            el.juros = Number(el.juros).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
            el.descontos_sem = Number(
              JSON.parse(JSON.stringify(Number(el.descontos)))
            ).toLocaleString("pt-BR");
            el.descontos = Number(el.descontos).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
            // el.valor = Number(el.valor).toLocaleString("pt-BR", {
            //   style: "currency",
            //   currency: "BRL",
            // });
            el.valor_export = el.efetivado == true ? el.valor_sem : null;
          });
          this.dados = r;
          console.log(this.dados);
          this.monta = true;
          setTimeout(() => {
            window.KTApp.unblock("#kt_blockui_content");
          }, 1000);
        } catch (error) {
        }
      });
    },
    visualizar(item) {
      this.ver = undefined;
      this.ver = JSON.parse(JSON.stringify(item));
      console.log(this.ver);
    },
    async dadosConcliacao(itens) {
      console.log(itens);
      if (itens.filter((f) => f.status == "PAGO").length > 0) {
        this.$swal.fire({
          icon: "warning",
          title: "Aviso",
          text: "Contas já pagas foram selecionadas",
        });
        return;
      }
      let r = await this.$swal.fire({
        icon: "question",
        title: "Aviso",
        text: "Deseja realmente conciliar este(s) lançamento(s)?",
        showCloseButton: true,
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
      });

      if (!r.value) return;

      try {
        window.KTApp.block("#kt_blockui_content", {
          overlayColor: "#000000",
          state: "primary",
          message: "Conciliando...",
        });

        let dados = itens.map((x) => {
          return {
            data_efetivacao: x.data_lancamento, //this.$moment(new Date()).format("YYYY-MM-DD"),
            descontos: 0,
            juros: 0,
            codigo_efetivacao: undefined,
            efetivado: true,
            id_movimentacao: x.id_movimentacao,
          };
        });
        let resp = await this.ActionEfetivarMovimentacaoMuitos(dados);
        window.KTApp.unblock("#kt_blockui_content");
        if (resp.sucesso == true) {
          this.$swal.fire({
            icon: "success",
            title: "Sucesso",
            text: "Contas concliadas com sucesso",
          });
          this.load();
        }
      } catch (error) {
        window.KTApp.unblock("#kt_blockui_content");
      }
    },
    inativar(item) {
      this.$swal
        .fire({
          icon: "question",
          title: "Aviso",
          text: "Deseja realmente excluir este lançamento?",
          showCloseButton: true,
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: "Não",
          confirmButtonText: "Sim",
        })
        .then((resp) => {
          if (resp.value) {
            if (item.data_lancamento < item.pagar.data_fim) {
              this.$swal
                .fire({
                  icon: "question",
                  title: "Aviso",
                  text: "Este lançamento de Contas a pagar tem mais parcelas, deseja aplicar para todas?",
                  showCloseButton: true,
                  showCancelButton: true,
                  showConfirmButton: true,
                  cancelButtonText: "Não ",
                  confirmButtonText: "Sim",
                })
                .then((r) => {
                  if (r.value) {
                    this.ActionInactiveContaPagar({
                      id_conta: item.id_conta_pagar,
                      id_movimentacao: item.id_movimentacao,
                    }).then((rx) => {
                      if (rx.sucesso == true) {
                        this.$swal
                          .fire({
                            icon: "success",
                            title: "Sucesso",
                            text: "Todas as Contas à pagar, associadas foram excluida com sucesso",
                          })
                          .then(() => {
                            this.load();
                          });
                      }
                    });
                  } else {
                    this.ActionDeleteMovimento(item.id_movimentacao).then(
                      (rx) => {
                        if (rx.sucesso == true) {
                          this.$swal
                            .fire({
                              icon: "success",
                              title: "Sucesso",
                              text: "Conta à pagar excluida com sucesso",
                            })
                            .then(() => {
                              this.load();
                            });
                        }
                      }
                    );
                  }
                });
            } else {
              this.ActionDeleteMovimento(item.id_movimentacao).then((rx) => {
                if (rx.sucesso == true) {
                  this.$swal
                    .fire({
                      icon: "success",
                      title: "Sucesso",
                      text: "Conta à pagar excluida com sucesso",
                    })
                    .then(() => {
                      this.load();
                    });
                }
              });
            }
          }
        });
    },
    editar(item) {
      this.$router.push(`/conta-pagar/${item.id_movimentacao}`);
    },
  },
  beforeDestroy() {
    console.log(this.$route);
    if (this.$route.path.includes("conta-pagar") == false) {
      localStorage.removeItem("dataInicioPagar");
      localStorage.removeItem("dataFimPagar");
    }
  },
  watch: {
    empresa(nValue) {
      this.empresa = nValue;
      this.load();
    },
    dataFim(nValue) {
      this.dataFim = nValue;
      localStorage.setItem("dataFimPagar", this.dataFim);
      this.load();
    },
    dataInicio(nValue) {
      this.dataInicio = nValue;
      localStorage.setItem("dataInicioPagar", this.dataInicio);
      this.load();
    },
  },
};
</script>