<template>
  <!--begin::Content-->
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Subheader-->
    <sub-header :titulo="titulo" :buttons="buttons"></sub-header>
    <!--end::Subheader-->
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Dashboard-->
        <!--begin::Row-->

        <div class="row">
          <div class="col-lg-12">
            <div class="card card-custom gutter-b example example-compact">
              <div class="card-header">
                <h3 class="card-title">1. Cadastrando {{ nome }}</h3>
                <div class="card-toolbar"></div>
              </div>
              <!--begin::Form-->
              <form class="form">
                <div class="card-body">
                  <div class="form-group row">
                    <div class="col-lg-1">
                      <label>Código:</label>
                      <input
                        type="number"
                        disabled
                        v-model="data.id"
                        class="form-control"
                        placeholder="Código interno serviço"
                      />
                    </div>

                    <div class="col-lg-11">
                      <label>Nome:*</label>
                      <input
                        type="text"
                        :disabled="disable"
                        v-model="data.nome"
                        class="form-control"
                        :placeholder="nome"
                      />
                    </div>
                  </div>

                  <div class="separator separator-dashed my-10"></div>
                  <div class="form-group row">
                    <div class="col-lg-6">
                      <label>Status:</label> &nbsp;
                      <toggle-button
                        :disabled="disable"
                        v-model="data.ativo"
                        :sync="true"
                        :labels="true"
                        :font-size="15"
                        :width="80"
                        :height="40"
                      />
                      <!-- <input
                    data-switch="true"
                    type="checkbox"
                    name="switch"
                    id="test"
                    data-on-color="success"
                    v-model="funcionario.ativo"
                      />-->
                      <span class="form-text text-muted"></span>
                    </div>
                  </div>
                </div>

                <div class="card-footer">
                  <div class="row">
                    <div class="col-lg-12 ml-lg-auto" id="kt_blockui_content">
                      <button
                        type="button"
                        @click="salvar()"
                        class="btn btn-primary mr-2"
                      >
                        {{ btnLabel }}
                      </button>
                      <button
                        type="reset"
                        @click.prevent="voltar()"
                        class="btn btn-secondary"
                      >
                        Cancelar
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <!--end::Form-->
            </div>
          </div>
        </div>

        <!--begin::Row-->

        <!--end::Row-->
        <!--end::Dashboard-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
  <!--end::Content-->
</template>
<script>
import SubHeader from "../layout/SubHeader";
import { mapState } from "vuex";

export default {
  props: {
    data: Object,
    disable: Boolean,
    titulo: String,
    nome: String,
    buttons: {
      type: Array,
      default: new Array(),
    },
    opcao: String,
  },
  components: {
    SubHeader,
  },
  computed: {
    ...mapState("auth", ["user"]),
    btnLabel() {
      if (this.opcao === "A") {
        return "Adicionar";
      } else {
        return "Atualizar";
      }
    },
  },
  mounted() {
    window.$("[data-switch=true]").bootstrapSwitch();
  },
  watch: {
    opcao(nValue) {
      if (nValue) {
        this.opcao = nValue;
      }
    },
    data(nValue) {
      if (nValue) {
        this.data = nValue;
        window.$("[data-switch=true]").bootstrapSwitch();
      }
    },
    disable(nValue) {
      if (nValue) {
        this.disable = nValue;
      }
    },
    titulo(nValue) {
      if (nValue) {
        this.titulo = nValue;
      }
    },
    nome(nValue) {
      if (nValue) {
        this.nome = nValue;
      }
    },
    buttons(nValue) {
      if (nValue) {
        this.buttons = nValue;
      }
    },
  },
  methods: {
    voltar() {
      this.$emit("onCancel", true);
    },
    salvar() {
      if (this.data.nome == "") {
        this.$swal.fire({
          icon: "warning",
          title: "Atenção",
          text: "Favor Preencher o campo Nome",
        });
        return;
      }
      this.$emit("onSave", this.data);
    },
  },
};
</script>