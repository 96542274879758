import services from "@/http";
import { http } from "@/http";
// import * as types from "./mutation-types";

export const ActionGetListOrcamento = (context) => {
    return http.get(`orcamento/all?id_empresa=${context.rootState.auth.empresa.id_empresa}`).then(r => {
        return r.data;
    });
};

export const ActionGetListOrcamentoActive = (context) => {
    return http.get(`orcamento/active?id_empresa=${context.rootState.auth.empresa.id_empresa}`).then(r => {
        return r.data;
    });
};


export const ActionGetOrcamento = (context, payload) => {
    return services.orcamento.get({ id: payload }).then(r => {
        return r.data;
    });
};

export const ActionUpdateOrcamento = (context, payload) => {
    return services.orcamento.update(payload).then(r => {
        return r.data;
    });
};

export const ActionCreateOrcamento = (context, payload) => {
    return services.orcamento.create(payload).then(r => {
        return r.data;
    });
};

export const ActionUpdateOrcamentoEnviar = (context, payload) => {
    return http.put(`orcamento?envia=true`, payload).then(r => {
        return r.data;
    });
};

export const ActionCreateOrcamentoEnviar = (context, payload) => {
    return http.post(`orcamento?envia=true`, payload).then(r => {
        return r.data;
    });
};


export const ActionInactiveOrcamento = (context, payload) => {
    return services.orcamento.inactive({ id: payload }).then(r => {
        return r.data;
    });
};