import Vue from 'vue'
import VueRouter from 'vue-router'
import { getLocalUsuario } from "../modules/auth/storage";
import routes from "./routes";
import store from "../store";
Vue.use(VueRouter)

const router = new VueRouter({ routes });

router.beforeEach(async (to, from, next) => {
    document.title = `${process.env.VUE_APP_NOME} | ${to.name}`;

    let isLogged = getLocalUsuario();
    if (isLogged != null && isLogged.id_usuario != undefined) {
        await store.dispatch('auth/ActionCheckToken');
    }
    if (to.fullPath != "/login" && (isLogged == null || isLogged.id_usuario == undefined)) {
        try {



            let lista = store.state.user.funcionalidadesHabilitadas.map((f) => f.funcionalidade.url);
            if (lista.length == 0) {
                await store.dispatch('auth/ActionCheckToken');
                next(to.fullPath);
            }
            let apoio = lista.filter(f => to.path.includes(f)).length > 0;
            console.log(lista.filter(f => to.path.includes(f)).length > 0);
            const pagesPermission = !apoio;
            if (pagesPermission) {
                return next("/401");
            } else {
                await store.dispatch('auth/ActionCheckToken');
                next(to.fullPath);
            }

        } catch (error) {
            next('/login');
        }
    } else if ((isLogged != null && isLogged.id_usuario !== undefined) && to.fullPath === '/login') {
        next({ name: 'Dashboard' });
    } else {

        next(true)
    }

});

export default router;
