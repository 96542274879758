<template>
  <Conta
    v-disable-all="user.leitura == true"
    :data="contaPagar"
    :tipo="tipo"
    :titulo="titulo"
    :buttons="buttons"
    @onCancel="voltar"
    @onSave="manter"
    :opcao="op"
    :conciliado="conc"
  />
</template>
<script>
import Conta from "../Components/conta";
import { mapActions, mapState } from "vuex";
export default {
  name: "ContasPagar",
  props: {
    conciliado: {
      type: Object,
      default: undefined,
    },
  },
  data: () => ({
    conta: {
      IsFuncionario: false,
      id_categoria: undefined,
      id_funcionario: undefined,
      id_obj: undefined,
      id_periodicidade: undefined,
      recorrecia: undefined,
      data_inclusao: "",
      data_fim: "",
      nome: "",
      descricao: "",
      anexo: "",
      valor: 0,
    },
    tipo: "Pagamento",
    opcao: "A",
    buttons: [
      {
        link: "/conta-pagar",
        titulo: "ver todas Contas",
        color: "btn-light-primary",
      },
    ],
    usuario: {},
  }),
  computed: {
    ...mapState("auth", ["empresa", "user"]),
    op() {
      return this.opcao;
    },
    conc() {
      return this.conciliado;
    },
    contaPagar: {
      get() {
        return this.conta;
      },
      set(nValue) {
        this.conta = nValue;
      },
    },
    titulo() {
      let id = this.$route.params.id;
      if (id == "0") {
        return "Adicionando Conta à Pagar";
      } else {
        return "Editando Conta à Pagar";
      }
    },
  },
  mounted() {
    this.opcao = "A";
    if (this.$route.params.id != 0 && this.$route.params.id != undefined) {
      this.opcao = "E";
      window.KTApp.blockPage({
        overlayColor: "#000000",
        state: "primary",
        message: "Carregando...",
      });
      this.ActionGetMovimentacao(this.$route.params.id).then((r) => {
        // let conta = r;
        this.contaPagar = {
          // id_categoria: r.pagar.categoria.id_categoria,
          id_categoria: r.pagar.categoria
            ? r.pagar.categoria.id_categoria
            : undefined,
          id_periodicidade: r.pagar.periodicidade
            ? r.pagar.periodicidade.id_periodicidade
            : null,
          ocorrencia: r.pagar.ocorrencia,
          id_funcionario: r.pagar.id_funcionario,
          data_inclusao: this.$moment(r.data_inclusao).format("YYYY-MM-DD"),
          data_lancamento: r.data_lancamento
            ? this.$moment(r.data_lancamento).format("YYYY-MM-DD")
            : undefined,
          nome: r.pagar.nome,
          descricao: r.descricao,
          anexo: null,
          valor: r.valor,
          id_conta_pagar: r.id_conta_pagar,
          id_conta: r.id_conta_pagar,
          id_movimentacao: r.id_movimentacao,
          pagar: r.pagar,
          recebimento:
            r.efetivado == true
              ? {
                  valor: r.valor,
                  juros: r.juros,
                  descontos: r.descontos,
                  data_efetivacao: r.data_efetivacao,
                  codigo_efetivacao: r.codigo_efetivacao,
                }
              : undefined,
        };
        if (r.pagar.id_funcionario == undefined) {
          this.contaPagar["id_obj"] = r.pagar.id_cliente
            ? "C" + r.pagar.id_cliente
            : "F" + r.pagar.id_fornecedor;
        }
        window.KTApp.unblockPage();
      });
    }
  },
  methods: {
    ...mapActions("contaPagar", [
      "ActionCreateContaPagar",
      "ActionUpdateContaPagar",
      "ActionCreateConciliadoContaPagar",
    ]),
    ...mapActions("movimentacao", [
      "ActionGetMovimentacao",
      "ActionUpdateMovimento",
      "ActionEfetivarMovimentacao",
    ]),
    ...mapActions("anexo", ["ActionUploadAnexo", "ActionCriaAnexo"]),
    manter(item) {
      if (this.contaPagar.IsFuncionario == true) {
        this.contaPagar.IsFuncionario = this.contaPagar.id_obj;
      }
      this.contaPagar = item;
      this.contaPagar["id_empresa"] = this.$store.state.auth.empresa.id_empresa;
      if (
        this.contaPagar.data_lancamento == undefined ||
        this.contaPagar.data_lancamento == null
      ) {
        this.contaPagar.data_lancamento = this.contaPagar.data_inclusao;
      }

      if (this.opcao == "A") {
        this.contaPagar.data_inicio = this.contaPagar.data_lancamento;
        this.contaPagar.ocorrencia = this.contaPagar.ocorrencia
          ? this.contaPagar.ocorrencia
          : 1;
        let resp = null;

        if (this.contaPagar.recebimento != undefined) {
          resp = this.ActionCreateConciliadoContaPagar(this.contaPagar);
        } else {
          resp = this.ActionCreateContaPagar(this.contaPagar);
        }
        window.KTApp.blockPage({
          overlayColor: "#000000",
          state: "primary",
          message: "Carregando...",
        });
        resp.then((x) => {
          window.KTApp.unblockPage();
          if (x.sucesso == true) {
            if (this.contaPagar.anexo) {
              this.ActionCriaAnexo({
                id_conta_pagar: x.contasPagar.id_conta,
                nome: `${this.contaPagar.nome} - ${this.$moment(
                  this.contaPagar.data_lancamento
                ).format("DD/MM/YYYY")}`,
                descricao: this.contaPagar.descricao,
              }).then((r) => {
                if (r.sucesso == true) {
                  let obj = {
                    id: r.anexo.id_anexo,
                    anexo: this.contaPagar.anexo,
                    op: "contas-pagar",
                    conta: x.contasPagar.id_conta,
                    tipo: "P",
                  };
                  this.ActionUploadAnexo(obj).then((xx) => {
                    this.$swal
                      .fire({
                        icon: xx.sucesso == true ? "success" : "error",
                        title:
                          xx.sucesso == true ? `Sucesso` : "Atenção / Erro",
                        text:
                          xx.sucesso == true
                            ? this.opcao == "A"
                              ? `Conta adicionada com sucesso`
                              : "Conta Alterada com Sucesso"
                            : xx.error.message,
                      })
                      .then(() => {
                        if (this.conc != undefined) {
                          this.$emit("onNovo", true);
                        } else {
                          this.$router.push("/conta-pagar");
                        }
                      });
                  });
                }
              });
            } else {
              this.$swal
                .fire({
                  icon: x.sucesso == true ? "success" : "error",
                  title: x.sucesso == true ? `Sucesso` : "Atenção / Erro",
                  text:
                    x.sucesso == true
                      ? this.opcao == "A"
                        ? `Conta adicionada com sucesso`
                        : "Conta Alterada com Sucesso"
                      : x.error.message,
                })
                .then(() => {
                  if (this.conc != undefined) {
                    this.$emit("onNovo", true);
                  } else {
                    this.$router.push("/conta-pagar");
                  }
                });
            }

            // this.$swal
            //   .fire({
            //     icon: x.sucesso == true ? "success" : "error",
            //     title: x.sucesso == true ? `Sucesso` : "Atenção / Erro",
            //     text:
            //       x.sucesso == true
            //         ? this.opcao == "A"
            //           ? `Conta adicionada com sucesso`
            //           : "Conta Alterada com Sucesso"
            //         : x.error.message,
            //   })
            //   .then(() => {
            //     if (this.conc != undefined) {
            //       this.$emit("onNovo", true);
            //     } else {
            //       this.$router.push("/conta-pagar");
            //     }
            //   });
          }
        });
      } else if (
        this.contaPagar.recebimento == undefined &&
        this.contaPagar.ocorrencia > 1 &&
        (this.contaPagar.pagar.data_fim == null ||
          new Date(this.contaPagar.data_lancamento) <
            new Date(this.contaPagar.pagar.data_fim))
      ) {
        this.$swal
          .fire({
            title: "Atenção",
            icon: "question",
            html: `Você está prestes a alterar um lançamento recorrente. Deseja alterar todos os lançamentos? `,
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
          })
          .then((x) => {
            let resp;
            if (x.value) {
              resp = this.ActionUpdateContaPagar(this.contaPagar);
            } else {
              resp = this.ActionUpdateMovimento(this.contaPagar);
            }
            resp.then((x) => {
              if (this.contaPagar.anexo) {
                this.ActionCriaAnexo({
                  id_conta_pagar: this.contaPagar.id_conta_pagar,
                  id_movimentacao: this.contaPagar.id_movimentacao,
                  nome: `${this.contaPagar.nome} - ${this.$moment(
                    this.contaPagar.data_lancamento
                  ).format("DD/MM/YYYY")}`,
                  descricao: this.contaPagar.descricao,
                }).then((r) => {
                  if (r.sucesso == true) {
                    let obj = {
                      id: r.anexo.id_anexo,
                      anexo: this.contaPagar.anexo,
                      op: "contas-pagar",
                    };
                    this.ActionUploadAnexo(obj).then((x) => {
                      this.$swal
                        .fire({
                          icon: x.sucesso == true ? "success" : "error",
                          title:
                            x.sucesso == true ? `Sucesso` : "Atenção / Erro",
                          text:
                            x.sucesso == true
                              ? this.opcao == "A"
                                ? `Conta adicionada com sucesso`
                                : "Conta Alterada com Sucesso"
                              : x.error.message,
                        })
                        .then(() => {
                          this.$router.push("/conta-pagar");
                        });
                    });
                  }
                });
              } else {
                if (x.sucesso == true) {
                  this.$swal
                    .fire({
                      icon: x.sucesso == true ? "success" : "error",
                      title: x.sucesso == true ? `Sucesso` : "Atenção / Erro",
                      text:
                        x.sucesso == true
                          ? this.opcao == "A"
                            ? `Conta adicionada com sucesso`
                            : "Conta Alterada com Sucesso"
                          : x.error.message,
                    })
                    .then(() => {
                      this.$router.push("/conta-pagar");
                    });
                }
              }
            });
          });
      } else {
        if (this.contaPagar.recebimento != undefined) {
          this.contaPagar.recebimento[
            "id_movimentacao"
          ] = this.contaPagar.id_movimentacao;
          this.ActionEfetivarMovimentacao(this.contaPagar).then((x) => {
            if (this.contaPagar.anexo) {
              this.ActionCriaAnexo({
                id_conta_pagar: this.contaPagar.id_conta_pagar,
                id_movimentacao: this.contaPagar.id_movimentacao,
                nome: `${this.contaPagar.nome} - ${this.$moment(
                  this.contaPagar.data_lancamento
                ).format("DD/MM/YYYY")}`,
                descricao: this.contaPagar.descricao,
              }).then((r) => {
                if (r.sucesso == true) {
                  let obj = {
                    id: r.anexo.id_anexo,
                    anexo: this.contaPagar.anexo,
                    op: "contas-pagar",
                  };
                  this.ActionUploadAnexo(obj).then((xx) => {
                    this.$swal
                      .fire({
                        icon: xx.sucesso == true ? "success" : "error",
                        title:
                          xx.sucesso == true ? `Sucesso` : "Atenção / Erro",
                        text:
                          xx.sucesso == true
                            ? `Conta efetivada com sucesso`
                            : xx.error.message,
                      })
                      .then(() => {
                        this.$router.push("/conta-pagar");
                      });
                  });
                }
              });
            } else {
              if (x.sucesso == true) {
                this.$swal
                  .fire({
                    icon: x.sucesso == true ? "success" : "error",
                    title: x.sucesso == true ? `Sucesso` : "Atenção / Erro",
                    text:
                      x.sucesso == true
                        ? `Conta efetivada com sucesso`
                        : x.error.message,
                  })
                  .then(() => {
                    this.$router.push("/conta-pagar");
                  });
              }
            }
          });
        } else {
          let resp = this.ActionUpdateMovimento(this.contaPagar);
          resp.then((x) => {
            if (this.contaPagar.anexo) {
              this.ActionCriaAnexo({
                id_conta_pagar: this.contaPagar.id_conta_pagar,
                id_movimentacao: this.contaPagar.id_movimentacao,
                nome: `${this.contaPagar.nome} - ${this.$moment(
                  this.contaPagar.data_lancamento
                ).format("DD/MM/YYYY")}`,
                descricao: this.contaPagar.descricao,
              }).then((r) => {
                if (r.sucesso == true) {
                  let obj = {
                    id: r.anexo.id_anexo,
                    anexo: this.contaPagar.anexo,
                    op: "contas-pagar",
                  };
                  this.ActionUploadAnexo(obj).then((xx) => {
                    this.$swal
                      .fire({
                        icon: xx.sucesso == true ? "success" : "error",
                        title:
                          xx.sucesso == true ? `Sucesso` : "Atenção / Erro",
                        text:
                          xx.sucesso == true
                            ? this.opcao == "A"
                              ? `Conta adicionada com sucesso`
                              : "Conta alterada com Sucesso"
                            : xx.error.message,
                      })
                      .then(() => {
                        this.$router.push("/conta-pagar");
                      });
                  });
                }
              });
            } else {
              if (x.sucesso == true) {
                this.$swal
                  .fire({
                    icon: x.sucesso == true ? "success" : "error",
                    title: x.sucesso == true ? `Sucesso` : "Atenção / Erro",
                    text:
                      x.sucesso == true
                        ? this.opcao == "A"
                          ? `Conta adicionada com sucesso`
                          : "Conta alterada com Sucesso"
                        : x.error.message,
                  })
                  .then(() => {
                    this.$router.push("/conta-pagar");
                  });
              }
            }
          });
        }
      }
    },
    voltar() {
      this.$router.push("/conta-pagar");
    },
  },
  components: {
    Conta,
  },
  watch: {
    conciliado(newValue) {
      this.conciliado = newValue;
    },
  },
};
</script>