<template>
  <!--begin::Content-->
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Subheader-->

    <sub-header :titulo="titulo" :buttons="buttons"></sub-header>
    <!--end::Subheader-->
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid" v-disable-all="user.leitura == true">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Dashboard-->
        <!--begin::Row-->

        <div class="card card-custom gutter-b example example-compact">
          <div class="card-body">
            <div class="form-group row">
              <div class="col-lg-6">
                <br />
                <label>Empresa:</label>
                <select-populado
                  :data="cliente.id_empresa"
                  :list="empresas"
                  :fieldKey="'id_empresa'"
                  :fieldText="'pessoa.nome'"
                  @onChange="ChangeEmpresa"
                  :disable="empresa.id_empresa != undefined"
                />
              </div>
              <div class="col-lg-6">
                <br />
                <label>Cliente/Fornecedor:*</label>
                <select-populado
                  :data="cliente.tipo"
                  :list="[
                    { id: 'cliente', text: 'CLIENTE' },
                    { id: 'fornecedor', text: 'FORNECEDOR' },
                  ]"
                  :fieldKey="'id'"
                  :fieldText="'text'"
                  @onChange="ChangeTipoCli"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <!--begin::Card-->
            <DadosPessoais
              :isCliente="false"
              :isFuncionario="false"
              :data="cliente"
              :ordem="1"
            />
            <!--end::Card-->
            <!--begin::Card-->
            <div class="card card-custom gutter-b example example-compact">
              <div class="card-header">
                <h3 class="card-title">2. Informações de Contato</h3>
              </div>
              <div class="card-body">
                <div class="form-group row">
                  <Emails :dados="emails" :disable="false" />
                </div>
                <div class="form-group row">
                  <Endereco :objeto="endereco" :disable="false" />
                </div>
                <div class="form-group row">
                  <Telefones :dados="telefones" :disable="false" />
                </div>
              </div>
            </div>
            <!--end::Card-->

            <!--begin::Card-->
            <ContaBancaria :ordem="3" :objeto="contaBancaria" />
            <!--end::Form-->
            <div class="card-footer">
              <div class="separator separator-dashed my-10"></div>
              <div class="form-group row">
                <div class="col-lg-6">
                  <label>Status:</label> &nbsp; &nbsp;
                  <toggle-button
                    v-model="cliente.ativo"
                    :sync="true"
                    :labels="true"
                    :font-size="15"
                    :width="80"
                    :height="40"
                  />
                  <!-- <input
                    data-switch="true"
                    type="checkbox"
                    name="switch"
                    id="test"
                    data-on-color="success"
                    v-model="funcionario.ativo"
                  />-->
                  <span class="form-text text-muted"></span>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 ml-lg-auto" id="kt_blockui_content">
                  <button
                    type="button"
                    @click="manter()"
                    class="btn btn-primary mr-2"
                  >
                    {{ opcao == "A" ? "Adicionar" : "Atualizar" }}
                  </button>
                  <button
                    type="reset"
                    @click="voltar()"
                    class="btn btn-secondary"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--begin::Row-->

      <!--end::Row-->
      <!--end::Dashboard-->
    </div>
    <!--end::Container-->
    <!--end::Entry-->
  </div>
  <!--end::Content-->
</template>
<script>
import ContaBancaria from "../../components/common/ContaBancaria";
import DadosPessoais from "../../components/common/DadosPessoais";
import Emails from "../../components/common/Emails";
import Endereco from "../../components/common/Endereco";
import Telefones from "../../components/common/Telefones";
import SubHeader from "../../components/layout/SubHeader";
import SelectPopulado from "../../components/forms/SelectPopulado";
import { mapActions, mapState } from "vuex";
export default {
  name: "Cliente",
  data: () => ({
    cli: {
      optante_simples: 0,
      id_empresa: 0,
      responsavel: "",
      pessoa: {
        tipo_pessoa: "",
        nome: "",
        razao_social: "",
        identificador: "",
        ativo: true,
        data_nascimento: null,
        insc_estadual: "",
        insc_municipal: "",
        sexo: "",
        rg: "",
        telefones: [],
        enderecos: [
          {
            cep: "",
            logradouro: "",
            numero: "",
            complemento: "",
            bairro: "",
            cidade: "",
            estado: "",
            ativo: true,
          },
        ],
        emails: [],
      },
    },
    cb: {
      banco: "",
      codigo_banco: "",
      agencia: "",
      dv_agencia: "",
      conta: "",
      dv_conta: "",
      tipo: "",
      ativo: true,
      isPoupanca: false,
    },
    end: {
      cep: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      estado: "",
      ativo: true,
    },
    empresas: [],
    buttons: [
      {
        link: "/cliente",
        titulo: "ver todas Clientes",
        color: "btn-light-primary",
      },
    ],
    usuario: {},
    opcao: "",
  }),
  computed: {
    ...mapState("auth", ["empresa", "user"]),
    titulo() {
      let id = this.$route.params.id;
      if (id == "0") {
        return "Adicionando Cliente / Fornecedor";
      } else {
        return "Editando Cliente / Fornecedor";
      }
    },
    cliente: {
      get() {
        return this.cli;
      },
      set(nValue) {
        this.cli = nValue;
      },
    },
    endereco: {
      get() {
        return this.end;
      },
      set(nValue) {
        this.end = nValue;
      },
    },
    contaBancaria: {
      get() {
        return this.cb;
      },
      set(nValue) {
        this.cb = nValue;
      },
    },
    telefones: {
      get() {
        return this.cli.pessoa.telefones;
      },
      set(nValue) {
        this.cli.pessoa.telefones = nValue;
      },
    },
    emails: {
      get() {
        return this.cli.pessoa.emails;
      },
      set(nValue) {
        this.cli.pessoa.emails = nValue;
      },
    },
  },
  mounted() {
    // this.contaBancaria = this.cliente.contasBancarias[0];
    // this.endereco = this.cliente.pessoa.enderecos[0];
    window.$("[data-switch=true]").bootstrapSwitch();
    if (this.empresa.id_empresa != undefined) {
      this.cliente.id_empresa = this.empresa.id_empresa;
    }
    this.ActionGetEmpresas().then((r) => {
      this.empresas = r;
      console.log(this.empresas);
    });
    this.opcao = "A";
    this.cliente.ativo = true;

    if (this.$route.params.id != 0) {
      this.opcao = "E";
      window.KTApp.blockPage({
        overlayColor: "#000000",
        state: "primary",
        message: "Carregando...",
      });
      this.ActionGetCliente({
        id: this.$route.params.id,
        tipo: this.$route.params.tipo,
      }).then((r) => {
        this.cli = r;

        if (this.cli.pessoa.tipo_pessoa == "F") {
          this.cli.pessoa.cpf = this.cli.pessoa.identificador;
        } else if (this.cli.pessoa.tipo_pessoa == "J") {
          this.cli.pessoa.cnpj = this.cli.pessoa.identificador;
        }
        if (this.cli.pessoa.enderecos.length > 0) {
          this.end = this.cli.pessoa.enderecos[0];
        } else {
          this.end = {
            cep: "",
            logradouro: "",
            numero: "",
            complemento: "",
            bairro: "",
            cidade: "",
            estado: "",
            ativo: true,
          };
        }

        this.cb = this.cli.contaBancaria
          ? this.cli.contaBancaria
          : {
              banco: "",
              codigo_banco: "",
              agencia: "",
              dv_agencia: "",
              conta: "",
              dv_conta: "",
              tipo: "",
              ativo: true,
            };
      });
      window.KTApp.unblockPage();
      // .catch((error) => {
      //   console.log(error);
      //   if (error.data) {
      //     this.$swal.fire({
      //       icon: "error",
      //       title: error.data.error.title || "Oops...",
      //       text: error.data.error.message,
      //     });
      //   } else {
      //     this.$swal.fire({
      //       icon: "error",
      //       title: "Oops...",
      //       text: "Ocorreu um erro inesperado, favor contactar o suporte.",
      //     });
      //   }
      // });
    }
  },
  watch: {
    empresa(nValue) {
      this.empresa = nValue;
      this.cliente.id_empresa = this.empresa.id_empresa;
      if (this.opcao == "E") {
        this.$swal
          .fire({
            icon: "warning",
            title: "Atenção",
            text:
              "Houve mudança de empresa, você será redirecionado para a lista de Cliente / Fornecedor ",
          })
          .then(() => this.$router.push("/cliente"));
      }
    },
  },
  methods: {
    ...mapActions("empresa", ["ActionGetEmpresas"]),
    ...mapActions("cliente", [
      "ActionGetCliente",
      "ActionCreateCliente",
      "ActionUpdateCliente",
    ]),
    manter() {
      try {
        /* if (this.cliente.pessoa.tipo_pessoa == "J") {
          this.cliente.pessoa.data_nascimento = null;
          this.cliente.pessoa.sexo = "";
          this.cliente.pessoa.rg = "";
        }
        if (this.cliente.pessoa.tipo_pessoa == "F") {
          this.cliente.responsavel = null;
          this.cliente.pessoa.razao_social = "";
          this.cliente.pessoa.rg = "";
        } */

        this.cliente.pessoa.identificador =
          this.cliente.pessoa.cpf != undefined &&
          this.cliente.pessoa.tipo_pessoa == "F"
            ? this.cliente.pessoa.cpf
            : this.cliente.pessoa.cnpj != undefined &&
              this.cliente.pessoa.tipo_pessoa == "J"
            ? this.cliente.pessoa.cnpj
            : this.cliente.pessoa.identificador;
        /* original */
        if (
          this.cliente.tipo == "" ||
          this.cliente.tipo == undefined ||
          this.cliente.tipo == null
        ) {
          this.$swal.fire({
            icon: "warning",
            title: "Aviso",
            text: "Campo Tipo obrigatório",
          });
          return;
        }
        if (this.cliente.pessoa.tipo_pessoa == "") {
          this.$swal.fire({
            icon: "warning",
            title: "Aviso",
            text: "Campo Tipo pessoa é obrigatório",
          });
          return;
        }
        /* Fisica */
        if (this.cliente.pessoa.tipo_pessoa == "F") {
          if (this.cliente.pessoa.nome == "") {
            this.$swal.fire({
              icon: "warning",
              title: "Aviso",
              text: "Campo Nome Completo é obrigatório",
            });
            return;
          }
          if (this.cliente.pessoa.nome == "") {
            this.$swal.fire({
              icon: "warning",
              title: "Aviso",
              text: "Campo Nome Completo é obrigatório",
            });
            return;
          }
          if (this.cliente.pessoa.data_nascimento == null) {
            this.$swal.fire({
              icon: "warning",
              title: "Aviso",
              text: "Campo Data de Nascimento é obrigatório",
            });
            return;
          }
          if (this.cliente.pessoa.identificador == "") {
            this.$swal.fire({
              icon: "warning",
              title: "Aviso",
              text: "Campo CPF é obrigatório",
            });
            return;
          }
          if (this.cliente.pessoa.rg == "") {
            this.$swal.fire({
              icon: "warning",
              title: "Aviso",
              text: "Campo RG é obrigatório",
            });
            return;
          }
        }
        /* juridica */
        if (this.cliente.pessoa.tipo_pessoa == "J") {
          if (this.cliente.pessoa.nome == "") {
            this.$swal.fire({
              icon: "warning",
              title: "Aviso",
              text: "Campo Nome Fantasia é obrigatório",
            });
            return;
          }
          if (this.cliente.pessoa.nome == "") {
            this.$swal.fire({
              icon: "warning",
              title: "Aviso",
              text: "Campo Nome Fantasia é obrigatório",
            });
            return;
          }
          if (this.cliente.responsavel == "") {
            this.$swal.fire({
              icon: "warning",
              title: "Aviso",
              text: "Campo Responsável é obrigatório",
            });
            return;
          }
          if (this.cliente.pessoa.razao_social == "") {
            this.$swal.fire({
              icon: "warning",
              title: "Aviso",
              text: "Campo Razão Social é obrigatório",
            });
            return;
          }
          if (this.cliente.pessoa.identificador == "") {
            this.$swal.fire({
              icon: "warning",
              title: "Aviso",
              text: "Campo CNPJ é obrigatório",
            });
            return;
          }
        }
        let r;
        this.cliente.contaBancaria = this.contaBancaria;
        this.cliente.pessoa.enderecos[0] = this.endereco;

        if (this.opcao == "A") {
          r = this.ActionCreateCliente(this.cliente);
        } else {
          r = this.ActionUpdateCliente(this.cliente);
        }
        window.KTApp.blockPage({
          overlayColor: "#000000",
          state: "primary",
          message: "Carregando...",
        });

        r.then((x) => {
          window.KTApp.unblockPage();
          if (x.sucesso == true) {
            this.$swal
              .fire({
                icon: x.sucesso == true ? "success" : "error",
                title: x.sucesso == true ? `Sucesso` : "Atenção / Erro",
                text:
                  x.sucesso == true
                    ? `${
                        this.cliente.tipo == "cliente"
                          ? "Cliente"
                          : this.cliente.tipo == "fornecedor"
                          ? "Fornecedor"
                          : ""
                      }  ${
                        this.opcao == "A" ? "adicionado" : "atualizado"
                      } com sucesso`
                    : x.error.message,
              })
              .then(() => {
                this.$router.push("/cliente");
              });
          }
        });
      } catch (error) {
        // console.log(error);
        // if (error.data) {
        //   console.log(error.data);
        //   this.$swal.fire({
        //     icon: "error",
        //     title: error.data.error.title || "Oops...",
        //     text: error.data.error.message,
        //   });
        // } else {
        //   this.$swal.fire({
        //     icon: "error",
        //     title: "Oops...",
        //     text: "Ocorreu um erro inesperado, favor contactar o suporte.",
        //   });
        // }
      }
    },
    voltar() {
      this.$router.push("/cliente");
    },
    ChangeEmpresa(item) {
      this.cliente.id_empresa = item;
    },
    ChangeTipoCli(item) {
      this.cliente.tipo = item;
    },
  },
  components: {
    ContaBancaria,
    DadosPessoais,
    Endereco,
    Emails,
    Telefones,
    SubHeader,
    SelectPopulado,
  },
};
</script>