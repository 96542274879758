import { http } from "@/http";
export const ActionUploadAnexo = (context, payload) => {

    let form = new FormData()
    form.append("anexo", payload.anexo);
    let url = `anexo/upload/${payload.id}/${payload.op}`;
    if (payload.conta) {
        url += `?conta=${payload.conta}&tipo=${payload.tipo}`;
    }
    return http.post(url, form, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }).then(r => r.data);

};

export const ActionCriaAnexo = (context, payload) => {

    return http.post(`anexo`, payload, {
    }).then(r => r.data);

};

export const ActionGetListAnexo = (context) => {
    return http.get(`anexo/all?id_empresa=${context.rootState.auth.empresa.id_empresa}`, {
    }).then(r => r.data);
};