<template>
  <!-- begin::User Panel-->
  <div
    v-if="this.$route.path != '/login'"
    id="kt_quick_user"
    class="offcanvas offcanvas-right p-10"
  >
    <!--begin::Header-->
    <div
      class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
    >
      <h3 class="font-weight-bold m-0">
        Seu perfil &ensp;
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </h3>
    </div>
    <!--end::Header-->
    <!--begin::Content-->
    <div class="offcanvas-content pr-5 mr-n5">
      <!--begin::Header-->
      <div class="d-flex align-items-center mt-5">
        <div class="symbol symbol-100 mr-5">
          <div
            class="symbol-label"
            :style="`background-image:url('${foto}')`"
          ></div>
          <i class="symbol-badge bg-success"></i>
        </div>
        <div class="d-flex flex-column">
          <div class="text-muted mt-1">
            <b>Empresa: {{ nomeEmpresa }}</b>
          </div>
          <a
            href="#"
            class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >{{ nome }}</a
          >

          <div class="text-muted mt-1">Função: {{ funcao }}</div>
          <div class="navi mt-2">
            <a href="#" class="navi-item">
              <span class="navi-link p-0 pb-2">
                <span class="navi-icon mr-1">
                  <span class="svg-icon svg-icon-lg svg-icon-primary">
                    <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-notification.svg-->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24" />
                        <path
                          d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z"
                          fill="#000000"
                        />
                        <circle
                          fill="#000000"
                          opacity="0.3"
                          cx="19.5"
                          cy="17.5"
                          r="2.5"
                        />
                      </g>
                    </svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
                <span class="navi-text text-muted text-hover-primary">{{
                  user.login
                }}</span>
              </span>
            </a>
            <a
              href="#"
              @click.prevent="sair"
              class="btn btn-sm btn-light-primary font-weight-bolder py-2 px-5"
              >Sair</a
            >
          </div>
        </div>
      </div>
      <!--end::Header-->

      <!--begin::Separator-->
      <div class="separator separator-dashed my-7"></div>
      <span class="svg-icon svg-icon-lg svg-icon-primary">
        <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-notification.svg-->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
        >
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <rect x="0" y="0" width="24" height="24" />
            <path
              d="M13.5,21 L13.5,18 C13.5,17.4477153 13.0522847,17 12.5,17 L11.5,17 C10.9477153,17 10.5,17.4477153 10.5,18 L10.5,21 L5,21 L5,4 C5,2.8954305 5.8954305,2 7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,21 L13.5,21 Z M9,4 C8.44771525,4 8,4.44771525 8,5 L8,6 C8,6.55228475 8.44771525,7 9,7 L10,7 C10.5522847,7 11,6.55228475 11,6 L11,5 C11,4.44771525 10.5522847,4 10,4 L9,4 Z M14,4 C13.4477153,4 13,4.44771525 13,5 L13,6 C13,6.55228475 13.4477153,7 14,7 L15,7 C15.5522847,7 16,6.55228475 16,6 L16,5 C16,4.44771525 15.5522847,4 15,4 L14,4 Z M9,8 C8.44771525,8 8,8.44771525 8,9 L8,10 C8,10.5522847 8.44771525,11 9,11 L10,11 C10.5522847,11 11,10.5522847 11,10 L11,9 C11,8.44771525 10.5522847,8 10,8 L9,8 Z M9,12 C8.44771525,12 8,12.4477153 8,13 L8,14 C8,14.5522847 8.44771525,15 9,15 L10,15 C10.5522847,15 11,14.5522847 11,14 L11,13 C11,12.4477153 10.5522847,12 10,12 L9,12 Z M14,12 C13.4477153,12 13,12.4477153 13,13 L13,14 C13,14.5522847 13.4477153,15 14,15 L15,15 C15.5522847,15 16,14.5522847 16,14 L16,13 C16,12.4477153 15.5522847,12 15,12 L14,12 Z"
              fill="#000000"
            />
            <rect fill="#FFFFFF" x="13" y="8" width="3" height="3" rx="1" />
            <path
              d="M4,21 L20,21 C20.5522847,21 21,21.4477153 21,22 L21,22.4 C21,22.7313708 20.7313708,23 20.4,23 L3.6,23 C3.26862915,23 3,22.7313708 3,22.4 L3,22 C3,21.4477153 3.44771525,21 4,21 Z"
              fill="#000000"
              opacity="0.3"
            />
          </g>
        </svg>
        <!--end::Svg Icon-->
      </span>
      Mudar de empresa:
      <select-populado
        :list="empresas"
        :data="idEmpresa"
        :fieldKey="'id_empresa'"
        :fieldText="'pessoa.nome'"
        @onChange="atualizaEmpresa"
      />

      <div class="separator separator-dashed my-7"></div>
      <span class="svg-icon svg-icon-lg svg-icon-primary">
        <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-notification.svg-->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
        >
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <rect x="0" y="0" width="24" height="24" />
            <polygon
              fill="#000000"
              opacity="0.3"
              points="5 15 3 21.5 9.5 19.5"
            />
            <path
              d="M16,10 L16,9.5 C16,8.11928813 14.8807119,7 13.5,7 C12.1192881,7 11,8.11928813 11,9.5 L11,10 C10.4477153,10 10,10.4477153 10,11 L10,14 C10,14.5522847 10.4477153,15 11,15 L16,15 C16.5522847,15 17,14.5522847 17,14 L17,11 C17,10.4477153 16.5522847,10 16,10 Z M13.5,21 C8.25329488,21 4,16.7467051 4,11.5 C4,6.25329488 8.25329488,2 13.5,2 C18.7467051,2 23,6.25329488 23,11.5 C23,16.7467051 18.7467051,21 13.5,21 Z M13.5,8 L13.5,8 C14.3284271,8 15,8.67157288 15,9.5 L15,10 L12,10 L12,9.5 C12,8.67157288 12.6715729,8 13.5,8 Z"
              fill="#000000"
            />
          </g>
        </svg>
        <!--end::Svg Icon-->
      </span>
      Alterar senha:
      <input
        type="password"
        class="form-control"
        v-model="obj.senha"
        placeholder="Nova senha"
        style="margin-bottom: 10px"
      />

      <input
        type="password"
        class="form-control"
        v-model="obj.confirmSenha"
        placeholder="Confirmar senha"
      />
      <button
        :disabled="obj.confirmSenha == '' || obj.senha == ''"
        @click="AlterarSenha()"
        class="btn btn-success form-control"
      >
        Alterar
      </button>

      <!-- <select class="browser-default custom-select">
        <option selected>Selecione...</option>
        <option value="1">Netolab</option>
        <option value="2">RDS</option>
        <option value="3">NetoVídeo</option>
      </select>-->

      <!--end::Separator-->
      <!--begin::Notifications-->

      <!--end::Notifications-->
    </div>
    <!--end::Content-->
  </div>
  <!-- end::User Panel-->
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import SelectPopulado from "../forms/SelectPopulado.vue";
export default {
  name: "UserPanel",
  data: () => ({
    obj: {
      senha: "",
      confirmSenha: "",
      idUsuario: 0,
    },
  }),
  updated() {
    window.KTLayoutQuickUser.init("kt_quick_user");
    window.KTUtil.init();
  },
  mounted() {
    window.KTLayoutQuickUser.init("kt_quick_user");
    setTimeout(() => {
      window.KTLayoutQuickUser.init("kt_quick_user");
    }, 90);
  },
  components: {
    SelectPopulado,
  },
  computed: {
    ...mapState("auth", ["user", "empresa"]),
    ...mapGetters("auth", ["getEmpresa"]),
    nome() {
      if (this.user.funcionario) {
        return this.user.funcionario.pessoa.nome;
      } else {
        return this.user.login;
      }
    },
    idEmpresa() {
      return this.getEmpresa ? this.getEmpresa.id_empresa : 0;
    },
    nomeEmpresa() {
      if (this.getEmpresa != undefined) {
        return this.getEmpresa.pessoa ? this.getEmpresa.pessoa.nome : "";
      } else {
        return "";
      }
    },
    funcao() {
      if (this.user.funcionario) {
        return this.user.funcionario.funcao;
      } else {
        return "ADM";
      }
    },
    foto() {
      if (this.user.funcionario) {
        return this.user.funcionario.url_foto ||
          this.user.funcionario.pessoa.sexo == "F"
          ? "assets/media/users/blank.png"
          : "assets/media/users/blank.png";
      } else {
        return "assets/media/users/blank.png";
      }
    },
    empresas() {
      return this.user.empresasHabilitadas.map((x) => x.empresa);
    },
  },
  methods: {
    ...mapActions("auth", ["ActionSingnout", "ActionSetEmpresa"]),
    ...mapActions("usuario", ["ActionAlterPassword"]),
    sair() {
      this.ActionSingnout();
      this.$router.push("/login");
    },
    atualizaEmpresa(item) {
      this.ActionSetEmpresa({
        ...this.empresas.filter((f) => f.id_empresa == item)[0],
      });
    },
    AlterarSenha() {
      this.obj.id_usuario = this.user.id_usuario;
      this.obj.confirm = this.obj.confirmSenha;
      this.obj.ativo = this.user.ativo;

      if (this.obj.confirmSenha != this.obj.senha) {
        this.$swal.fire({
          icon: "warning",
          title: "Atenção",
          text: "Senha e Confirmar senha estão diferentes",
        });
        return;
      }
      this.ActionAlterPassword(this.obj).then((r) => {
        if (r.sucesso == true) {
          this.$swal.fire({
            icon: "success",
            title: "Sucesso",
            text: "Senha alterada com sucesso",
          });
          this.obj = {
            senha: "",
            confirmSenha: "",
            idUsuario: 0,
          };
        }
      });
    },
  },
};
</script>