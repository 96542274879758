import services from "@/http";
// import * as types from "./mutation-types";

export const ActionGetListPeriodicidade = () => {
    return services.periodicidade.all().then(r => {
        return r.data;
    });
};

// export const ActionGetListPeriodicidadeActive = () => {
//     return services.periodicidade.active().then(r => {
//         return r.data;
//     });
// };


export const ActionGetPeriodicidade = (context, payload) => {
    return services.periodicidade.get({ id: payload }).then(r => {
        return r.data;
    });
};

/*  export const ActionUpdatePeriodicidade = (context, payload) => {
     return services.periodicidade.update(payload).then(r => {
         return r.data;
     });
 };

 export const ActionCreatePeriodicidade = (context, payload) => {
     return services.periodicidade.create(payload).then(r => {
         return r.data;
     });
 };


export const ActionInactivePeriodicidade = (context, payload) => {
    return services.periodicidade.inactive({ id: payload }).then(r => {
        return r.data;
    });
}; */