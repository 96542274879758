<template>
  <div class="col-lg-12">
    <div class="row" style="margin-left: 40px; margin-top: -10px">
      <button
        class="btn btn-primary btn-hover-light"
        style="margin-right: 10px"
        v-if="dados.length > 0"
        :disabled="possiveis.length == 0"
        @click="GoAll()"
      >
        <i class="fa flaticon-add"></i> Todos
      </button>

      <button
        class="btn btn-info btn-hover-light"
        :disabled="checados.length == 0"
        v-if="dados.length > 0"
        @click="conciliaTodos()"
      >
        <i class="fa flaticon-list-3"></i> Conciliar Selecionados
      </button>
    </div>
    <br />

    <div class="row">
      <div :class="`d-flex flex-column-fluid col-lg-${tamanho}`">
        <!--begin::Container-->
        <div class="container">
          <div
            class="card card-custom gutter-b"
            v-for="(item, index) in todosXibanca"
            :key="index"
            style="height: 100px"
          >
            <!--begin::Header-->
            <!-- <div class="card-header border-0 py-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label font-weight-bolder text-dark">{{titulo}}</span>
            </h3>-->
            <!-- </div> -->
            <div class="card-body py-0">
              <br />
              <!--begin::Table-->
              <button
                style="margin-top: -15px"
                v-if="item.id_movimentacao == null"
                @click="novo(item)"
                data-toggle="modal"
                data-target="#modalNovo"
                class="btn btn-icon"
              >
                <span class="svg-icon svg-icon-primary">
                  <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg-->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <circle
                        fill="#000000"
                        opacity="0.3"
                        cx="12"
                        cy="12"
                        r="10"
                      />
                      <path
                        d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                        fill="#000000"
                      />
                    </g>
                  </svg>
                  <!--end::Svg Icon-->
                </span>
                <span style="font-size: 10px; margin-left: 2px">Novo</span>
              </button>

              <input
                type="checkbox"
                v-model="item.checked"
                v-if="item.id_movimentacao != null"
                style="width: 20px; height: 20px"
              />
              <br />
              <b>{{ item.data_lancamento }}</b>
              - {{ item.nome }}
              <br />
              <div
                :style="`color:${item.tipo == 'P' ? '#ff0000' : '#0000ff'};`"
              >
                <b>
                  {{
                    Number(item.valor).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })
                  }}
                </b>
              </div>
              <br />
            </div>
            <!--end::Table-->
          </div>
          <!--end::Body-->
        </div>
      </div>
      <div :class="`d-flex flex-column-fluid col-lg-${12 - tamanho}`">
        <!--begin::Container-->
        <div class="container">
          <div
            class="card card-custom gutter-b"
            v-for="(item, index) in todosXibanca"
            :key="index"
            style="height: 100px"
          >
            <div class="card-body py-0">
              <!--begin::Table-->
              <div v-if="item.id_movimentacao != null" style="margin-top: 30px">
                <b>{{ trazerDados(item).data_lancamento }}</b>
                - {{ trazerDados(item).nome }}
                <br />
                <b
                  :style="`color:${item.tipo == 'P' ? '#ff0000' : '#0000ff'};`"
                >
                  {{
                    Number(trazerDados(item).valor).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })
                  }}
                </b>
              </div>
              <br />

              <div style="float: right; margin-top: -60px; margin-right: 200px">
                <button
                  style="margin-right: 10px"
                  v-if="item.id_movimentacao != null"
                  @click="conciliarUm(item)"
                  class="btn btn-icon"
                >
                  <span class="svg-icon svg-icon-primary">
                    <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Done-circle.svg-->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24" />
                        <circle
                          fill="#000000"
                          opacity="0.3"
                          cx="12"
                          cy="12"
                          r="10"
                        />
                        <path
                          d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                        />
                      </g>
                    </svg>
                    <!--end::Svg Icon-->
                  </span>
                  <span style="margin-left: 5px">Conciliar</span>
                </button>
              </div>

              <div style="float: right; margin-top: -60px">
                <button
                  style="margin-right: 70px"
                  v-if="item.id_movimentacao != null"
                  @click="desconciliar(item)"
                  class="btn btn-icon"
                >
                  <span class="svg-icon svg-icon-primary">
                    <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Error-circle.svg-->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24" />
                        <circle
                          fill="#000000"
                          opacity="0.3"
                          cx="12"
                          cy="12"
                          r="10"
                        />
                        <path
                          d="M12.0355339,10.6213203 L14.863961,7.79289322 C15.2544853,7.40236893 15.8876503,7.40236893 16.2781746,7.79289322 C16.6686989,8.18341751 16.6686989,8.81658249 16.2781746,9.20710678 L13.4497475,12.0355339 L16.2781746,14.863961 C16.6686989,15.2544853 16.6686989,15.8876503 16.2781746,16.2781746 C15.8876503,16.6686989 15.2544853,16.6686989 14.863961,16.2781746 L12.0355339,13.4497475 L9.20710678,16.2781746 C8.81658249,16.6686989 8.18341751,16.6686989 7.79289322,16.2781746 C7.40236893,15.8876503 7.40236893,15.2544853 7.79289322,14.863961 L10.6213203,12.0355339 L7.79289322,9.20710678 C7.40236893,8.81658249 7.40236893,8.18341751 7.79289322,7.79289322 C8.18341751,7.40236893 8.81658249,7.40236893 9.20710678,7.79289322 L12.0355339,10.6213203 Z"
                          fill="#000000"
                        />
                      </g>
                    </svg>
                    <!--end::Svg Icon-->
                  </span>
                  <span style="margin-left: 5px">Desvincular</span>
                </button>
              </div>
              <div
                v-if="
                  item.id_movimentacao == null &&
                  dadosRelacionados(item).length > 1
                "
              >
                <div class="row">
                  <div class="col-lg-6">
                    <select-populado
                      v-if="VerfificarTodo(item, false)"
                      :list="dadosRelacionados(item)"
                      :data="item.id_movimentacao"
                      :fieldKey="'value'"
                      :fieldText="'label'"
                      @onChange="AlterarId(item, $event)"
                    />
                    <select-populado
                      v-if="VerfificarTodo(item, true)"
                      :list="dadosSelecionaveis(item)"
                      :data="item.id_movimentacao"
                      :fieldKey="'value'"
                      :fieldText="'label'"
                      @onChange="AlterarId(item, $event)"
                    />
                  </div>
                  <div class="col-lg-4">
                    <button
                      @click="habilita(item, true)"
                      :class="`btn btn-${
                        item.tipo == 'P' ? 'danger' : 'primary'
                      } btn-hover-light`"
                    >
                      <i class="fa fa-search"></i> Sugestão
                    </button>
                    <button
                      v-if="VerfificarTodo(item, true)"
                      @click="habilita(item, false)"
                      class="btn btn-light btn-hover-danger btn-lg"
                    >
                      <i class="fa fa-ban"></i> Limpar
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  item.id_movimentacao == null &&
                  dadosRelacionados(item).length == 0
                "
              >
                <div class="row">
                  <div
                    class="col-lg-6"
                    v-if="VerfificarTodo(item, true) == true"
                    style="margin-top: 10px"
                  >
                    <select-populado
                      :list="dadosSelecionaveis(item)"
                      :data="item.id_movimentacao"
                      :fieldKey="'value'"
                      :fieldText="'label'"
                      @onChange="AlterarId(item, $event)"
                    />
                  </div>
                  <div class="col-lg-4" style="margin-top: 10px">
                    <button
                      @click="habilita(item, true) == true"
                      :class="`btn btn-${
                        item.tipo == 'P' ? 'danger' : 'primary'
                      } btn-hover-light`"
                    >
                      <i class="fa fa-search"></i> Sugestão
                    </button>
                    <button
                      v-if="VerfificarTodo(item, true) == true"
                      @click="habilita(item, false)"
                      class="btn btn-light btn-hover-danger"
                    >
                      <i class="fa fa-ban"></i> Limpar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Body-->
        </div>
      </div>
      <!--end::Container-->
    </div>

    <div
      class="modal fade bd-example-modal-xl"
      id="modalNovo"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myExtraLargeModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-xl modal-dialog-scrollable"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Nova Conta</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i aria-hidden="true" class="ki ki-close"></i>
            </button>
          </div>

          <div class="modal-body" style="height: 600px">
            <div style="margin-top: -30px; width: 1120px; margin-left: -50px">
              <ContaPagarManter
                v-if="xibanca.tipo == 'P'"
                :conciliado="xibanca"
                @onNovo="retornouNovo()"
              />
              <ContaReceberManter
                v-if="xibanca.tipo == 'R'"
                :conciliado="xibanca"
                @onNovo="retornouNovo()"
              />
            </div>
          </div>

          <!-- <div class="modal-footer">
            <button
              type="button"
              class="btn btn-light-primary font-weight-bold"
              data-dismiss="modal"
            >Fechar</button>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SelectPopulado from "../../../components/forms/SelectPopulado";
import ContaPagarManter from "../../Conta/ContaPagar/ContaPagarManter";
import ContaReceberManter from "../../Conta/ContaReceber/ContaReceberManter";
export default {
  name: "Card",
  props: {
    tamanho: Number,
    titulo: String,
    isImport: {
      type: Boolean,
      default: false,
    },
    isDate: {
      type: Boolean,
      default: false,
    },
    dados: { type: Array, default: new Array() },
    dataInicio: String,
    dataFim: String,
    dadosSistema: {
      type: Array,
      default: new Array(),
    },
    dadosSelect: {
      type: Array,
      default: new Array(),
    },
  },
  data: () => ({
    // dados: [],
    itemNovo: {},
  }),
  //   computed: {
  //     ...mapState("auth", ["empresa"]),
  //   },
  mounted() {
    // if (this.isImport == true) {
    //   initUppy5();
    // }
    this.dados.forEach((el, index) => {
      el.todos = false;
      el.index = index;
    });
  },
  computed: {
    checados() {
      return this.dados.filter(
        (f) => f.id_movimentacao != undefined && f.checked == true
      );
    },
    possiveis() {
      return this.dados.filter((f) => f.id_movimentacao != undefined);
    },
    xibanca() {
      return this.itemNovo;
    },
    todosXibanca() {
      return this.dados;
    },
  },
  components: {
    SelectPopulado,
    ContaPagarManter,
    ContaReceberManter,
  },
  methods: {
    retornouNovo() {
      window.$("#modalNovo").modal("hide");
      let x = JSON.parse(JSON.stringify(this.xibanca));
      this.$emit("onNovo", x);
      this.itemNovo = {};
    },
    GoAll() {
      let checked = !this.checkAll;
      this.$emit("onCheckAll", checked);
      this.checkAll = checked;
    },
    dadosSelecionaveis(item) {
      return this.dadosSistema
        .filter((f) => f.tipo == item.tipo)
        .map((x) => {
          return {
            label: `${x.id_movimentacao} - ${x.nome} - ${
              x.data_lancamento
            } : ${Number(x.valor).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })} `,
            value: x.id_movimentacao,
          };
        });
    },
    VerfificarTodo(item, value) {
      return this.dados.filter((x) => x.index == item.index)[0].todos == value;
    },
    habilita(item, value) {
      item.todos = value;
      this.dados.filter((x) => x.index == item.index)[0] = item;
    },
    novo(item) {
      this.itemNovo = {
        tipo: item.tipo,
        index: item.index,
        data_lancamento: item.data_lancamento_apoio,
        valor: item.tipo == "P" ? item.valor * -1 : item.valor,
        nome: item.nome,
        codigo_efetivacao: item.codigo,
      };
    },
    dadosRelacionados(item) {
      return this.dadosSistema
        .filter(
          (f) =>
            f.valor == item.valor &&
            this.dados.filter((x) => x.id_movimentacao == f.id_movimentacao)
              .length == 0
        )
        .map((x) => {
          return {
            label: `${x.id_movimentacao} - ${x.nome} - ${
              x.data_lancamento
            } : ${Number(x.valor).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })} `,
            value: x.id_movimentacao,
          };
        });
    },
    trazerDados(item) {
      return this.dadosSistema.find(
        (f) => f.id_movimentacao == item.id_movimentacao
      );
    },
    // importar() {
    //   // this.$emit("onDadosImportados");
    // },
    AlterarId(item, dado) {
      let dada = this.dadosSistema.filter((f) => f.id_movimentacao == dado)[0];

      if (dada.tipo == "P") {
        let valorItem = item.valor * -1;
        let valorSistema = dada.valor * -1;
        if (valorItem > valorSistema) {
          item.juros = valorItem - valorSistema;
          item.descontos = 0;
        } else if (valorItem < valorSistema) {
          item.descontos = valorSistema - valorItem;
          item.juros = 0;
        } else {
          item.descontos = 0;
          item.juros = 0;
        }
      } else {
        if (item.valor > dada.valor) {
          item.juros = item.valor - dada.valor;
          item.descontos = 0;
        } else if (item.valor < dada.valor) {
          item.descontos = dada.valor - item.valor;
          item.juros = 0;
        } else {
          item.descontos = 0;
          item.juros = 0;
        }
      }
      item.id_movimentacao = dado;
    },
    desconciliar(item) {
      this.$swal
        .fire({
          icon: "question",
          title: "Atenção",
          text: "Deseja realmente desvincular este lançamento?",
          showCloseButton: true,
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: "Não",
          confirmButtonText: "Sim",
        })
        .then((r) => {
          if (r.value) {
            this.dados.filter(
              (f) => f.index == item.index
            )[0].id_movimentacao = null;
            this.dados.filter((f) => f.index == item.index)[0].todos = false;
            // item.id_movimentacao = null;
          }
        });
    },
    conciliarUm(item) {
      this.$swal
        .fire({
          icon: "question",
          title: "Atenção",
          text: "Deseja realmente conciliar este lançamento?",
          showCloseButton: true,
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: "Não",
          confirmButtonText: "Sim",
        })
        .then((r) => {
          if (r.value) {
            this.$emit("onConciliarPontual", item);
          }
        });
    },
    conciliaTodos() {
      this.$swal
        .fire({
          icon: "question",
          title: "Atenção",
          text: "Deseja realmente conciliar estes lançamentos?",
          showCloseButton: true,
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: "Não",
          confirmButtonText: "Sim",
        })
        .then((r) => {
          if (r.value) {
            let dadosConciliar = this.dados.filter(
              (f) => f.id_movimentacao != undefined && f.checked == true
            );
            this.$emit("onConciliarAll", dadosConciliar);
          }
        });
    },
  },
  watch: {
    dados(nValue) {
      this.dados = nValue;
    },
    dadosSelect(nValue) {
      console.log(nValue);
      this.dadosSelect = nValue;
    },
    dadosSistema(nValue) {
      console.log(nValue);
      this.dadosSistema = nValue;
    },
  },
};
</script>