<template>
  <FomularioComum
    :titulo="titulo"
    :buttons="buttons"
    :nome="'Tipo de Recebimento'"
    :disable="false"
    :opcao="opcao"
    :data="dado"
    @onSave="manter"
    @onCancel="this.$router.push('/tipo-recebimento')"
  />
</template>
<script>
import FomularioComum from "../../components/forms/FomularioComum";
import { mapActions } from "vuex";
export default {
  name: "Tipo de Recebimento",
  data: () => ({
    buttons: [
      {
        link: "/tipo-recebimento/",
        titulo: "ver todos",
        color: "btn-light-primary",
      },
    ],
    usuario: {},
    dado: {
      id: 0,
      nome: "",
      ativo: true,
    },
    tipoRecebimento: {
      id_tipo_recebimento: 0,
      descricao: "",
      ativo: true,
    },
  }),
  computed: {
    opcao() {
      let id = this.$route.params.id;
      if (id == "0") {
        return "A";
      } else {
        return "E";
      }
    },
    titulo() {
      let id = this.$route.params.id;
      if (id == "0") {
        return "Adicionando Tipo de Recebimento";
      } else {
        return "Editando Tipo de Recebimento";
      }
    },
  },
  mounted() {
    let id = this.$route.params.id;
    if (id != "0") {
      this.ActionGetTipoRecebimento(id).then((r) => {
        this.tipoRecebimento = r;
        this.dado.id = this.tipoRecebimento.id_tipo_recebimento;
        this.dado.nome = this.tipoRecebimento.descricao;
        this.dado.ativo = this.tipoRecebimento.ativo;
      });
    }
  },
  methods: {
    ...mapActions("tipoRecebimento", [
      "ActionGetTipoRecebimento",
      "ActionCreateTipoRecebimento",
      "ActionUpdateTipoRecebimento",
    ]),
    async manter(dado) {
      try {
        console.log(dado);
        this.dado = dado;
        let r;
        this.tipoRecebimento.id_tipo_recebimento = this.dado.id;
        this.tipoRecebimento.descricao = this.dado.nome;
        this.tipoRecebimento.ativo = this.dado.ativo;

        if (this.opcao == "A") {
          r = await this.ActionCreateTipoRecebimento(this.tipoRecebimento);
        } else {
          r = await this.ActionUpdateTipoRecebimento(this.tipoRecebimento);
        }
        if (r.sucesso == true) {
          this.$swal
            .fire({
              icon: r.sucesso == true ? "success" : "error",
              title: r.sucesso == true ? `Sucesso` : "Atenção / Erro",
              text:
                r.sucesso == true
                  ? `Tipo de Recebimento ${
                      this.opcao == "A" ? "adicionado" : "atualizado"
                    } com sucesso`
                  : r.error.message,
            })
            .then(() => {
              this.$router.push("/tipo-recebimento");
            });
        }
      } catch (error) {
        console.log(error);
        if (error.data) {
          console.log(error.data);
          this.$swal.fire({
            icon: "error",
            title: error.data.error.title || "Oops...",
            text: error.data.error.message,
          });
        } else {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Ocorreu um erro inesperado, favor contactar o suporte.",
          });
        }
      }
    },
    voltar() {
      this.$router.push("/tipo-recebimento");
    },
  },
  components: {
    FomularioComum,
  },
};
</script>