import services from "@/http";

export const ActionGetFuncionalidades = () => {
    return services.perfilAcesso.funcionalidades().then(r => {
        return r.data;
    });
};

export const ActionPostFuncionalidadeUsuario = (context, payload) => {
    return services.perfilAcesso.gravar(payload).then(r => {
        return r.data;
    });
};
