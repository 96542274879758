<template>
  <!--begin::Content-->
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Subheader-->
    <sub-header :titulo="titulo" :buttons="buttons"></sub-header>
    <!--end::Subheader-->
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Dashboard-->
        <!--begin::Row-->

        <Listagem
          :dados="dados"
          :colunas="colunaPadrao"
          :colunasDetalhes="colunas"
          :monta="monta"
          :canVer="false"
          :canEditar="false"
          :exportacao="exportarLista"
          :canBtnExport="false"
          :canInativar="false"
          :canBtnAtivo="false"
        />

        <!-- Button trigger modal-->
        <!-- <button
          type="button"
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModal"
        >Visualizar Funcionário</button>-->

        <!-- Modal-->
        <div
          class="modal fade bd-example-modal-xl"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myExtraLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Visualizando categoria</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <i aria-hidden="true" class="ki ki-close"></i>
                </button>
              </div>
              <div class="modal-body" style="height: 500px;">...</div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-light-primary font-weight-bold"
                  data-dismiss="modal"
                >Fechar</button>
              </div>
            </div>
          </div>
        </div>

        <!--end::Row-->
        <!--end::Dashboard-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
  <!--end::Content-->
</template>

<script>
import Listagem from "../../components/forms/Listagem.vue";
import SubHeader from "../../components/layout/SubHeader";
import { mapActions } from "vuex";
export default {
  components: {
    Listagem,
    SubHeader,
  },
  data: () => ({
    export: [
      {
        descricaoPai: "Periodicidade",
        criaSheet: true,
        campos: [
          {
            header: "ID",
            field: "id_periodicidade",
            checked: false,
          },
          {
            header: "Nome",
            field: "descricao",
            checked: false,
          },
        ],
      },
    ],
    titulo: "Periodicidade",
    colunaPadrao: [
      {
        label: "ID",
        field: "id_periodicidade",
      },

      {
        label: "Descrição",
        field: "descricao",
      },
    ],
    colunas: [
      {
        nome: "ID",
        index: "id_periodicidade",
        isHtml: false,
      },

      {
        nome: "Descrição",
        index: "descricao",
        isHtml: false,
      },
    ],
    dados: [],
    monta: false,
  }),
  computed: {
    exportarLista: {
      get() {
        return this.export;
      },
      set(nValue) {
        this.export = nValue;
      },
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions("periodicidade", [
      "ActionGetListPeriodicidade",
      "ActionInactivePeriodicidade",
    ]),
    load() {
      window.KTApp.block("#kt_blockui_content", {
        overlayColor: "#000000",
        state: "primary",
        message: "Processando...",
      });
      this.ActionGetListPeriodicidade().then((r) => {
        this.dados = r;
        this.monta = true;
        window.KTApp.unblock("#kt_blockui_content");
      });
    },
  },
};
</script>