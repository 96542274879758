import Vue from "vue";
import VueResource from "vue-resource";
import services from "./services";
import interceptors from "./interceptors";

Vue.use(VueResource);

const http = Vue.http;
http.options.root = process.env.VUE_APP_URL;
http.interceptors.push(interceptors);

Object.keys(services).map(s => {
    services[s] = Vue.resource('', {}, services[s]);
});

const setBearerToken = token => {
    http.headers.common['Authorization'] = `Bearer ${token}`;
};
export default services;
export { http, setBearerToken };

