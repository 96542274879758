<template>
  <FomularioUsuario
    v-disable-all="user.leitura == true"
    :titulo="titulo"
    :buttons="buttons"
    :nome="'Usuário'"
    :disable="false"
    :opcao="opcao"
    :data="usuario"
    :usuarios="usuarios"
    @onSave="manter"
    @onCancel="voltar"
  />
</template>
<script>
import FomularioUsuario from "../../components/forms/FomularioUsuario";
import { mapActions, mapState } from "vuex";
export default {
  name: "Usuário",
  data: () => ({
    buttons: [
      {
        link: "/usuario/",
        titulo: "ver todos",
        color: "btn-light-primary",
      },
    ],
    dado: {
      id_funcionario: 0,
      login: "",
      senha: "",
      confirm: "",
      ativo: true,
      leitura: false,
    },
    usr: {
      id_funcionario: 0,
      login: "",
      senha: "",
      confirm: "",
      ativo: true,
      leitura: false,
    },
    usuarios: [],
  }),
  computed: {
    ...mapState("auth", ["user"]),
    usuario: {
      get() {
        return this.usr;
      },
      set(nValue) {
        this.usr = nValue;
      },
    },
    opcao() {
      let id = this.$route.params.id;
      if (id == 0) {
        return "A";
      } else {
        return "E";
      }
    },
    titulo() {
      let id = this.$route.params.id;
      if (id == "0") {
        return "Adicionando Usuário";
      } else {
        return "Editando Usuário";
      }
    },
  },
  mounted() {
    let id = this.$route.params.id;
    if (id != "0") {
      window.KTApp.blockPage({
        overlayColor: "#000000",
        state: "primary",
        message: "Carregando...",
      });
      this.ActionGetUsuario(id).then((r) => {
        this.usuario = r;
        this.usuario.senha = "";
      });
      window.KTApp.unblockPage();
    }

    this.ActionGetListUsuarios().then((r) => {
      this.usuarios = r;
    });
  },
  methods: {
    ...mapActions("usuario", [
      "ActionGetUsuario",
      "ActionCreateUser",
      "ActionAlterPassword",
      "ActionGetListUsuarios",
      "ActionAlterUserCompleto",
    ]),
    manter(dado) {
      try {
        console.log(dado);
        this.dado = dado;
        let r;
        this.usuario = this.dado;
        let id = this.$route.params.id;
        if (id == 0) {
          r = this.ActionCreateUser(this.usuario);
        } else {
          r = this.ActionAlterUserCompleto(this.usuario);
        }
        window.KTApp.blockPage({
          overlayColor: "#000000",
          state: "primary",
          message: "Carregando...",
        });
        r.then((x) => {
          window.KTApp.unblockPage();
          if (x.sucesso == true) {
            this.$swal
              .fire({
                icon: x.sucesso == true ? "success" : "error",
                title: x.sucesso == true ? `Sucesso` : "Atenção / Erro",
                text:
                  x.sucesso == true
                    ? this.opcao == "E"
                      ? "Usuário atualizado com sucesso"
                      : "Usuário adicionado com sucesso"
                    : x.error.message,
              })
              .then(() => {
                this.$router.push("/usuario");
              });
          }
        });
      } catch (error) {
        console.log(error);
        if (error.data) {
          console.log(error.data);
          this.$swal.fire({
            icon: "error",
            title: error.data.error.title || "Oops...",
            text: error.data.error.message,
          });
        } else {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Ocorreu um erro inesperado, favor contactar o suporte.",
          });
        }
      }
    },
    voltar() {
      this.$router.push("/usuario");
    },
  },
  components: {
    FomularioUsuario,
  },
};
</script>