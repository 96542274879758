<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Subheader-->
    <sub-header :titulo="titulo" :buttons="[]"></sub-header>
    <!--end::Subheader-->
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Dashboard-->
        <!--begin::Row-->
        <div
          class="alert alert-custom alert-white alert-shadow fade show gutter-b"
          role="alert"
        >
          <div class="alert-icon">
            <span class="svg-icon svg-icon-primary svg-icon-2x">
              <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Search.svg-->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <rect x="0" y="0" width="24" height="24" />
                  <path
                    d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                    fill="#000000"
                    fill-rule="nonzero"
                    opacity="0.3"
                  />
                  <path
                    d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                    fill="#000000"
                    fill-rule="nonzero"
                  />
                </g>
              </svg>
              <!--end::Svg Icon-->
            </span>
          </div>
          <div class="alert-text">
            <div
              class="input-daterange input-group col-lg-6"
              id="kt_datepicker_5"
            >
              <input
                type="date"
                v-model="dataInicio"
                class="form-control"
                name="start"
              />
              <div class="input-group-append">
                <span class="input-group-text">
                  <i class="la la-ellipsis-h"></i>
                </span>
              </div>
              <input
                type="date"
                v-model="dataFim"
                class="form-control"
                name="end"
              />
            </div>
          </div>
          <router-link
            v-if="user.leitura != true"
            to="/conciliacao"
            class="btn btn-outline-primary btn-sm"
          >
            <i class="fa fa-cog icon-lg"></i>
            <span class="nav-text">CONCILIAÇÃO BANCÁRIA</span>
          </router-link>
        </div>

        <div class="card card-custom">
          <div class="row" style="margin-top: 20px">
            <div class="col-lg-6 col-xxl-5">
              <!--begin::List Widget 9-->
              <div class="card card-custom card-stretch gutter-b">
                <!--begin::Header-->
                <div class="card-header align-items-center border-0 mt-4">
                  <h3 class="card-title align-items-start flex-column">
                    <span class="font-weight-bolder text-dark">
                      <h3 class="card-label">
                        <img
                          src="assets/media/svg/icons/Shopping/Calculator.svg"
                        />
                        Extrato do dia
                      </h3>
                    </span>
                    <span class="text-muted mt-3 font-weight-bold font-size-sm">
                      <i class="fa fa-genderless text-warning"></i> RC
                      (Recebimento conciliado)
                      <i class="fa fa-genderless text-info"></i> RN (Recebimento
                      não conciliado)
                      <br />
                      <i class="fa fa-genderless text-success"></i> PC
                      (Pagamento conciliado)
                      <i class="fa fa-genderless text-danger"></i> PN (Pagamento
                      não conciliado)
                    </span>
                    <br />

                    <span class="text-muted mt-3 font-weight-bold font-size-sm">
                      Saldo:
                      {{
                        saldoDia.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })
                      }}
                    </span>
                  </h3>
                </div>

                <xlsx-workbook>
                  <xlsx-sheet
                    :collection="sheet.data"
                    v-for="sheet in sheetsDia"
                    :key="sheet.name"
                    :sheet-name="sheet.name"
                  />
                  <xlsx-download :filename="nomeArqDia">
                    <div style="float: right; margin: 20px; margin-top: -30px">
                      <button type="button" class="btn btn-outline-info btn-sm">
                        <i class="fa fa-cog icon-lg"></i>
                        <span class="nav-text">EXPORTAR</span>
                      </button>
                    </div>
                  </xlsx-download>
                </xlsx-workbook>

                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-4">
                  <div class="timeline timeline-5 mt-3">
                    <!--begin::Item-->
                    <div
                      v-for="(item, index) in dadosDia"
                      :key="index"
                      class="timeline-item align-items-start"
                    >
                      <!--begin::Label-->
                      <div
                        class="timeline-label font-weight-bolder text-dark-75 font-size-lg"
                      ></div>
                      <!--end::Label-->
                      <!--begin::Badge-->
                      <div class="timeline-badge">
                        <i
                          :class="`fa fa-genderless text-${
                            item.tipo == 'R' && item.efetivado == true
                              ? 'warning'
                              : item.tipo == 'R'
                              ? 'info'
                              : item.efetivado == true
                              ? 'success'
                              : 'danger'
                          } icon-xl`"
                        ></i>
                      </div>
                      <!--end::Badge-->
                      <!--begin::Text-->
                      <div class="timeline-content d-flex">
                        <span
                          class="font-weight-bolder text-dark-75 pl-3 font-size-lg"
                        >
                          {{ `${item.data_lancamento} - ${item.nome}` }}
                          <br />
                          <b
                            :class="`text-${
                              item.tipo == 'R' && item.efetivado == true
                                ? 'warning'
                                : item.tipo == 'R'
                                ? 'info'
                                : item.efetivado == true
                                ? 'success'
                                : 'danger'
                            }`"
                            >{{
                              item.valor.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })
                            }}</b
                          >.
                        </span>
                      </div>
                      <!--end::Text-->
                    </div>
                    <!--end::Item-->
                  </div>
                  <!--end: Items-->
                </div>
                <!--end: Card Body-->
              </div>
              <!--end: Card-->
              <!--end: List Widget 9-->
            </div>

            <div class="col-xxl-7 order-2 order-xxl-1">
              <!--begin::Advance Table Widget 2-->
              <div class="card card-custom card-stretch gutter-b">
                <!--begin::Header-->
                <div class="card-header align-items-center border-0 mt-4">
                  <h3 class="card-title align-items-start flex-column">
                    <span class="font-weight-bolder text-dark">
                      <h3 class="card-label">
                        <img
                          src="assets/media/svg/icons/Shopping/Calculator.svg"
                        />
                        Extrato - {{ dataInicioFormat }} à {{ dataFimFormat }}
                      </h3>
                    </span>
                    <span class="text-muted mt-3 font-weight-bold font-size-sm">
                      <i class="fa fa-genderless text-warning"></i> RC
                      (Recebimento conciliado)
                      <i class="fa fa-genderless text-info"></i> RN (Recebimento
                      não conciliado)
                      <br />
                      <i class="fa fa-genderless text-success"></i> PC
                      (Pagamento conciliado)
                      <i class="fa fa-genderless text-danger"></i> PN (Pagamento
                      não conciliado)
                    </span>
                    <br />
                    <span class="text-muted mt-3 font-weight-bold font-size-sm">
                      Saldo:
                      {{
                        saldoPeriod.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })
                      }}
                    </span>
                  </h3>
                  <xlsx-workbook>
                    <xlsx-sheet
                      :collection="sheet.data"
                      v-for="sheet in sheetsPeriod"
                      :key="sheet.name"
                      :sheet-name="sheet.name"
                    />
                    <xlsx-download :filename="nomeArqPeriod">
                      <button
                        type="button"
                        class="btn btn-outline-info btn-sm"
                        style="margin-top: -20px"
                      >
                        <i class="fa fa-cog icon-lg"></i>
                        <span class="nav-text">EXPORTAR</span>
                      </button>
                    </xlsx-download>
                  </xlsx-workbook>
                </div>

                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-4">
                  <div class="timeline timeline-5 mt-3">
                    <!--begin::Item-->
                    <div
                      v-for="(item, index) in dadosPeriod"
                      :key="index"
                      class="timeline-item align-items-start"
                    >
                      <!--begin::Label-->
                      <div
                        class="timeline-label font-weight-bolder text-dark-75 font-size-lg"
                      ></div>
                      <!--end::Label-->
                      <!--begin::Badge-->
                      <div class="timeline-badge">
                        <i
                          :class="`fa fa-genderless text-${
                            item.tipo == 'R' && item.efetivado == true
                              ? 'warning'
                              : item.tipo == 'R'
                              ? 'info'
                              : item.efetivado == true
                              ? 'success'
                              : 'danger'
                          } icon-xl`"
                        ></i>
                      </div>
                      <!--end::Badge-->
                      <!--begin::Text-->
                      <div class="timeline-content d-flex">
                        <span
                          class="font-weight-bolder text-dark-75 pl-3 font-size-lg"
                        >
                          {{ `${item.data_lancamento} - ${item.nome} ` }}
                          <br />
                          <b
                            :class="`text-${
                              item.tipo == 'R' && item.efetivado == true
                                ? 'warning'
                                : item.tipo == 'R'
                                ? 'info'
                                : item.efetivado == true
                                ? 'success'
                                : 'danger'
                            }`"
                            >{{
                              item.valor.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })
                            }}</b
                          >.
                        </span>
                      </div>
                      <!--end::Text-->
                    </div>
                    <!--end::Item-->
                    <!--begin::Item-->
                    <!--end::Item-->
                  </div>
                  <!--end: Items-->
                </div>
                <!--end: Card Body-->
              </div>
              <!--end::Advance Table Widget 2-->
            </div>
          </div>
          <!--end::Row-->
          <!--begin::Row-->
        </div>

        <!-- Button trigger modal-->

        <!--end::Row-->
        <!--end::Dashboard-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
</template>

<script>
import SubHeader from "../../components/layout/SubHeader";
import { mapState, mapActions } from "vuex";
import {
  XlsxDownload,
  XlsxWorkbook,
  XlsxSheet,
} from "vue-xlsx/dist/vue-xlsx.es.js";
export default {
  data: () => ({
    titulo: "Extrato",
    dadosDia: [],
    dadosPeriod: [],
    dataInicio: null,
    dataFim: null,
    dataHoje: null,
  }),
  computed: {
    ...mapState("auth", ["empresa", "user"]),
    dataInicioFormat() {
      return this.$moment(this.dataInicio).format("DD/MM/YYYY");
    },
    dataFimFormat() {
      return this.$moment(this.dataFim).format("DD/MM/YYYY");
    },
    saldoDia() {
      return this.dadosDia.length == 0
        ? 0
        : this.dadosDia
            .map((x) => x.valor)
            .reduce((acc, value) => acc + value, 0);
    },
    saldoPeriod() {
      return this.dadosPeriod.length == 0
        ? 0
        : this.dadosPeriod
            .map((x) => x.valor)
            .reduce((acc, value) => acc + value, 0);
    },
    nomeArqPeriod() {
      return `${process.env.VUE_APP_NOME}_Extrato_${this.dataInicio}_${
        this.dataFim
      }_${this.dataFim}_${this.$moment(new Date()).format(
        "DD_MM_YYYY_HHmmss"
      )}.xlsx`;
    },
    nomeArqDia() {
      return `${process.env.VUE_APP_NOME}_Extrato do Dia_${
        this.dataHoje
      }_${this.$moment(new Date()).format("DD_MM_YYYY_HHmmss")}.xlsx`;
    },
    sheetsDia() {
      let arrays = [];
      let obj = { name: "Extrato do Dia", data: [] };
      obj.data = this.dadosDia.map((x) => {
        return {
          Data: x.data_lancamento,
          Nome: x.nome,
          Tipo:
            x.tipo == "R" && x.efetivado == true
              ? "Recebido conciliado"
              : x.tipo == "R"
              ? "Recebido não conciliado"
              : x.efetivado == true
              ? "Pago conciliado"
              : "Pago não conciliado",
          Valor: x.valor,
        };
      });
      arrays.push(obj);
      return arrays;
    },
    sheetsPeriod() {
      let arrays = [];
      let obj = { name: "Extrato do Dia", data: [] };
      obj.data = this.dadosPeriod.map((x) => {
        return {
          Data: x.data_lancamento,
          Nome: x.nome,
          Tipo:
            x.tipo == "R" && x.efetivado == true
              ? "Recebido conciliado"
              : x.tipo == "R"
              ? "Recebido não conciliado"
              : x.efetivado == true
              ? "Pago conciliado"
              : "Pago não conciliado",
          Valor: x.valor,
        };
      });
      arrays.push(obj);
      return arrays;
    },
  },
  mounted() {
    let dataHoje = new Date();
    // data = new Date(data.getFullYear(), data.getMonth(), 1);
    let data = new Date();
    data = data.setDate(1);
    this.dataInicio = this.$moment(data).format("YYYY-MM-DD");
    this.dataFim = this.$moment(new Date().setDate(+31)).format("YYYY-MM-DD");
    // let data2 = new Date(data.getFullYear(), data.getMonth() + 1, 1);
    // data2 = data2.setDate(data2.getDate() - 1);
    // this.dataInicio = this.$moment().format("YYYY-MM-DD");
    this.dataHoje = this.$moment(dataHoje).format("YYYY-MM-DD");
    // this.dataFim = this.$moment(data2).format("YYYY-MM-DD");
    this.loadDia();
    this.loadPeriod();
  },
  methods: {
    ...mapActions("extrato", ["ActionGetExtrato"]),
    loadDia() {
      this.ActionGetExtrato({
        idEmpresa: this.empresa.id_empresa,
        dataInicio: this.dataHoje,
        dataFim: this.dataHoje,
      }).then((r) => {
        this.dadosDia = r;
      });
    },
    loadPeriod() {
      this.ActionGetExtrato({
        idEmpresa: this.empresa.id_empresa,
        dataInicio: this.dataInicio,
        dataFim: this.dataFim,
      }).then((r) => {
        this.dadosPeriod = r;
      });
    },
  },
  components: {
    SubHeader,
    XlsxDownload,
    XlsxWorkbook,
    XlsxSheet,
  },
  watch: {
    empresa() {
      this.loadPeriod();
    },
    dataInicio(nValue) {
      this.dataInicio = nValue;
      this.loadPeriod();
    },
    dataFim(nValue) {
      this.dataFim = nValue;
      this.loadPeriod();
    },
  },
};
</script>