import services from "@/http";
// import * as types from "./mutation-types";

export const ActionGetListContaPagar = () => {
    return services.contaPagar.all().then(r => {
        return r.data;
    });
};

export const ActionGetListContaPagarActive = () => {
    return services.contaPagar.active().then(r => {
        return r.data;
    });
};


export const ActionGetContaPagar = (context, payload) => {
    return services.contaPagar.get({ id: payload }).then(r => {
        return r.data;
    });
};

export const ActionUpdateContaPagar = (context, payload) => {
    return services.contaPagar.update(payload).then(r => {
        return r.data;
    });
};

export const ActionCreateContaPagar = (context, payload) => {
    return services.contaPagar.create(payload).then(r => {
        return r.data;
    });
};

export const ActionCreateConciliadoContaPagar = (context, payload) => {
    return services.contaPagar.createConciliado(payload).then(r => {
        return r.data;
    });
};

export const ActionInactiveContaPagar = (context, payload) => {
    return services.contaPagar.inactive({ id: payload.id_conta, idMov: payload.id_movimentacao }).then(r => {
        return r.data;
    });
};