import { setBearerToken } from "@/http";
// TOKEN
export const setHeaderToken = token => setBearerToken(token);
export const getLocalToken = () => localStorage.getItem('token');
export const deleteLocalToken = () => localStorage.removeItem('token');
export const setLocalToken = token => localStorage.setItem('token', token);


// USER
export const getLocalUsuario = () => JSON.parse(localStorage.getItem('usuario'));
export const deleteLocalUsuario = () => localStorage.removeItem('usuario');
export const setLocalUsuario = usuario => localStorage.setItem('usuario', JSON.stringify(usuario));



export const setLocalEmpresa = empresa => localStorage.setItem('empresa', JSON.stringify(empresa));
export const getLocalEmpresa = () => JSON.parse(localStorage.getItem('empresa'));