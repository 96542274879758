<template>
  <!--begin::Content-->
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Subheader-->
    <sub-header :titulo="titulo" :buttons="buttons"></sub-header>
    <!--end::Subheader-->
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Dashboard-->
        <!--begin::Row-->

        <Listagem
          :dados="dados"
          :colunas="colunaPadrao"
          :colunasDetalhes="colunas"
          :monta="monta"
          :canVer="false"
          :canInativar="user.leitura != true"
          :exportacao="exportarLista"
          @onVisualizar="visualizar"
          @onDelete="inativar"
          @onEditar="editar"
        />

        <!-- Button trigger modal-->
        <!-- <button
          type="button"
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModal"
        >Visualizar Funcionário</button>-->

        <!-- Modal-->
        <div
          class="modal fade bd-example-modal-xl"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myExtraLargeModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-xl modal-dialog-scrollable"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Visualizando Forma de Pagamento
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i aria-hidden="true" class="ki ki-close"></i>
                </button>
              </div>
              <div class="modal-body" style="height: 500px">...</div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-light-primary font-weight-bold"
                  data-dismiss="modal"
                >
                  Fechar
                </button>
              </div>
            </div>
          </div>
        </div>

        <!--end::Row-->
        <!--end::Dashboard-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
  <!--end::Content-->
</template>
<script>
import Listagem from "../../components/forms/Listagem.vue";
import SubHeader from "../../components/layout/SubHeader";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    Listagem,
    SubHeader,
  },
  data: () => ({
    export: [
      {
        descricaoPai: "Serviço",
        criaSheet: true,
        campos: [
          {
            header: "ID",
            field: "id_servico",
            checked: false,
          },
          {
            header: "Nome",
            field: "descricao",
            checked: false,
          },
          // {
          //   header: "Valor",
          //   field: "valor",
          //   checked: false,
          // },
          {
            header: "Descrição",
            field: "descricao_detalhada",
            checked: false,
          },
        ],
      },
    ],
    buttons: [
      {
        link: "/servico/0",
        titulo: "Add novo Serviço",
      },
    ],
    titulo: "Serviços",
    colunaPadrao: [
      {
        label: "Código",
        field: "id_servico",
      },
      {
        label: "Nome",
        field: "descricao",
      },
      {
        label: "Empresa",
        field: "empresa.pessoa.nome",
      },
      // {
      //   label: "Valor",
      //   field: "valor",
      // },
      {
        label: "Descrição",
        field: "descricao_detalhada",
      },
      {
        label: "Ação",
        field: "id_servico",
      },
    ],
    colunas: [
      {
        nome: "Código",
        index: "id_servico",
        isHtml: false,
      },

      {
        nome: "Nome",
        index: "descricao",
        isHtml: false,
      },
      {
        nome: "Empresa",
        index: "empresa.pessoa.nome",
        isHtml: false,
      },
      // {
      //   nome: "Valor",
      //   index: "valor",
      //   isHtml: false,
      // },
      {
        nome: "Descrição",
        index: "descricao_detalhada",
        isHtml: false,
      },
      {
        nome: "Ação",
        isHtml: false,
      },
    ],
    dados: [],
    monta: false,
  }),
  mounted() {
    window.KTApp.block("#kt_blockui_content", {
      overlayColor: "#000000",
      state: "primary",
      message: "Processando...",
    });
    this.ActionGetListServico().then((r) => {
      this.dados = r;
      this.monta = true;
      window.KTApp.unblock("#kt_blockui_content");
    });
    if (this.user.leitura == true) {
      this.buttons = [];
    }
    // .catch((error) => {
    //   if (error.status != 401) {
    //     if (error.data) {
    //       console.log(error.data);
    //       this.$swal.fire({
    //         icon: "error",
    //         title: error.data.error.title || "Oops...",
    //         text: error.data.error.message,
    //       });
    //     } else {
    //       this.$swal.fire({
    //         icon: "error",
    //         title: "Oops...",
    //         text: "Ocorreu um erro inesperado, favor contactar o suporte.",
    //       });
    //     }
    //   }
    // });
  },
  computed: {
    ...mapState("auth", ["empresa", "user"]),
    exportarLista: {
      get() {
        return this.export;
      },
      set(nValue) {
        this.export = nValue;
      },
    },
  },
  methods: {
    ...mapActions("servico", ["ActionGetListServico", "ActionInactiveServico"]),

    load() {
      this.ActionGetListServico().then((r) => {
        this.dados = r;
        this.monta = true;
      });
    },
    visualizar(item) {
      console.log(item);
    },
    inativar(item) {
      console.log(item);
      this.$swal
        .fire({
          icon: "question",
          title: "Atenção",
          text: "Deseja realmente inativar este serviço?",
          showCloseButton: true,
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: "Não",
          confirmButtonText: "Sim",
        })
        .then((r) => {
          if (r.value) {
            this.ActionInactiveServico(item.id_servico).then((r) => {
              if (r.sucesso == true) {
                this.$swal.fire({
                  icon: "success",
                  title: "Sucesso",
                  text: "Serviço inativado com sucesso",
                });
              }
              this.load();
            });
          }
        });
    },
    editar(item) {
      this.$router.push(`/servico/${item.id_servico}`);
    },
  },
  watch: {
    empresa(nValue) {
      this.empresa = nValue;
      this.load();
    },
  },
};
</script>