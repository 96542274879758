<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Subheader-->
    <sub-header :titulo="titulo" :buttons="buttons"></sub-header>
    <!--end::Subheader-->
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid" v-disable-all="user.leitura == true">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Dashboard-->
        <!--begin::Row-->

        <div class="row">
          <div class="col-lg-12">
            <div class="card card-custom gutter-b example example-compact">
              <div class="card-header">
                <h3 class="card-title">1. Cadastrando Serviços</h3>
                <div class="card-toolbar"></div>
              </div>
              <!--begin::Form-->
              <form class="form">
                <div class="card-body">
                  <div class="form-group row">
                    <div class="col-lg-1">
                      <label>Código:</label>
                      <input
                        type="number"
                        disabled
                        v-model="servico.id_servico"
                        class="form-control"
                        placeholder="Código interno serviço"
                      />
                    </div>

                    <div class="col-lg-4">
                      <label>Empresa:</label>
                      <select-populado
                        :data="servico.id_empresa"
                        :list="empresas"
                        :fieldKey="'id_empresa'"
                        :fieldText="'pessoa.nome'"
                        @onChange="ChangeEmpresa"
                        :disable="empresa.id_empresa != undefined"
                      />
                      <!-- <select class="form-control select2" id="kt_select2_2" name="param">
                        <option>Netolab</option>
                        <option>NetoYoutube</option>
                      </select>-->
                    </div>

                    <div class="col-lg-4">
                      <label>Nome:*</label>
                      <input
                        v-model="servico.descricao"
                        type="email"
                        class="form-control"
                        placeholder="Nome do Serviços"
                      />
                    </div>
                    <!-- 
                                                    <div class="col-lg-3">
														<label>Tipo:</label>
														<select class="form-control select2" id="kt_select2_3"
															name="param">
															<option>Consultor</option>
                                                            <option>Influencer marketer</option>
                                                            <option>Social Media</option>

														</select>
                    </div>-->

                    <!-- <div class="col-lg-3">
                      <label>Valor:</label>
                      <money
                        v-model="servico.valor"
                        class="form-control"
                        v-bind="money"
                      ></money>
                    </div> -->

                    <div class="col-lg-12">
                      <br />
                      <label>Descrição:</label>
                      <textarea
                        v-model="servico.descricao_detalhada"
                        rows="5"
                        class="form-control"
                        placeholder="Observação"
                      ></textarea>
                    </div>
                  </div>

                  <div class="separator separator-dashed my-10"></div>
                  <div class="form-group row">
                    <div class="col-lg-6">
                      <label>Status:</label> &nbsp;
                      <toggle-button
                        v-model="servico.ativo"
                        :sync="true"
                        :labels="true"
                        :font-size="15"
                        :width="80"
                        :height="40"
                      />
                      <!-- <input
                    data-switch="true"
                    type="checkbox"
                    name="switch"
                    id="test"
                    data-on-color="success"
                    v-model="funcionario.ativo"
                      />-->
                      <span class="form-text text-muted"></span>
                    </div>
                  </div>
                </div>

                <div class="card-footer">
                  <div class="row">
                    <div class="col-lg-12 ml-lg-auto" id="kt_blockui_content">
                      <button
                        type="button"
                        @click.prevent="salvar"
                        class="btn btn-primary mr-2"
                      >
                        {{ btnLabel }}
                      </button>
                      <button
                        type="button"
                        @click.prevent="voltar"
                        class="btn btn-secondary"
                      >
                        Cancelar
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <!--end::Form-->
            </div>
          </div>
        </div>

        <!--begin::Row-->

        <!--end::Row-->
        <!--end::Dashboard-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
  <!--end::Content-->
</template>
<script>
import SubHeader from "../../components/layout/SubHeader";
import SelectPopulado from "../../components/forms/SelectPopulado";
import { mapActions, mapState } from "vuex";
// import { Money } from "v-money";
export default {
  components: {
    SubHeader,
    SelectPopulado,
    // Money,
  },
  data: () => ({
    buttons: [
      {
        link: "/servico",
        titulo: "Ver todos Serviços",
        color: "btn-light-primary",
      },
    ],
    // money: {
    //   decimal: ",",
    //   thousands: ".",
    //   prefix: "R$ ",
    //   suffix: "",
    //   precision: 2,
    //   masked: false,
    // },

    servico: {
      id_servico: 0,
      descricao: "",
      descricao_detalhada: "",
      valor: 0,
      ativo: true,
      id_empresa: 0,
    },
    empresas: [],
  }),
  computed: {
    ...mapState("auth", ["empresa", "user"]),
    titulo() {
      if (this.$route.params.id == 0) {
        return "Adicionar Serviço";
      } else {
        return "Atualizar Serviço";
      }
    },
    btnLabel() {
      if (this.$route.params.id == 0) {
        return "Adicionar";
      } else {
        return "Atualizar";
      }
    },
  },
  watch: {
    empresa(nValue) {
      this.empresa = nValue;
      this.servico.id_empresa = this.empresa.id_empresa;
      if (this.opcao == "E") {
        this.$swal
          .fire({
            icon: "warning",
            title: "Atenção",
            text:
              "Houve mudança de empresa, você será redirecionado para a lista de Serviços ",
          })
          .then(() => this.$router.push("/servico"));
      }
    },
  },
  mounted() {
    window.$("[data-switch=true]").bootstrapSwitch();
    this.ActionGetEmpresas().then((r) => (this.empresas = r));
    this.opcao = "A";
    if (this.empresa.id_empresa != undefined) {
      this.servico.id_empresa = this.empresa.id_empresa;
    }

    if (this.$route.params.id != 0) {
      this.opcao = "E";
      window.KTApp.blockPage({
        overlayColor: "#000000",
        state: "primary",
        message: "Carregando...",
      });
      this.ActionGetServico(this.$route.params.id).then(
        (r) => (this.servico = r),
        window.KTApp.unblockPage()
      );
    }
  },
  methods: {
    ...mapActions("servico", [
      "ActionGetServico",
      "ActionUpdateServico",
      "ActionCreateServico",
    ]),
    ...mapActions("empresa", ["ActionGetEmpresas"]),
    voltar() {
      this.$router.push("/servico");
    },
    salvar() {
      if (this.servico.descricao == "") {
        this.$swal.fire({
          icon: "warning",
          title: "Atenção",
          text: "Favor Preencher o campo Nome",
        });
        return;
      }
      // if (this.servico.valor == 0) {
      //   this.$swal.fire({
      //     icon: "warning",
      //     title: "Atenção",
      //     text: "Favor Preencher o campo Valor",
      //   });
      //   return;
      // }
      if (this.servico.id_empresa == 0) {
        this.$swal.fire({
          icon: "warning",
          title: "Atenção",
          text: "Favor Preencher o campo Empresa",
        });
        return;
      }
      let r;
      if (this.opcao == "A") {
        r = this.ActionCreateServico(this.servico);
      } else {
        r = this.ActionUpdateServico(this.servico);
      }
      window.KTApp.blockPage({
        overlayColor: "#000000",
        state: "primary",
        message: "Carregando...",
      });
      r.then((x) => {
        if (x.sucesso == true) {
          this.$swal.fire({
            icon: x.sucesso == true ? "success" : "error",
            title: x.sucesso == true ? `Sucesso` : "Atenção / Erro",
            text:
              x.sucesso == true
                ? `Serviço  ${
                    this.opcao == "A" ? "adicionado" : "atualizado"
                  } com sucesso`
                : x.error.message,
          });
          window.KTApp.unblockPage();
          this.$router.push("/servico");
        }
      });
    },
    ChangeEmpresa(item) {
      this.servico.id_empresa = item;
    },
  },
};
</script>