import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2';
import VueGoodTablePlugin from 'vue-good-table';
import ToggleButton from 'vue-js-toggle-button'
import 'vue-good-table/dist/vue-good-table.css'
import 'sweetalert2/dist/sweetalert2.min.css';
import VueFileAgent from 'vue-file-agent';
import 'vue-file-agent/dist/vue-file-agent.css';
import VueApexCharts from 'vue-apexcharts';
import Print from 'vue-print-nb'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)
Vue.use(Print);
Vue.use(VueGoodTablePlugin);
Vue.use(VueSweetalert2);
Vue.use(require('vue-moment'));
Vue.use(ToggleButton);
Vue.use(VueFileAgent);

Vue.directive("disable-all", {
  // When all the children of the parent component have been updated
  componentUpdated: function (el, binding) {
    if (!binding.value) return;
    const tags = ["input", "button", "textarea", "select"];
    tags.forEach(tagName => {
      const nodes = el.getElementsByTagName(tagName);
      for (let i = 0; i < nodes.length; i++) {
        nodes[i].disabled = true;
        nodes[i].tabIndex = -1;
      }
    });


    // const nodes = el.getElementsByTagName("a");
    // console.log(el.getElementsByTagName("achor"));
    // console.log(el.getElementsByTagName("a"));
    // for (let i = 0; i < nodes.length; i++) {
    //   console.log(nodes[i]);
    //   nodes[i].style.display = "none";
    //   console.log(nodes[i]);
    //   // nodes[i].tabIndex = -1;
    // }


  }
});

Vue.config.productionTip = false


window._Vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
