<template>
  <FomularioComum
    v-disable-all="user.leitura == true"
    :titulo="titulo"
    :buttons="buttons"
    :nome="'Forma de Pagamento'"
    :disable="false"
    :opcao="opcao"
    :data="dado"
    @onSave="manter"
    @onCancel="voltar()"
  />
</template>
<script>
import FomularioComum from "../../components/forms/FomularioComum";
import { mapActions, mapState } from "vuex";
export default {
  name: "Forma de Pagamento",
  data: () => ({
    buttons: [
      {
        link: "/forma-pagamento/",
        titulo: "ver todas as Formas de Pagamentos",
        color: "btn-light-primary",
      },
    ],
    usuario: {},
    dado: {
      id: 0,
      nome: "",
      ativo: true,
    },
    formaPagamento: {
      id_categoria: 0,
      nome: "",
      ativo: true,
    },
  }),
  computed: {
    ...mapState("auth", ["user"]),

    opcao() {
      let id = this.$route.params.id;
      if (id == "0") {
        return "A";
      } else {
        return "E";
      }
    },
    titulo() {
      let id = this.$route.params.id;
      if (id == "0") {
        return "Adicionando Forma de Pagamento";
      } else {
        return "Editando Forma de Pagamento";
      }
    },
  },
  mounted() {
    let id = this.$route.params.id;
    if (id != "0") {
      window.KTApp.blockPage({
        overlayColor: "#000000",
        state: "primary",
        message: "Carregando...",
      });
      this.ActionGetFormasPagamento(id).then((r) => {
        this.formaPagamento = r;
        this.dado.id = this.formaPagamento.id_forma_pagamento;
        this.dado.nome = this.formaPagamento.descricao;
        this.dado.ativo = this.formaPagamento.ativo;
        window.KTApp.unblockPage();
      });
    }
  },
  methods: {
    ...mapActions("formaPagamento", [
      "ActionGetFormasPagamento",
      "ActionCreateFormasPagamento",
      "ActionUpdateFormasPagamento",
    ]),
    async manter(dado) {
      try {
        console.log(dado);
        this.dado = dado;
        let r;
        this.formaPagamento.id_forma_pagamento = this.dado.id;
        this.formaPagamento.descricao = this.dado.nome;
        this.formaPagamento.ativo = this.dado.ativo;
        window.KTApp.blockPage({
          overlayColor: "#000000",
          state: "primary",
          message: "Carregando...",
        });
        if (this.opcao == "A") {
          r = await this.ActionCreateFormasPagamento(this.formaPagamento);
        } else {
          r = await this.ActionUpdateFormasPagamento(this.formaPagamento);
        }
        if (r.sucesso == true) {
          this.$swal
            .fire({
              icon: r.sucesso == true ? "success" : "error",
              title: r.sucesso == true ? `Sucesso` : "Atenção / Erro",
              text:
                r.sucesso == true
                  ? `Forma de Pagamento ${
                      this.opcao == "A" ? "adicionada" : "atualizada"
                    } com sucesso`
                  : r.error.message,
            })
            .then(() => {
              this.$router.push("/forma-pagamento");
            });
        }
        window.KTApp.unblockPage();
      } catch (error) {
        // console.log(error);
        // if (error.data) {
        //   console.log(error.data);
        //   this.$swal.fire({
        //     icon: "error",
        //     title: error.data.error.title || "Oops...",
        //     text: error.data.error.message,
        //   });
        // } else {
        //   this.$swal.fire({
        //     icon: "error",
        //     title: "Oops...",
        //     text: "Ocorreu um erro inesperado, favor contactar o suporte.",
        //   });
        // }
      }
    },
    voltar() {
      this.$router.push("/forma-pagamento");
    },
  },
  components: {
    FomularioComum,
  },
};
</script>