<template>
  <!--begin::Content-->
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Subheader-->
    <sub-header :titulo="titulo" :buttons="buttons"></sub-header>
    <!--end::Subheader-->
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Dashboard-->
        <!--begin::Row-->

        <Listagem
          :dados="dados"
          v-disable-all="user.leitura == true"
          :colunas="colunaPadrao"
          :colunasDetalhes="colunas"
          :monta="monta"
          :canVer="false"
          :canInativar="user.leitura != true"
          :exportacao="exportarLista"
          @onVisualizar="visualizar"
          @onDelete="inativar"
          @onEditar="editar"
        />

        <!-- Button trigger modal-->
        <!-- <button
          type="button"
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModal"
        >Visualizar Funcionário</button>-->

        <!-- Modal-->

        <!--end::Row-->
        <!--end::Dashboard-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
  <!--end::Content-->
</template>
<script>
import Listagem from "../../components/forms/Listagem.vue";
import SubHeader from "../../components/layout/SubHeader";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    Listagem,
    SubHeader,
  },
  data: () => ({
    export: [
      {
        descricaoPai: "Categoria",
        criaSheet: true,
        campos: [
          {
            header: "ID",
            field: "id_categoria",
            checked: false,
          },
          {
            header: "Nome",
            field: "nome",
            checked: false,
          },
        ],
      },
    ],
    buttons: [
      {
        link: "/categoria/0",
        titulo: "Add nova categoria",
      },
    ],
    titulo: "Categoria",
    colunaPadrao: [
      {
        label: "ID",
        field: "id_categoria",
      },

      {
        label: "Descrição",
        field: "nome",
      },
      {
        label: "Ação",
        field: "id_categoria",
      },
    ],
    colunas: [
      {
        nome: "ID",
        index: "id_categoria",
        isHtml: false,
      },

      {
        nome: "Descrição",
        index: "nome",
        isHtml: false,
      },
    ],
    dados: [],
    monta: false,
  }),
  computed: {
    ...mapState("auth", ["user"]),
    exportarLista: {
      get() {
        return this.export;
      },
      set(nValue) {
        this.export = nValue;
      },
    },
  },
  mounted() {
    // this.ActionGetListCategoria().then((r) => {
    //   this.dados = r;
    //   this.monta = true;
    // });

    if (this.user.leitura == true) {
      this.buttons = [];
    }

    this.load();
    // .catch((error) => {
    //  if (error.status != 401) {
    //     if (error.data) {
    //       console.log(error.data);
    //       this.$swal.fire({
    //         icon: "error",
    //         title: error.data.error.title || "Oops...",
    //         text: error.data.error.message,
    //       });
    //     } else {
    //       this.$swal.fire({
    //         icon: "error",
    //         title: "Oops...",
    //         text: "Ocorreu um erro inesperado, favor contactar o suporte.",
    //       });
    //     }
    //   }
    // });
  },
  methods: {
    ...mapActions("categoria", [
      "ActionGetListCategoria",
      "ActionInactiveCategoria",
    ]),
    load() {
      window.KTApp.block("#kt_blockui_content", {
        overlayColor: "#000000",
        state: "primary",
        message: "Processando...",
      });
      this.ActionGetListCategoria().then((r) => {
        this.dados = r;
        this.monta = true;
      });
      window.KTApp.unblock("#kt_blockui_content");
    },

    visualizar(item) {
      console.log(item);
    },
    inativar(item) {
      console.log(item);
      this.$swal
        .fire({
          icon: "question",
          title: "Atenção",
          text: "Deseja realmente inativar esta categoria?",
          showCloseButton: true,
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: "Não",
          confirmButtonText: "Sim",
        })
        .then((r) => {
          if (r.value) {
            this.ActionInactiveCategoria(item.id_categoria).then((r) => {
              if (r.sucesso == true) {
                this.$swal.fire({
                  icon: "success",
                  title: "Sucesso",
                  text: "Categoria inativada com sucesso",
                });
              }
              this.load();
            });
          }
        });
    },
    editar(item) {
      this.$router.push(`/categoria/${item.id_categoria}`);
    },
  },
};
</script>