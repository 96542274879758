import services from "@/http";
import { http } from "@/http";
export const ActionGetDashboard = (context, payload) => {
    return services.dashboard.dashboard({ id: context.rootState.auth.empresa.id_empresa, hoje: payload }).then(r => {
        return r.data;
    });
};
export const ActionGetFluxoAno = (context, payload) => {
    let url = `dashboard/fluxo/${context.rootState.auth.empresa.id_empresa}/${payload.ano}`;

    if (payload.contabiliza) {
        url += "?contabiliza=true";
    }

    return http.get(url).then(r => r.data);

    // return services.dashboard.fluxo({ id: context.rootState.auth.empresa.id_empresa, ano: payload }).then(r => {
    //     return r.data;
    // });
};


