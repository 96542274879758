import services from "@/http";
// import * as types from "./mutation-types";

export const ActionGetListCategoria = () => {
    return services.categoria.all().then(r => {
        return r.data;
    });
};

export const ActionGetListCategoriaActive = () => {
    return services.categoria.active().then(r => {
        return r.data;
    });
};


export const ActionGetCategoria = (context, payload) => {
    return services.categoria.get({ id: payload }).then(r => {
        return r.data;
    });
};

export const ActionUpdateCategoria = (context, payload) => {
    return services.categoria.update(payload).then(r => {
        return r.data;
    });
};

export const ActionCreateCategoria = (context, payload) => {
    return services.categoria.create(payload).then(r => {
        return r.data;
    });
};


export const ActionInactiveCategoria = (context, payload) => {
    return services.categoria.inactive({ id: payload }).then(r => {
        return r.data;
    });
};