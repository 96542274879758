import services from "@/http";
import { http } from "@/http";
// import * as types from "./mutation-types";

export const ActionGetListServico = (context) => {
    return http.get(`servico/all?id_empresa=${context.rootState.auth.empresa.id_empresa}`).then(r => {
        return r.data;
    });
};

export const ActionGetListServicoActive = (context) => {
    return http.get(`servico/active?id_empresa=${context.rootState.auth.empresa.id_empresa}`).then(r => {
        return r.data;
    });
};


export const ActionGetServico = (context, payload) => {
    return services.servico.get({ id: payload }).then(r => {
        return r.data;
    });
};

export const ActionUpdateServico = (context, payload) => {
    return services.servico.update(payload).then(r => {
        return r.data;
    });
};

export const ActionCreateServico = (context, payload) => {
    return services.servico.create(payload).then(r => {
        return r.data;
    });
};


export const ActionInactiveServico = (context, payload) => {
    return services.servico.inactive({ id: payload }).then(r => {
        return r.data;
    });
};