import services from "@/http";
import { http } from "@/http";
// import * as types from "./mutation-types";

export const ActionGetExtrato = (context, payload) => {
    return services.extrato.extrato({ idEmpresa: payload.idEmpresa, dataInicio: payload.dataInicio, dataFim: payload.dataFim }).then(r => {
        return r.data;
    });
};

export const ActionGetConciliacao = (context, payload) => {
    return services.extrato.conciliacao({ idEmpresa: payload.idEmpresa, dataInicio: payload.dataInicio, dataFim: payload.dataFim }).then(r => {
        return r.data;
    });
};

export const ActionPostValidaCodigo = (context, payload) => {
    return http.post("conciliacao/codigos", { codigos: payload }).then(r => {
        return r.data;
    });
};


