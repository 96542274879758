import services from "@/http";
// import * as types from "./mutation-types";

export const ActionGetListAgenda = () => {
    return services.agenda.all().then(r => {
        return r.data;
    });
};

export const ActionGetListAgendaActive = () => {
    return services.agenda.active().then(r => {
        return r.data;
    });
};


export const ActionGetAgenda = (context, payload) => {
    return services.agenda.get({ id: payload }).then(r => {
        return r.data;
    });
};

export const ActionUpdateAgenda = (context, payload) => {
    return services.agenda.update(payload).then(r => {
        return r.data;
    });
};

export const ActionCreateAgenda = (context, payload) => {
    return services.agenda.create(payload).then(r => {
        return r.data;
    });
};


export const ActionDeleteAgenda = (context, payload) => {
    return services.agenda.delete({ id: payload }).then(r => {
        return r.data;
    });
};