import services from "@/http";
import { http } from "@/http";
// import * as types from "./mutation-types";

export const ActionGetEmpresas = () => {
    return services.empresa.all().then(r => {
        return r.data;
    });
};

export const ActionGetEmpresasAtivos = () => {
    return services.empresa.active().then(r => {
        return r.data;
    });
};


export const ActionGetEmpresa = (context, payload) => {
    return services.empresa.get({ id: payload }).then(r => {
        return r.data;
    });
};

export const ActionUpdateEmpresas = (context, payload) => {
    return services.empresa.update(payload).then(r => {
        return r.data;
    });
};

export const ActionCreateEmpresas = (context, payload) => {
    return services.empresa.create(payload).then(r => {
        return r.data;
    });

};

export const ActionInactiveEmpresas = (context, payload) => {
    return services.empresa.inactive({ id: payload }).then(r => {
        return r.data;
    });
};


export const ActionUploadLogo = (context, payload) => {


    let form = new FormData();
    form.append('logo', payload.logo);
    return http.post("empresa/logo/" + payload.id, form, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(r => r.data);
};