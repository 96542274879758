<template>
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-4 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <div class="d-flex align-items-center flex-wrap mr-2">
        <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">{{titulo}}</h5>
        <template v-for="(item,index) of buttons">
          <div
            :key="index"
            class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"
          ></div>
          <router-link
            :key="index"
            :class="`btn ${item.color!= undefined?item.color:'btn-light-warning'}   font-weight-bolder btn-sm`"
            :to="item.link"
          >{{item.titulo}}</router-link>
        </template>
      </div>
    </div>
  </div>
  <!--end::Subheader-->
</template>
<script>
export default {
  name: "SubHeader",
  props: {
    titulo: String,
    buttons: {
      type: Array,
      default: new Array(),
    },
  },
  watch: {
    buttons(newValue) {
      this.buttons = newValue;
    },
    titulo(newValue) {
      this.titulo = newValue;
    },
  },
};
</script>