import Home from "../views/home/Home.vue";
import FormasPagamento from "../views/FormasPagamento/FormasPagamento.vue";
import FormasPagamentoManter from "../views/FormasPagamento/FormasPagamentoManter.vue";
import TipoRecebimento from "../views/TipoRecebimento/TipoRecebimento.vue";
import TipoRecebimentoManter from "../views/TipoRecebimento/TipoRecebimentoManter.vue";
import Empresa from "../views/Empresa/Empresa.vue";
import EmpresaManter from "../views/Empresa/EmpresaManter.vue";
import Funcionario from "../views/funcionario/Funcionario.vue";
import FuncionarioManter from "../views/funcionario/FuncionarioManter.vue";
import Usuario from "../views/Usuario/Usuario.vue";
import UsuarioManter from "../views/Usuario/UsuarioManter.vue";
import PerfilAcesso from "../views/PerfilAcesso/PerfilAcesso.vue";
import Page401 from "../views/401/401.vue";
import Cliente from "../views/Cliente/Cliente.vue";
import ClienteManter from "../views/Cliente/ClienteManter.vue";
// import Fornecedor from "../views/Fornecedor/Fornecedor.vue";
// import FornecedorManter from "../views/Fornecedor/FornecedorManter.vue";
import Categoria from "../views/Categoria/Categoria.vue";
import CategoriaManter from "../views/Categoria/CategoriaManter.vue";
import ContaPagar from "../views/Conta/ContaPagar/ContaPagar.vue";
import ContaPagarManter from "../views/Conta/ContaPagar/ContaPagarManter.vue";
import Servico from "../views/Servico/Servico.vue";
import ServicoManter from "../views/Servico/ServicoManter.vue";
import Periodicidade from "../views/Periodicidade/Periodicidade.vue";
import ContaReceber from "../views/Conta/ContaReceber/ContaReceber.vue";
import ContaReceberManter from "../views/Conta/ContaReceber/ContaReceberManter.vue";
import Arquivo from "../views/Arquivo/Arquivo.vue";
import OrcamentoManter from "../views/Orcamento/OrcamentoManter.vue";
import Orcamento from "../views/Orcamento/Orcamento.vue";
import Extrato from "../views/Extrato/Extrato.vue";
import Conciliacao from "../views/Extrato/Conciliacao.vue";

import FluxoDiario from "../views/Relatorios/FluxoDiario/FluxoDiario.vue";
import FluxoMensal from "../views/Relatorios/FluxoMensal/FluxoMensal.vue";

import AnaliseRecebimento from '../views/Relatorios/AnaliseRecebimento/AnaliseRecebimento.vue';
import AnalisePagamento from '../views/Relatorios/AnalisePagamento/AnalisePagamento.vue';
import VendaCliente from '../views/Relatorios/VendaCliente/VendaCliente.vue';


export default [
    {
        path: '/',
        name: 'Dashboard',
        component: Home
    },
    {
        path: '/tipo-recebimento',
        name: 'Tipo Recebimento',
        component: TipoRecebimento
    },
    {
        path: '/tipo-recebimento/:id',
        name: 'Tipo Recebimento',
        component: TipoRecebimentoManter
    },
    {
        path: '/forma-pagamento',
        name: 'Forma de Pagamento',
        component: FormasPagamento
    },
    {
        path: '/forma-pagamento/:id',
        name: 'Forma de Pagamento',
        component: FormasPagamentoManter
    },
    {
        path: '/empresa',
        name: 'Empresa',
        component: Empresa
    },
    {
        path: '/empresa/:id',
        name: 'Empresa Manter',
        component: EmpresaManter
    },
    {
        path: '/cliente',
        name: 'Cliente',
        component: Cliente
    },
    {
        path: '/cliente/:id',
        name: 'Cliente Manter',
        component: ClienteManter
    },
    {
        path: '/cliente/:id/:tipo',
        name: 'Cliente Manter',
        component: ClienteManter
    },
    // {
    //     path: '/fornecedor',
    //     name: 'Fornecedor',
    //     component: Fornecedor
    // },
    // {
    //     path: '/fornecedor/:id',
    //     name: 'Fornecedor Manter',
    //     component: FornecedorManter
    // },

    {
        path: '/usuario',
        name: 'Usuários',
        component: Usuario
    },
    {
        path: '/usuario/:id',
        name: 'Usuário',
        component: UsuarioManter
    },
    {
        path: '/perfil-acesso',
        name: 'Perfil de Acesso',
        component: PerfilAcesso
    },
    {
        path: '/401',
        name: "Acesso negado",
        component: Page401
    },
    {
        path: '/funcionario',
        name: 'Funcionario',
        component: Funcionario
    },
    {
        path: '/funcionario/:id',
        name: 'Funcionario Manter',
        component: FuncionarioManter
    },
    {
        path: '/categoria',
        name: 'Categoria',
        component: Categoria
    },
    {
        path: '/categoria/:id',
        name: 'Categoria Manter',
        component: CategoriaManter
    },
    {
        path: '/servico',
        name: 'Serviços',
        component: Servico
    },
    {
        path: '/servico/:id',
        name: 'Servico Manter',
        component: ServicoManter
    },
    {
        path: '/periodicidade',
        name: 'Periodicidade',
        component: Periodicidade
    },
    {
        path: '/conta-pagar',
        name: 'Conta à Pagar',
        component: ContaPagar
    },
    {
        path: '/conta-pagar/:id',
        name: 'Conta à Pagar',
        component: ContaPagarManter
    },
    {
        path: '/conta-receber',
        name: 'Conta à Receber',
        component: ContaReceber
    },
    {
        path: '/orcamento/:id',
        name: 'Orçamento',
        component: OrcamentoManter
    },
    {
        path: '/orcamento',
        name: 'Orçamento',
        component: Orcamento
    },
    {
        path: '/extrato',
        name: 'Extrato',
        component: Extrato
    },
    {
        path: '/conciliacao',
        name: 'Conciliacao',
        component: Conciliacao
    },
    {
        path: '/conta-receber/:id',
        name: 'Conta à Receber',
        component: ContaReceberManter
    },
    {
        path: '/arquivo',
        name: 'Arquivo',
        component: Arquivo
    },
    {
        path: '/relatorio/fluxo-diario',
        name: 'Fluxo Diário',
        component: FluxoDiario
    },
    {
        path: '/relatorio/fluxo-mensal',
        name: 'Fluxo Mensal',
        component: FluxoMensal
    },
    {
        path: '/relatorio/analise-receber',
        name: 'Análise de Recebimento',
        component: AnaliseRecebimento
    },
    {
        path: '/relatorio/analise-pagar',
        name: 'Análise de Pagamento',
        component: AnalisePagamento
    },
    {
        path: '/relatorio/venda-cliente',
        name: 'Venda por Cliente',
        component: VendaCliente
    }

   
]

