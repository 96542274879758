import { store as auth } from "../modules/auth";
import cep from "./cep"
import { store as empresa } from "../views/Empresa";
import { store as funcionario } from "../views/funcionario";
import { store as usuario } from "../views/Usuario";
import { store as tipoRecebimento } from "../views/TipoRecebimento";
import { store as servico } from "../views/Servico";
import { store as periodicidade } from "../views/Periodicidade";
import { store as perfilAcesso } from "../views/PerfilAcesso";
import { store as orcamento } from "../views/Orcamento";
import { store as fornecedor } from "../views/Fornecedor";
import { store as formaPagamento } from "../views/FormasPagamento";
import { store as contaPagar } from "../views/Conta/ContaPagar";
import { store as contaReceber } from "../views/Conta/ContaReceber";
import { store as cliente } from "../views/Cliente";
import { store as categoria } from "../views/Categoria";
import { store as agenda } from "../views/Agenda";
import { store as movimentacao } from "../views/Conta/index";
import { store as dashboard } from "../views/home";
import { store as relatorio } from "../views/Relatorios";
import { store as extrato } from "../views/Extrato";
import anexo from "./anexo";


export default {
    auth,
    cep,
    empresa,
    funcionario,
    usuario,
    tipoRecebimento,
    servico,
    periodicidade,
    perfilAcesso,
    orcamento,
    fornecedor,
    formaPagamento,
    contaPagar,
    contaReceber,
    cliente,
    categoria,
    agenda,
    movimentacao,
    anexo,
    dashboard,
    relatorio,
    extrato
}