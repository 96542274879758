<template>
  <!--begin::Content-->
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Subheader-->
    <sub-header :titulo="titulo" :buttons="buttons"></sub-header>
    <!--end::Subheader-->
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Dashboard-->
        <!--begin::Row-->

        <Listagem
          :dados="dados"
          :colunas="colunaPadrao"
          :colunasDetalhes="colunas"
          :monta="monta"
          :exportacao="exportarLista"
          :canInativar="user.leitura != true"
          @onVisualizar="visualizar"
          @onDelete="inativar"
          @onEditar="editar"
        />

        <!-- Button trigger modal-->
        <!-- <button
          type="button"
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModal"
        >Visualizar Funcionário</button>-->

        <!-- Modal-->
        <div
          class="modal fade bd-example-modal-xl"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myExtraLargeModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-xl modal-dialog-scrollable"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Visualizando {{ ver.tipo }}
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i aria-hidden="true" class="ki ki-close"></i>
                </button>
              </div>
              <div id="imprimir">
                <div class="modal-body" style="height: 500px">
                  <div class="container">
                    <img :src="logo" style="width: 200px" />
                    <br />
                    <br />
                    <br />

                    <b>1. Informações</b>
                    <hr />
                    <br />

                    <table class="table table-bordered">
                      <tbody>
                        <tr>
                          <td>
                            <b>Cliente / Fornecedores:</b>
                            {{ ver.cliente.pessoa.nome }}
                          </td>
                          <td colspan="2">
                            <b>Número:</b>
                            {{ ver.id_orcamento }}
                          </td>
                          <td>
                            <b>Contrato:</b>
                            {{ ver.contrato }}
                          </td>

                          <!-- <td>
                          <b>Introdução:</b>
                          {{ver.observacao}}
                        </td>
                          </tr>-->
                        </tr>
                        <tr>
                          <td>
                            <b>Data de Orçamento:</b>
                            {{ ver.data_orcamento }}
                          </td>
                          <td>
                            <b>Validade do Orçamento:</b>
                            {{ ver.data_validade }}
                          </td>
                          <td colspan="2">
                            <b>Previsão de Entrega:</b>
                            {{ ver.data_entrega }}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div class="table table-bordered">
                      <p style="margin: 12px">{{ ver.observacao }}</p>
                    </div>

                    <br />
                    <b>2. Produto / Serviço</b>
                    <hr />
                    <br />

                    <table class="table table-bordered">
                      <tbody>
                        <b>Orçamento(s):</b>
                        <tr v-for="item of ver.itens" :key="item.id_orcamento">
                          <td>
                            <b>Serviço:</b>
                            {{ item.servico.descricao }}
                          </td>
                          <td>
                            <b>Valor:</b>
                            R$ {{ item.valor }}
                          </td>
                          <td>
                            <b>Quantidade:</b>
                            {{ item.quantidade }}
                          </td>
                          <td>
                            <b>Valor Total:</b>
                            R$ {{ item.valor_total }}
                          </td>
                        </tr>
                        <td colspan="3">
                          <b>Total do orçamento</b>
                        </td>
                        <td>
                          <!--      <b>R$</b> -->
                          {{
                            vl_total.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })
                          }}
                        </td>
                      </tbody>
                    </table>

                    <br />
                    <b>3. Detalhamento dos serviços</b>
                    <hr />

                    <div class="table table-bordered">
                      <p style="margin: 12px">{{ ver.descricao }}</p>
                    </div>

                    <table class="table table-bordered">
                      <tbody>
                        <tr>
                          <td colspan="2">
                            <b>Forma de Pagamento:</b>
                            {{ ver.formaPagamento.descricao }}
                          </td>
                          <td colspan="2">
                            <b>Vendedor:</b>
                            {{ ver.funcionario.pessoa.nome }}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <br />
                    <div class="table table-bordered"></div>
                    <br />

                    <div class="text">
                      Rio de Janeiro,
                      {{ agenda.toLocaleDateString("pt-br", options) }}
                    </div>

                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-light-primary font-weight-bold"
                  data-dismiss="modal"
                >
                  <i aria-hidden="true" class="ki ki-close"></i>
                  Fechar
                </button>
                <button
                  type="button"
                  class="btn btn-light-primary font-weight-bold"
                  v-print="'#imprimir'"
                >
                  <i aria-hidden="true" class="fa fa-print"></i>
                  Imprimir
                </button>
              </div>
            </div>
          </div>
        </div>

        <!--end::Row-->
        <!--end::Dashboard-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
  <!--end::Content-->
</template>
<script>
import Listagem from "../../components/forms/Listagem.vue";
import SubHeader from "../../components/layout/SubHeader";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    Listagem,
    SubHeader,
  },
  data: () => ({
    export: [
      {
        descricaoPai: "Orçamento",
        criaSheet: true,
        campos: [
          {
            header: "ID",
            field: "id_orcamento",
            checked: false,
          },
          {
            header: "Clientes / Fornecedores",
            field: "obj.pessoa.nome",
            checked: false,
          },
          {
            header: "Status de negociação",
            field: "situacao",
            checked: false,
          },
          {
            header: "Contrato",
            field: "situacao",
            checked: false,
          },
          {
            header: "Introdução",
            field: "observacao",
            checked: false,
          },
          {
            header: "Data de Orçamento",
            field: "data_orcamento",
            checked: false,
          },
          {
            header: "Validade do Orçamento",
            field: "data_validade",
            checked: false,
          },
          {
            header: "Previsão de entrega",
            field: "data_entrega",
            checked: false,
          },
          {
            header: "Valor Total",
            field: "valor_total",
            checked: false,
          },
          {
            header: "Forma de Pagamento",
            field: "formaPagamento.descricao",
            checked: false,
          },
          {
            header: "Vendedor Responsável",
            field: "funcionario.pessoa.nome",
            checked: false,
          },
          {
            header: "Informações",
            field: "descricao",
            checked: false,
          },
        ],
      },
      /*  {
        descricaoPai: "Produtos / Serviços",
        criaSheet: false,
        campos: [
          {
            header: "Serviços",
            field: "servicos",
            checked: false,
          }, 
          {
            header: "Quantidade",
            field: "quantidade",
            checked: false,
          }, 
          {
            header: "Valor",
            field: "itens.valor",
            checked: false,
          }, 
        ],
      }, */
    ],
    buttons: [
      {
        link: "/orcamento/0",
        titulo: "Add novo Orçamento",
      },
    ],
    titulo: "Orçamentos",
    colunaPadrao: [
      {
        label: "Número",
        field: "id_orcamento",
      },
      {
        label: "Cliente/Fornecedor",
        field: "obj.pessoa.nome",
      },
      {
        label: "Serviço(s)",
        field: "servicos",
      },
      {
        label: "Dt. Orçamento",
        field: "data_orcamento",
      },
      {
        label: "Previsão Entrega",
        field: "data_entrega",
      },
      {
        label: "Valor",
        field: "valor_total",
      },
      {
        label: "Status",
        field: "situacao",
        filterOptions: {
          enabled: true, // enable filter for this column
          placeholder: "Filtrar", // placeholder for filter input
          filterDropdownItems: [
            "Em orçamento".toUpperCase(),
            "Orçamento aceito".toUpperCase(),
            "Recusado".toUpperCase(),
            "Venda".toUpperCase(),
          ],
        },
      },
      {
        label: "Ativo?",
        field: "ativo_label",
        filterOptions: {
          enabled: true, // enable filter for this column
          placeholder: "Filtrar", // placeholder for filter input
          filterDropdownItems: ["ativo".toUpperCase(), "inativo".toUpperCase()],
        },
      },
      {
        label: "Ação",
        field: "id_orcamento",
      },
    ],
    colunas: [
      {
        nome: "Número",
        index: "id_orcamento",
        isHtml: false,
      },
      {
        nome: "Cliente/Fornecedor",
        index: "obj.pessoa.nome",
        isHtml: false,
      },
      {
        nome: "Serviço(s)",
        index: "servicos",
        isHtml: false,
      },
      {
        nome: "Dt. Orçamento",
        index: "data_orcamento",
        isHtml: false,
      },
      {
        nome: "Previsão Entrega",
        index: "data_entrega",
        isHtml: false,
      },
      {
        nome: "Valor",
        index: "valor_total",
        isHtml: false,
      },
      {
        nome: "Status",
        index: "situacao",
        isHtml: false,
      },
      {
        nome: "Ativo?",
        index: "ativo_label",
        isHtml: false,
      },
    ],
    dados: [],
    monta: false,
    agenda: new Date(),
    options: {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    },
    ver: {
      id_orcamento: 0,
      id_empresa: 0,
      descricao: "",
      situacao: "",
      ativo: true,
      observacao: "",
      data_orcamento: null,
      dt_validade: null,
      dt_entrega: null,
      id_forma_pagamento: 0,
      id_cliente: 0,
      id_fornecedor: 0,
      id_funcionario: 0,
      id_obj: 0,
      empresa: {
        url_logo: "",
      },
      funcionario: {
        pessoa: {
          nome: "",
        },
      },
      cliente: {
        pessoa: {
          nome: "",
        },
      },
      formaPagamento: {
        descricao: "",
      },
      itens: [
        {
          id_servico: 0,
          valor: 0,
          servico: {
            descricao: "",
          },
          quantidade: 0,
          valor_total: 0,
        },
      ],
    },
    itemServico: {
      id_servico: 0,
      servico: {
        observacao: "",
      },
      valor: 0,
      quatidade: 0,
      valor_total: 0,
    },
  }),
  mounted() {
    this.load();
    if (this.user.leitura == true) {
      this.buttons = [];
    }
    // .catch((error) => {
    //  if (error.status != 401) {
    //     if (error.data) {
    //       console.log(error.data);
    //       this.$swal.fire({
    //         icon: "error",
    //         title: error.data.error.title || "Oops...",
    //         text: error.data.error.message,
    //       });
    //     } else {
    //       this.$swal.fire({
    //         icon: "error",
    //         title: "Oops...",
    //         text: "Ocorreu um erro inesperado, favor contactar o suporte.",
    //       });
    //     }
    //   }
    // });
  },
  computed: {
    ...mapState("auth", ["empresa", "user"]),
    vl_total() {
      let ximba = this.ver.itens.reduce((acc, value) => {
        return acc + Number(value.valor);
      }, 0);
      return ximba;
    },
    logo() {
      return this.empresa.url_logo == undefined ||
        this.empresa.url_logo == null ||
        this.empresa.url_logo == ""
        ? "assets/media/logos/logo-light.png"
        : this.empresa.url_logo;
    },
    exportarLista: {
      get() {
        return this.export;
      },
      set(nValue) {
        this.export = nValue;
      },
    },
  },
  methods: {
    ...mapActions("orcamento", [
      "ActionGetListOrcamento",
      "ActionInactiveOrcamento",
    ]),
    load() {
      window.KTApp.block("#kt_blockui_content", {
        overlayColor: "#000000",
        state: "primary",
        message: "Processando...",
      });
      this.ActionGetListOrcamento().then((r) => {
        this.dados = r.filter((f) => f.ativo);
        this.dados.forEach((el) => {
          el["servicos"] = el.itens.map((x) => x.servico.descricao).join(", ");
          el["obj"] = el.id_cliente != undefined ? el.cliente : el.fornecedor;
          el.data_orcamento = this.$moment(el.data_orcamento).format(
            "DD/MM/YYYY"
          );
          el.data_validade = this.$moment(el.data_validade).format(
            "DD/MM/YYYY"
          );
          el.data_entrega = this.$moment(el.data_entrega).format("DD/MM/YYYY");
          el.contrato = el.contrato == true ? "CONTRATADO" : "NÃO CONTRATADO";
          el.valor_total = Number(el.valor_total).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
          el.ativo_label = el.ativo == true ? "ATIVO" : "INATIVO";
        });
        this.monta = true;

        window.KTApp.unblock("#kt_blockui_content");
      });
    },
    visualizar(item) {
      console.log(item);
      this.ver = item;
    },
    inativar(item) {
      /* console.log(item); */
      this.$swal
        .fire({
          icon: "question",
          title: "Atenção",
          text: `Deseja realmente inativar este Orçamento?`,
          showCloseButton: true,
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: "Não",
          confirmButtonText: "Sim",
        })
        .then((r) => {
          if (r.value) {
            this.ActionInactiveOrcamento(item.id_orcamento).then((r) => {
              if (r.sucesso == true) {
                this.$swal.fire({
                  icon: "success",
                  title: "Sucesso",
                  text: `Orçamento inativado com sucesso`,
                });
              }
              this.load();
            });
          }
        });
    },
    editar(item) {
      this.$router.push(`/orcamento/${item.id_orcamento}`);
    },
  },
  watch: {
    empresa(nValue) {
      this.empresa = nValue;
      this.load();
    },
  },
};
</script>