import { services as auth } from "../modules/auth";
import { services as empresa } from "../views/Empresa";
import { services as formasPagamento } from "../views/FormasPagamento";
import { services as agenda } from "../views/Agenda";
import { services as categoria } from "../views/Categoria";
import { services as cliente } from "../views/Cliente";
import { services as contaPagar } from "../views/Conta/ContaPagar";
import { services as contaReceber } from "../views/Conta/ContaReceber";
import { services as fornecedor } from "../views/Fornecedor";
import { services as orcamento } from "../views/Orcamento";
import { services as perfilAcesso } from "../views/PerfilAcesso";
import { services as periodicidade } from "../views/Periodicidade";
import { services as servico } from "../views/Servico";
import { services as tipoRecebimento } from "../views/TipoRecebimento";
import { services as usuario } from "../views/Usuario";
import { services as funcionario } from "../views/funcionario";
import { services as dashboard } from "../views/home";
import { services as extrato } from "../views/Extrato";

export default {
    auth,
    empresa,
    formasPagamento,    
    agenda,
    categoria,
    cliente,
    contaPagar,
    contaReceber,
    fornecedor,
    orcamento,
    perfilAcesso,
    periodicidade,
    servico,
    tipoRecebimento,
    usuario,
    funcionario,
    dashboard,
    extrato,
    cep: {
        buscar: { method: 'GET', url: 'integracao/cep{/cep}' }
    }
};