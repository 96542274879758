<template>
  <div class="card card-custom">
    <div class="card-body" id="kt_blockui_content">
      <div
        class="card-toolbar"
        style="text-align: right; margin: 6px; margin-top: -10px"
      >
        <button
          v-if="canBtnAtivo"
          type="button"
          :class="`btn btn-outline-${ativo ? 'success' : 'danger'} btn-sm`"
          @click="AtivarInativar()"
        >
          <i :class="`far fa-thumbs-${ativo ? 'up' : 'down'} icon-lg`"></i>
          <span class="nav-text">{{ ativo ? "ATIVOS" : "INATIVOS" }}</span>
        </button>
        &nbsp; &nbsp;
        <button
          v-if="canBtnExport"
          @click="montarTable()"
          type="button"
          class="btn btn-outline-info btn-sm"
          data-toggle="modal"
          data-target="#ModalExportacao"
        >
          <i class="fa fa-cog icon-lg"></i>
          <span class="nav-text">EXPORTAR</span>
        </button>
      </div>

      <div
        class="modal fade bd-example-modal-xl"
        id="ModalExportacao"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myExtraLargeModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-xl modal-dialog-scrollable"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Exportando</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i aria-hidden="true" class="ki ki-close"></i>
              </button>
            </div>
            <div class="modal-body" style="height: 500px">
              <div class="container">
                <b>1. Selecione as opções para exportação:</b>
                <hr />
                <br />

                <div class="checkbox-inline">
                  <input
                    type="checkbox"
                    name="checkAll"
                    v-model="checkExportacaoTodos"
                  />
                  <b style="margin-left: 8px">Marcar Todos</b>
                </div>

                <br />
                <v-template v-for="(item, index) in exportacao" :key="index">
                  <span class="form-text text-muted">{{
                    item.descricaoPai
                  }}</span>
                  <br />
                  <v-template
                    v-for="(x, idx) in efeito(item.campos)"
                    :key="idx"
                  >
                    <div class="checkbox-inline">
                      <v-template v-for="(filho, i) in x" :key="i">
                        <label
                          class="checkbox checkbox-lg"
                          v-if="
                            filho.hidden == false || filho.hidden == undefined
                          "
                        >
                          <input
                            type="checkbox"
                            v-model="filho.checked"
                            name="Checkboxes3_1"
                          />
                          <span></span>
                          {{ filho.header }}
                        </label>
                        <br
                          v-if="
                            filho.hidden == false || filho.hidden == undefined
                          "
                        />
                      </v-template>
                    </div>
                  </v-template>
                </v-template>

                <!-- <div class="checkbox-inline">
                  <label class="checkbox checkbox-lg">
                    <input type="checkbox" name="Checkboxes3_1" />
                    <span></span>Categoria
                  </label>
                  <label class="checkbox checkbox-lg">
                    <input type="checkbox" name="Checkboxes3_1" />
                    <span></span>Forncededor/Cliente
                  </label>
                  <label class="checkbox checkbox-lg">
                    <input type="checkbox" name="Checkboxes3_1" />
                    <span></span>Data Competência
                  </label>
                  <label class="checkbox checkbox-lg">
                    <input type="checkbox" name="Checkboxes3_1" />
                    <span></span>Data Vencimento
                  </label>
                </div>
                <br />-->
                <!-- <div class="checkbox-inline">
                  <label class="checkbox checkbox-lg">
                    <input type="checkbox" name="Checkboxes3_1" />
                    <span></span>Ocorrências
                  </label>
                  <label class="checkbox checkbox-lg">
                    <input type="checkbox" name="Checkboxes3_1" />
                    <span></span>Quantidade de Ocorrências
                  </label>
                  <label class="checkbox checkbox-lg">
                    <input type="checkbox" name="Checkboxes3_1" />
                    <span></span>Valor
                  </label>
                  <label class="checkbox checkbox-lg">
                    <input type="checkbox" name="Checkboxes3_1" />
                    <span></span>Status
                  </label>
                  <label class="checkbox checkbox-lg">
                    <input type="checkbox" name="Checkboxes3_1" />
                    <span></span>Observação
                  </label>
                </div>
                <br />-->

                <!-- <span class="form-text text-muted">Pagamento</span>
                <br />-->

                <!-- <div class="checkbox-inline">
                  <label class="checkbox checkbox-lg">
                    <input type="checkbox" name="Checkboxes3_1" />
                    <span></span>Data de Pagamento
                  </label>
                  <label class="checkbox checkbox-lg">
                    <input type="checkbox" name="Checkboxes3_1" />
                    <span></span>Desconto/Taxas
                  </label>
                  <label class="checkbox checkbox-lg">
                    <input type="checkbox" name="Checkboxes3_1" />
                    <span></span>Juros e Multas
                  </label>
                  <label class="checkbox checkbox-lg">
                    <input type="checkbox" name="Checkboxes3_1" />
                    <span></span>Valor Pago
                  </label>
                </div>-->
              </div>
            </div>
            <div class="modal-footer">
              <xlsx-workbook>
                <xlsx-sheet
                  :collection="sheet.data"
                  v-for="sheet in sheets"
                  :key="sheet.name"
                  :sheet-name="sheet.name"
                />
                <xlsx-download :filename="nomeArq">
                  <button
                    :disabled="!hasFieldChecked"
                    class="btn btn-light-info font-weight-bold"
                  >
                    Exportar
                  </button>
                </xlsx-download>
              </xlsx-workbook>
              <button
                type="button"
                class="btn btn-light-primary font-weight-bold"
                data-dismiss="modal"
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- :sort-options="{
           enabled:true,
           initialSortBy: sorts
      }"-->
      <!-- :fixed-header="true" -->
      <vue-good-table
        ref="myTable"
        :columns="colunas"
        :rows="dadosFiltro"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          nextLabel: 'Próximo',
          prevLabel: 'Anterior',
          ofLabel: 'de',
          rowsPerPageLabel: 'Linhas por página',
          perPageDropdown: [20, 50],
          pageLabel: 'página',
          allLabel: 'Todos',
        }"
        :search-options="{
          enabled: true,
          placeholder: 'Pesquisar',
        }"
        styleClass="table table-bordered table-hover table-checkable marginApoio"
      >
        <div slot="emptystate" class="text-center">Não há registros</div>
        <template slot="table-row" slot-scope="props">
          <span v-if="isAction(props)">
            <a
              v-if="canVer == true"
              id="listaVer"
              @click="ver(props.row)"
              class="btn btn-sm btn-clean btn-icon"
              data-toggle="modal"
              data-target="#exampleModal"
              title="Ver"
            >
              <i class="la la-eye"></i>
            </a>
            <!-- &&(efetivo(props.row)) -->
            <a
              v-if="canEditar == true"
              class="btn btn-sm btn-clean btn-icon"
              title="Editar"
              @click="editar(props.row)"
            >
              <i class="la la-edit"></i>
            </a>
            <a
              v-if="
                canInativar == true &&
                props.row.ativo != false &&
                efetivo(props.row)
              "
              class="btn btn-sm btn-clean btn-icon"
              title="Deletar"
              @click="inativar(props.row)"
            >
              <i class="la la-trash"></i>
            </a>
          </span>
          <span v-else-if="isHTMLField(props.column)">
            <span v-html="montarField(props.row, props.column)"></span>
          </span>
          <span v-else>{{ montarField(props.row, props.column) }}</span>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

     
<script>
function resolve(path, obj) {
  return path.split(".").reduce(function (prev, curr) {
    return prev ? prev[curr] : null;
  }, obj || self);
}
import * as _ from "lodash";
import {
  XlsxDownload,
  XlsxWorkbook,
  XlsxSheet,
} from "vue-xlsx/dist/vue-xlsx.es.js";

export default {
  props: {
    dados: Array,
    colunas: Array,
    colunasDetalhes: Array,
    canVer: {
      type: Boolean,
      default: true,
    },
    canEditar: {
      type: Boolean,
      default: true,
    },
    canInativar: {
      type: Boolean,
      default: true,
    },
    canBtnExport: {
      type: Boolean,
      default: true,
    },
    canBtnAtivo: {
      type: Boolean,
      default: true,
    },
    canBtnDownload: {
      type: Boolean,
      default: true,
    },
    exportacao: {
      type: Array,
      default: new Array(),
    },
  },
  components: {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  data: () => ({
    ativo: true,
    dadosFiltro: [],
    dadosExportacao: [],
  }),
  computed: {
    sorts() {
      return this.colunas.map((x) => {
        return { type: "asc", field: x.field };
      });
    },
    hasFieldChecked() {
      return (
        this.exportacao.filter(
          (f) => f.campos.filter((x) => x.checked == true).length > 0
        ).length > 0
      );
    },
    nomeArq() {
      return `${process.env.VUE_APP_NOME}_Export_${this.$moment(
        new Date()
      ).format("DD_MM_YYYY_HHmmss")}.xlsx`;
    },
    sheets() {
      let arrays = [];
      let obj = { name: "", data: [] };
      let apoio = {};
      this.exportacao
        .filter((el) => el.campos.filter((x) => x.checked == true).length > 0)
        .map((el, index) => {
          apoio = {};
          if (el.criaSheet == true && index != 0) {
            apoio = {};
            arrays.push(JSON.parse(JSON.stringify(obj)));
            obj = { name: el.descricaoPai, data: [] };
          } else if (index == 0) {
            obj.name = el.descricaoPai;
          }
          if (el.dados == undefined) {
            this.dadosExportacao.map((x, idx) => {
              apoio = {};
              apoio["index"] = idx;
              el.campos
                .filter((f) => f.checked == true)
                .map((element) => {
                  if (el.criaSheet == false || el.criaSheet == undefined) {
                    let xpto = obj.data.findIndex((f) => f.index == idx);
                    obj.data[xpto][element.header] = resolve(element.field, x);
                  } else {
                    apoio[element.header] = resolve(element.field, x);
                  }
                });
              if (el.criaSheet == true) {
                obj.data.push(JSON.parse(JSON.stringify(apoio)));
              }
            });
          } else {
            this.dadosExportacao.map((x, idx) => {
              if (x[el.dados] != undefined) {
                apoio = {};
                apoio["index"] = idx;
                el.campos
                  .filter((f) => f.checked == true && f.hidden == true)
                  .map((element) => {
                    apoio[element.header] = resolve(element.field, x);
                  });
                x[el.dados].map((y) => {
                  el.campos
                    .filter((f) => f.checked == true && f.hidden == undefined)
                    .map((element) => {
                      apoio[element.header] = resolve(element.field, y);
                    });
                  obj.data.push(JSON.parse(JSON.stringify(apoio)));
                });
              }
            });
            // this.dadosFiltro.map((x) => x[el.dados]).forEach((x, idx) => {});
          }
        });
      arrays.push(JSON.parse(JSON.stringify(obj)));
      arrays.forEach((el) => {
        el.data = el.data.map((x) => this.objectWithoutKey(x, "index"));
      });
      return arrays;
    },
    checkExportacaoTodos: {
      get() {
        return (
          this.exportacao.length ==
          this.exportacao.filter(
            (f) => f.campos.filter((x) => x.checked == true).length > 0
          ).length
        );
      },
      set(nValue) {
        return this.exportacao.forEach((element) => {
          element.campos.forEach((el) => {
            el.checked = nValue;
            /* el.checked == false ? (el.checked = true) : (el.checked = false); */
          });
        });
      },
    },
  },
  methods: {   
    isAction(props) {
      return props.column.label == "Ação";
    },
    objectWithoutKey(object, key) {
      const { [key]: deletedKey, ...otherKeys } = object;
      console.log(deletedKey);
      return otherKeys;
    },
    AtivarInativar() {
      this.ativo = !this.ativo;
      this.dadosFiltro = this.dados.filter((f) => f.ativo == this.ativo);
    },
    efetivo(row) {
      return row.efetivado == undefined || row.efetivado == null;
    },
    efeito(item) {
      return _.chunk(item, 6);
    },
    montarField(rows, cols) {
      let apx = this.colunasDetalhes.find((f) => f.nome == cols.label);

      if (apx.isHtml == true) {
        let colStrinfg = apx.html;
        apx.colsFormatar.forEach((element, index) => {
          colStrinfg = colStrinfg.replace(`{${index}}`, resolve(element, rows));
        });
        return colStrinfg;
      } else {
        return resolve(apx.index, rows);
      }
    },
    isHTMLField(cols) {
      let apx = this.colunasDetalhes.find((f) => f.nome == cols.label);
      if (apx.isHtml == true) {
        return apx.isHtml;
      } else {
        return false;
      }
    },
    AplicarHTML(col, item) {
      if (col.isHtml == true) {
        let colStrinfg = col.html;
        col.colsFormatar.forEach((element, index) => {
          colStrinfg = colStrinfg.replace(`{${index}}`, resolve(element, item));
        });
        return colStrinfg;
      } else {
        return "";
      }
    },
    montarTela(col, item) {
      return col.isHtml == false ? resolve(col.index, item) : "";
    },
    ver(item) {
      this.$emit("onVisualizar", item);
    },
    inativar(item) {
      this.$emit("onDelete", item);
    },
    editar(item) {
      this.$emit("onEditar", item);
    },
    montarTable() {
      //this.dadosExportacao = this.$refs.myTable.filteredRows[0].children;
      this.dadosExportacao = this.$refs.myTable.paginated[0].children;
      console.log(this.sheets);
    },
  },

  mounted() {
    if (this.canBtnAtivo) {
      this.dadosFiltro = this.dados.filter((f) => f.ativo == this.ativo);
    } else {
      this.dadosFiltro = this.dados;
    }
  },

  watch: {
    dados(nValue) {
      if (nValue) {
        this.dados = nValue;
        if (this.canBtnAtivo) {
          this.dadosFiltro = this.dados.filter((f) => f.ativo == this.ativo);
        } else {
          this.dadosFiltro = this.dados;
        }
      }
    },
    colunas(nValue) {
      if (nValue) {
        this.colunas = nValue;
      }
    },
    colunasDetalhes(nValue) {
      if (nValue) {
        this.colunas = nValue;
      }
    },
  },
};
</script>
<style >
.marginApoio {
  margin-top: 13px !important;
}
</style>