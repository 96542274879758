export default {
    all: { method: 'GET', url: 'funcionario/all' },
    active: { method: 'GET', url: 'funcionario/active' },
    get: { method: 'GET', url: 'funcionario/get{/id}' },
    create: {
        method: 'POST', url: 'funcionario', headers: {
            'Content-Type': 'application/json'
        }
    },
    update: {
        method: 'PUT', url: 'funcionario', headers: {
            'Content-Type': 'application/json'
        }
    },
    upload: {
        method: 'post', url: 'funcionario/anexo{/id}', headers: {
            'Content-Type': 'multipart/form-data'
        }
    },
    inactive: { method: 'DELETE', url: 'funcionario{/id}' },
};