import { http } from "@/http";

export const ActionGetFluxoDiario = (context, payload) => {
    let url = `relatorio/fluxo-diario?id_empresa=${context.rootState.auth.empresa.id_empresa}&dataInicio=${payload.dataInicio}&dataFim=${payload.dataFim}&efetivado=${payload.efetivado}`;
    if (payload.contabiliza) {
        url += "&contabiliza=true";
    }
    return http.get(url).then(r => r.data);
};
export const ActionGetFluxoMensal = (context, payload) => {
    let url = `relatorio/fluxo-mensal?id_empresa=${context.rootState.auth.empresa.id_empresa}&mesInicio=${payload.mesInicio}&mesFim=${payload.mesFim}&efetivado=${payload.efetivado}`;
    if (payload.contabiliza) {
        url += "&contabiliza=true";
    }
    return http.get(url).then(r => r.data);
};
export const ActionGetAcompanhamentoReceber = (context, payload) => {
    let url = `relatorio/analise-receber?id_empresa=${context.rootState.auth.empresa.id_empresa}&mesInicio=${payload.mesInicio}&mesFim=${payload.mesFim}&efetivado=${payload.efetivado}`;
    if (payload.contabiliza) {
        url += "&contabiliza=true";
    }
    return http.get(url).then(r => r.data);
};
export const ActionGetAcompanhamentoPagar = (context, payload) => {
    let url = `relatorio/analise-pagar?id_empresa=${context.rootState.auth.empresa.id_empresa}&mesInicio=${payload.mesInicio}&mesFim=${payload.mesFim}&efetivado=${payload.efetivado}`;
    if (payload.contabiliza) {
        url += "&contabiliza=true";
    }
    return http.get(url).then(r => r.data);
};

export const ActionGetVendasPorCliente = (context, payload) => {
    let url = `relatorio/vendas-cliente?id_empresa=${context.rootState.auth.empresa.id_empresa}&mesInicio=${payload.mesInicio}&mesFim=${payload.mesFim}`;
    if (payload.contabiliza) {
        url += "&contabiliza=true";
    }
    return http.get(url).then(r => r.data);
};
