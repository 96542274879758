import services from "@/http";
import { http } from "@/http";
// import * as types from "./mutation-types";

export const ActionGetListCliente = (context) => {
    return http.get(`cliente-fornecedor/all?id_empresa=${context.rootState.auth.empresa.id_empresa}`).then(r => {
        for (let iterator of r.data) {
            iterator["tipo"] = iterator.id_cliente ? "cliente" : iterator.id_fornecedor ? "fornecedor" : "";
        }
        return r.data;
    });
};

export const ActionGetListClienteActive = (context) => {

    return http.get(`cliente-fornecedor/active?id_empresa=${context.rootState.auth.empresa.id_empresa}`).then(r => {
        for (let iterator of r.data) {
            iterator["tipo"] = iterator.id_cliente ? "cliente" : iterator.id_fornecedor ? "fornecedor" : "";
        }
        return r.data;
    });
};

export const ActionGetCliente = (context, payload) => {
    return services.cliente.get({ id: payload.id, tipo: payload.tipo }).then(r => {

        r.data["tipo"] = r.data.id_cliente ? "cliente" : r.data.id_fornecedor ? "fornecedor" : "";
        return r.data;
    });
};

export const ActionUpdateCliente = (context, payload) => {
    return services.cliente.update(payload).then(r => {
        return r.data;
    });
};

export const ActionCreateCliente = (context, payload) => {
    return services.cliente.create(payload).then(r => {
        return r.data;
    });
};


export const ActionInactiveCliente = (context, payload) => {
    return services.cliente.inactive({ id: payload.id, tipo: payload.tipo }).then(r => {
        return r.data;
    });
};