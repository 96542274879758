<template>
  <FomularioComum
    :titulo="titulo"
    :buttons="buttons"
    :nome="'Categoria'"
    :disable="user.leitura == true"
    :opcao="opcao"
    :data="dado"
    @onSave="manter"
    @onCancel="voltar"
  />
</template>
<script>
import FomularioComum from "../../components/forms/FomularioComum";
import { mapActions, mapState } from "vuex";
export default {
  name: "Categoria",
  data: () => ({
    buttons: [
      {
        link: "/categoria/",
        titulo: "ver todas as Categorias",
        color: "btn-light-primary",
      },
    ],
    usuario: {},
    dado: {
      id: 0,
      nome: "",
      ativo: true,
    },
    categoria: {
      id_categoria: 0,
      nome: "",
      ativo: true,
    },
  }),
  computed: {
    ...mapState("auth", ["user"]),
    opcao() {
      let id = this.$route.params.id;
      if (id == "0") {
        return "A";
      } else {
        return "E";
      }
    },
    titulo() {
      let id = this.$route.params.id;
      if (id == "0") {
        return "Adicionando Categoria";
      } else {
        return "Editando Categoria";
      }
    },
  },
  mounted() {
    let id = this.$route.params.id;
    if (id != "0") {
      window.KTApp.blockPage({
        overlayColor: "#000000",
        state: "primary",
        message: "Carregando...",
      });
      this.ActionGetCategoria(id).then((r) => {
        this.categoria = r;
        console.log(this.categoria);
        this.dado.id = this.categoria.id_categoria;
        this.dado.nome = this.categoria.nome;
        this.dado.ativo = this.categoria.ativo;
        console.log(this.dado);
        window.KTApp.unblockPage();
      });
    }
  },
  methods: {
    ...mapActions("categoria", [
      "ActionGetCategoria",
      "ActionCreateCategoria",
      "ActionUpdateCategoria",
    ]),
    async manter(dado) {
      try {
        console.log(dado);
        this.dado = dado;
        let r;
        this.categoria.id_categoria = this.dado.id;
        this.categoria.nome = this.dado.nome;
        this.categoria.ativo = this.dado.ativo;
        window.KTApp.blockPage({
          overlayColor: "#000000",
          state: "primary",
          message: "Carregando...",
        });
        if (this.opcao == "A") {
          r = await this.ActionCreateCategoria(this.categoria);
        } else {
          r = await this.ActionUpdateCategoria(this.categoria);
        }
        window.KTApp.unblockPage();

        if (r.sucesso == true) {
          this.$swal
            .fire({
              icon: r.sucesso == true ? "success" : "error",
              title: r.sucesso == true ? `Sucesso` : "Atenção / Erro",
              text:
                r.sucesso == true
                  ? `Categoria ${
                      this.opcao == "A" ? "adicionada" : "atualizada"
                    } com sucesso`
                  : r.error.message,
            })
            .then(() => {
              this.$router.push("/categoria");
            });
        }
      } catch (error) {
        // console.log(error);
        // if (error.status != 401) {
        //   if (error.data) {
        //     console.log(error.data);
        //     this.$swal.fire({
        //       icon: "error",
        //       title: error.data.error.title || "Oops...",
        //       text: error.data.error.message,
        //     });
        //   } else {
        //     this.$swal.fire({
        //       icon: "error",
        //       title: "Oops...",
        //       text: "Ocorreu um erro inesperado, favor contactar o suporte.",
        //     });
        //   }
        // }
      }
    },
    voltar() {
      this.$router.push("/categoria");
    },
  },
  components: {
    FomularioComum,
  },
};
</script>