<template>
  <!--begin::Content-->
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Subheader-->

    <sub-header :titulo="titulo" :buttons="buttons"></sub-header>
    <!--end::Subheader-->
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid" v-disable-all="user.leitura == true">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Dashboard-->
        <!--begin::Row-->

        <div class="row">
          <div class="col-lg-12">
            <!--begin::Card-->
            <DadosPessoais
              v-disable-all="user.leitura == true"
              :isFuncionario="true"
              :empresas="empresas"
              :data="funcionario"
              :ordem="1"
            />
            <!--end::Card-->
            <!--begin::Card-->
            <div class="card card-custom gutter-b example example-compact">
              <div class="card-header">
                <h3 class="card-title">2. Informações de Contato</h3>
              </div>
              <div class="card-body">
                <div class="form-group row">
                  <Emails :dados="emails" :disable="false" />
                </div>
                <div class="form-group row">
                  <Endereco :objeto="endereco" :disable="false" />
                </div>
                <div class="form-group row">
                  <Telefones :dados="telefones" :disable="false" />
                </div>
              </div>
            </div>
            <!--end::Card-->

            <!--begin::Card-->
            <ContaBancaria :ordem="3" :objeto="conta_bancaria" />
            <!--end::Form-->
            <div class="card-footer">
              <div class="separator separator-dashed my-10"></div>
              <div class="form-group row">
                <div class="col-lg-6">
                  <label>Status:</label> &nbsp;
                  <toggle-button
                    v-model="funcionario.ativo"
                    :sync="true"
                    :labels="true"
                    :font-size="15"
                    :width="80"
                    :height="40"
                  />
                  <!-- <input
                    data-switch="true"
                    type="checkbox"
                    name="switch"
                    id="test"
                    data-on-color="success"
                    v-model="funcionario.ativo"
                  />-->
                  <span class="form-text text-muted"></span>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 ml-lg-auto" id="kt_blockui_content">
                  <button
                    type="button"
                    @click="manter()"
                    class="btn btn-primary mr-2"
                  >
                    {{ opcao == "A" ? "Adicionar" : "Atualizar" }}
                  </button>
                  <button
                    type="reset"
                    @click="voltar()"
                    class="btn btn-secondary"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--begin::Row-->

      <!--end::Row-->
      <!--end::Dashboard-->
    </div>
    <!--end::Container-->
    <!--end::Entry-->
  </div>
  <!--end::Content-->
</template>
<script>
import DadosPessoais from "../../components/common/DadosPessoais";
import Emails from "../../components/common/Emails";
import Endereco from "../../components/common/Endereco";
import Telefones from "../../components/common/Telefones";
import SubHeader from "../../components/layout/SubHeader";
import ContaBancaria from "../../components/common/ContaBancaria";

import { mapActions, mapState } from "vuex";
export default {
  name: "Funcionario",
  data: () => ({
    func: {
      observacao: "",
      data_admissao: null,
      data_demissao: null,
      ultimo_salario: 0,
      ultimo_salario_contabil: 0,
      apelido: "",
      ativo: true,
      funcao: "",
      carteira_trabalho: "",
      dia_pagto: 0,
      id_empresa: 0,
      pessoa: {
        tipo_pessoa: "",
        nome: "",
        razao_social: "",
        identificador: "",
        ativo: true,
        data_nascimento: null,
        insc_estadual: "",
        insc_municipal: "",
        sexo: "",
        rg: "",
        telefones: [],
        enderecos: [
          {
            cep: "",
            logradouro: "",
            numero: "",
            complemento: "",
            bairro: "",
            cidade: "",
            estado: "",
            ativo: true,
          },
        ],
        emails: [],
      },
      conta_bancaria: {
        banco: "",
        codigo_banco: "",
        agencia: "",
        dv_agencia: "",
        conta: "",
        dv_conta: "",
        tipo: "",
        ativo: true,
        isPoupanca: false,
      },
    },
    end: {
      cep: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      estado: "",
      ativo: true,
    },
    empresas: [],
    buttons: [
      {
        link: "/funcionario",
        titulo: "ver todas Funcionários",
        color: "btn-light-primary",
      },
    ],
    usuario: {},
    opcao: "",
  }),
  computed: {
    ...mapState("auth", ["empresa", "user"]),

    funcionario: {
      get() {
        return this.func;
      },
      set(nValue) {
        this.func = nValue;
      },
    },
    endereco: {
      get() {
        return this.end;
      },
      set(nValue) {
        this.end = nValue;
      },
    },
    telefones: {
      get() {
        return this.func.pessoa.telefones;
      },
      set(nValue) {
        this.func.pessoa.telefones = nValue;
      },
    },
    emails: {
      get() {
        return this.func.pessoa.emails;
      },
      set(nValue) {
        this.func.pessoa.emails = nValue;
      },
    },
    conta_bancaria: {
      get() {
        return this.func.conta_bancaria;
      },
      set(nValue) {
        this.func.conta_bancaria = nValue;
      },
    },
    titulo() {
      if (this.$route.params.id == "0") {
        return "Adicionando Funcionários";
      } else {
        return "Editando Funcionários";
      }
    },
  },
  mounted() {
    this.opcao = "A";
    this.ActionGetEmpresas().then((r) => {
      this.empresas = r;
    });
    /* this.funcionario.id_empresa = this.empresa.id_empresa; */
    if (this.$route.params.id != 0) {
      this.opcao = "E";
      window.KTApp.blockPage({
        overlayColor: "#000000",
        state: "primary",
        message: "Carregando...",
      });
      this.ActionGetFuncionario(this.$route.params.id).then((r) => {
        this.funcionario = r;
        if (this.funcionario.pessoa.tipo_pessoa == "F") {
          this.funcionario.pessoa.cpf = this.funcionario.pessoa.identificador;
        } else if (this.funcionario.pessoa.tipo_pessoa == "J") {
          this.funcionario.pessoa.cnpj = this.funcionario.pessoa.identificador;
        }
        if (!this.funcionario.conta_bancaria) {
          this.funcionario.conta_bancaria = {
            banco: "",
            codigo_banco: "",
            agencia: "",
            dv_agencia: "",
            conta: "",
            dv_conta: "",
            tipo: "",
            ativo: true,
            isPoupanca: false,
          };
        }
        if (this.funcionario.pessoa.enderecos) {
          this.endereco = this.funcionario.pessoa.enderecos[0];
        } else {
          this.endereco = {
            cep: "",
            logradouro: "",
            numero: "",
            complemento: "",
            bairro: "",
            cidade: "",
            estado: "",
            ativo: true,
            id_endereco: 0,
          };
        }
        window.KTApp.unblockPage();
      });
      // .catch((error) => {
      //   console.log(error);
      //   if (error.data) {
      //     this.$swal.fire({
      //       icon: "error",
      //       title: error.data.error.title || "Oops...",
      //       text: error.data.error.message,
      //     });
      //   } else {
      //     this.$swal.fire({
      //       icon: "error",
      //       title: "Oops...",
      //       text: "Ocorreu um erro inesperado, favor contactar o suporte.",
      //     });
      //   }
      // });
    }

    window.$("[data-switch=true]").bootstrapSwitch();
  },
  methods: {
    ...mapActions("funcionario", [
      "ActionGetFuncionario",
      "ActionCreateFuncionario",
      "ActionUpdateFuncionario",
      "ActionUploadFoto",
    ]),
    ...mapActions("empresa", ["ActionGetEmpresas"]),
    manter() {
      this.funcionario.pessoa.identificador =
        this.funcionario.pessoa.cpf != undefined &&
        this.funcionario.pessoa.tipo_pessoa == "F"
          ? this.funcionario.pessoa.cpf
          : this.funcionario.pessoa.cnpj != undefined &&
            this.funcionario.pessoa.tipo_pessoa == "J"
          ? this.funcionario.pessoa.cnpj
          : this.funcionario.pessoa.identificador;
      /* Original */
      if (this.funcionario.apelido == "") {
        this.$swal.fire({
          icon: "warning",
          title: "Aviso",
          text: "Campo Apelido é obrigatório",
        });
        return;
      }
      if (this.funcionario.data_admissao == null) {
        this.$swal.fire({
          icon: "warning",
          title: "Aviso",
          text: "Campo Data de Admissão é obrigatório",
        });
        return;
      }
      if (
        this.funcionario.data_demissao != "" &&
        this.funcionario.data_demissao != null
      ) {
        if (this.funcionario.data_admissao > this.funcionario.data_demissao) {
          this.$swal.fire({
            icon: "warning",
            title: "Aviso",
            text: "Campo Data de Demissão Estar Menor do que Data de Admissão",
          });
          return;
        }
      }
      /* if (this.funcionario.data_demissao == null) {
        this.$swal.fire({
          icon: "warning",
          title: "Aviso",
          text: "Campo Data de Demissão é obrigatório",
        });
        return;
      } */
      if (this.funcionario.ultimo_salario == 0) {
        this.$swal.fire({
          icon: "warning",
          title: "Aviso",
          text: "Campo Valor do Salário é obrigatório",
        });
        return;
      }
      if (this.funcionario.ultimo_salario_contabil == 0) {
        this.$swal.fire({
          icon: "warning",
          title: "Aviso",
          text: "Campo Valor Salário Contábil é obrigatório",
        });
        return;
      }
      if (this.funcionario.funcao == "") {
        this.$swal.fire({
          icon: "warning",
          title: "Aviso",
          text: "Campo Função é obrigatório",
        });
        return;
      }
      /* if (this.funcionario.observacao == "") {
        this.$swal.fire({
          icon: "warning",
          title: "Aviso",
          text: "Campo Observação é obrigatório",
        });
        return;
      } */
      if (this.funcionario.pessoa.tipo_pessoa == "") {
        this.$swal.fire({
          icon: "warning",
          title: "Aviso",
          text: "Campo Tipo de Pessoas é obrigatório",
        });
        return;
      }
      /* End-Original */
      /* Pessoa Jurídica */
      if (this.funcionario.pessoa.tipo_pessoa == "J") {
        if (this.funcionario.pessoa.nome == "") {
          this.$swal.fire({
            icon: "warning",
            title: "Aviso",
            text: "Campo Nome Fantasia é obrigatório",
          });
          return;
        }
        if (this.funcionario.pessoa.razao_social == "") {
          this.$swal.fire({
            icon: "warning",
            title: "Aviso",
            text: "Campo Razão Social é obrigatório",
          });
          return;
        }
        if (this.funcionario.pessoa.identificador == 0) {
          this.$swal.fire({
            icon: "warning",
            title: "Aviso",
            text: "Campo CNPJ é obrigatório",
          });
          return;
        }
      }
      /* End-Pessoa Jurídica */
      /* Pessoa Fisica */
      if (this.funcionario.pessoa.tipo_pessoa == "F") {
        if (this.funcionario.pessoa.nome == "") {
          this.$swal.fire({
            icon: "warning",
            title: "Aviso",
            text: "Campo Nome Completo é obrigatório",
          });
          return;
        }
        if (this.funcionario.pessoa.sexo == "") {
          this.$swal.fire({
            icon: "warning",
            title: "Aviso",
            text: "Campo Sexo é obrigatório",
          });
          return;
        }
        if (this.funcionario.pessoa.data_nascimento == null) {
          this.$swal.fire({
            icon: "warning",
            title: "Aviso",
            text: "Campo Data de Nascimento é obrigatório",
          });
          return;
        }
        if (this.funcionario.carteira_trabalho == "") {
          this.$swal.fire({
            icon: "warning",
            title: "Aviso",
            text: "Campo Carteira de Trabalho é obrigatório",
          });
          return;
        }
        if (this.funcionario.pessoa.identificador == 0) {
          this.$swal.fire({
            icon: "warning",
            title: "Aviso",
            text: "Campo CPF é obrigatório",
          });
          return;
        }
        if (this.funcionario.rg == "") {
          this.$swal.fire({
            icon: "warning",
            title: "Aviso",
            text: "Campo RG é obrigatório",
          });
          return;
        }
      }
      try {
        if (this.funcionario.data_demissao == "") {
          this.funcionario.data_demissao = null;
        }
        let r;
        if (this.endereco.id_endereco) {
          this.funcionario.pessoa.enderecos.filter(
            (f) => f.id_endereco == this.endereco.id_endereco
          )[0] = this.endereco;
        } else {
          this.funcionario.pessoa.enderecos = [];
          this.funcionario.pessoa.enderecos.push(this.endereco);
        }

        let form = new FormData();
        form.append("foto", this.funcionario.foto);

        if (this.opcao == "A") {
          r = this.ActionCreateFuncionario(this.funcionario);
        } else {
          r = this.ActionUpdateFuncionario(this.funcionario);
        }
        window.KTApp.blockPage({
          overlayColor: "#000000",
          state: "primary",
          message: "Carregando...",
        });
        r.then((x) => {
          window.KTApp.unblockPage();
          if (x.sucesso == true) {
            if (this.func.foto != undefined) {
              this.ActionUploadFoto({
                id: x.funcionario.id_funcionario,
                foto: form,
              }).then((v) => {
                this.$swal
                  .fire({
                    icon: v.sucesso == true ? "success" : "error",
                    title: v.sucesso == true ? `Sucesso` : "Atenção / Erro",
                    text:
                      v.sucesso == true
                        ? `Funcionário ${
                            this.opcao == "A" ? "adicionado" : "atualizado"
                          } com sucesso`
                        : v.error.message,
                  })
                  .then(() => {
                    this.$router.push("/funcionario");
                  });
              });
            } else {
              this.$swal
                .fire({
                  icon: x.sucesso == true ? "success" : "error",
                  title: x.sucesso == true ? `Sucesso` : "Atenção / Erro",
                  text:
                    x.sucesso == true
                      ? `Funcionário ${
                          this.opcao == "A" ? "adicionado" : "atualizado"
                        } com sucesso`
                      : x.error.message,
                })
                .then(() => {
                  this.$router.push("/funcionario");
                });
            }
          }
        });
      } catch (error) {
        // console.log(error);
        // if (error.data) {
        //   console.log(error.data);
        //   this.$swal.fire({
        //     icon: "error",
        //     title: error.data.error.title || "Oops...",
        //     text: error.data.error.message,
        //   });
        // } else {
        //   this.$swal.fire({
        //     icon: "error",
        //     title: "Oops...",
        //     text: "Ocorreu um erro inesperado, favor contactar o suporte.",
        //   });
        // }
      }
    },
    voltar() {
      this.$router.push("/funcionario");
    },
  },
  components: {
    ContaBancaria,
    DadosPessoais,
    Endereco,
    Emails,
    Telefones,
    SubHeader,
  },
  watch: {
    empresa(nValue) {
      this.empresa = nValue;
      this.funcionario.id_empresa = this.empresa.id_empresa;
    },
  },
};
</script>