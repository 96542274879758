var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card card-custom"},[_c('div',{staticClass:"card-body",attrs:{"id":"kt_blockui_content"}},[_c('div',{staticClass:"card-toolbar",staticStyle:{"text-align":"right","margin":"6px","margin-top":"-10px"}},[(_vm.canBtnDownload)?_c('button',{staticClass:"btn btn-outline-info btn-sm",attrs:{"disabled":!_vm.selected,"type":"button"},on:{"click":function($event){return _vm.baixarArquivos()}}},[_c('i',{staticClass:"fa fa-download fa-2x"}),_c('span',{staticClass:"nav-text"},[_vm._v("DOWNLOAD TODOS")])]):_vm._e()]),_c('vue-good-table',{attrs:{"columns":_vm.colunas,"rows":_vm.dadosFiltro,"select-options":{
        enabled: true,
        selectionText: 'linha(s) selecionadas',
        clearSelectionText: 'limpar',
        selectOnCheckboxOnly: true,
      },"pagination-options":{
        enabled: true,
        mode: 'records',
        nextLabel: 'Próximo',
        prevLabel: 'Anterior',
        ofLabel: 'de',
        rowsPerPageLabel: 'Linhas por página',
        perPageDropdown: [10, 20, 50],
        pageLabel: 'página',
        allLabel: 'Todos',
      },"search-options":{
        enabled: true,
        placeholder: 'Pesquisar',
      },"styleClass":"table table-bordered table-hover table-checkable marginApoio"},on:{"on-selected-rows-change":_vm.selectionChanged},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(_vm.isAction(props))?_c('span',[(_vm.canDownload == true)?_c('a',{staticClass:"btn btn-sm btn-clean btn-icon",attrs:{"id":"listaVer","title":"Download"},on:{"click":function($event){return _vm.DownloadUnico(props.row)}}},[_c('i',{staticClass:"fa fa-download"})]):_vm._e()]):(_vm.isHTMLField(props.column))?_c('span',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.montarField(props.row, props.column))}})]):_c('span',[_vm._v(_vm._s(_vm.montarField(props.row, props.column)))])]}}])},[_c('div',{staticClass:"text-center",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v("Não há registros")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }