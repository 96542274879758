<template>
  <!--begin::User-->

  <div class="topbar-item">
    <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
    >
      <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Olá,</span>
      <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{{nome}}</span>
      <span class="symbol symbol-35 symbol-light-success">
        <span class="symbol-label font-size-h5 font-weight-bold">{{simbolo}}</span>
      </span>
     
    </div><br>
    
  </div>


  <!--end::User-->
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "user",
  computed: {
    ...mapState("auth", ["user"]),
    nome() {
      if (this.user.funcionario) {
        return this.user.funcionario.pessoa.nome;
      } else {
        return this.user.login;
      }
    },
    simbolo() {
      if (this.user.funcionario) {
        return this.user.funcionario.pessoa.nome.substring(0,1);
      } else {
        return this.user.login.substring(0,1);
      }
    }
  }
};
</script>