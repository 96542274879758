<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Subheader-->
    <sub-header :titulo="titulo" :buttons="buttons"></sub-header>
    <!--end::Subheader-->
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid" v-disable-all="user.leitura == true">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Dashboard-->
        <!--begin::Row-->

        <div class="row">
          <div class="col-lg-12">
            <div class="card card-custom gutter-b example example-compact">
              <div class="card-header">
                <h3 class="card-title">1. Adicionando Orçamento</h3>
                <div class="card-toolbar"></div>
              </div>
              <!--begin::Form-->
              <div class="card-body">
                <div class="form-group row">
                  <div class="col-lg-4">
                    <label>Clientes / Fornecedores:</label>
                    <select-populado
                      :data="orcamento.id_obj"
                      :list="fornecCliente"
                      :fieldKey="'id'"
                      :fieldText="'text'"
                      @onChange="ChangeFonecCliente"
                    />
                  </div>

                  <div class="col-lg-2">
                    <label>Número:</label>
                    <input
                      type="number"
                      v-model="orcamento.id_orcamento"
                      disabled
                      class="form-control"
                      placeholder="N. do Serviço"
                    />
                  </div>

                  <div class="col-lg-4">
                    <label>Status da negociação:</label>
                    <select-populado
                      :list="situacao"
                      :data="orcamento.situacao"
                      :fieldKey="'id'"
                      :fieldText="'text'"
                      @onChange="atualizaStatus"
                    />
                  </div>

                  <div class="col-lg-2">
                    <br />
                    <label>Contrato:</label> &nbsp;
                    <toggle-button
                      v-model="orcamento.contrato"
                      :sync="true"
                      :labels="true"
                      :font-size="15"
                      :width="80"
                      :height="40"
                    />
                  </div>

                  <div class="col-lg-12">
                    <br />
                    <label>Introdução:</label>
                    <textarea
                      rows="5"
                      class="form-control"
                      placeholder="Observação"
                      v-model="orcamento.observacao"
                    ></textarea>
                  </div>

                  <div class="col-lg-3">
                    <br />
                    <label>Data do Orçamentos:</label>
                    <input
                      type="date"
                      v-model="orcamento.data_orcamento"
                      class="form-control"
                      placeholder="Data de Orcamento"
                      disabled
                    />
                  </div>

                  <div class="col-lg-3">
                    <br />
                    <label>Validade do Orçamento:</label>
                    <input
                      type="date"
                      v-model="orcamento.data_validade"
                      class="form-control"
                      placeholder="Data de validade"
                    />
                  </div>

                  <div class="col-lg-6">
                    <br />
                    <label>Previsão de entrega:</label>
                    <input
                      type="date"
                      v-model="orcamento.data_entrega"
                      class="form-control"
                      placeholder="Data de entrega"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!--end::Card-->
            <!--begin::Card-->
            <div class="card card-custom gutter-b example example-compact">
              <div class="card-header">
                <h3 class="card-title">2. Produtos / Serviços</h3>
              </div>

              <div class="card-body">
                <div class="form-group row">
                  <div id="kt_repeater_1" class="col-lg-12">
                    <div data-repeater-list>
                      <div
                        v-for="(item, index) in orcamento.itens"
                        :key="index"
                        data-repeater-item
                        class="form-group row align-items-center"
                      >
                        <div class="col-lg-4">
                          <label>Serviços:</label>
                          <select-populado
                            :list="servico"
                            :data="item.id_servico"
                            :fieldKey="'id_servico'"
                            :fieldText="'descricao'"
                            @onChange="atualizaServico(item, $event)"
                          />
                        </div>
                        <!--  <div class="col-md-4">
                          <label>Descrição:</label>
                          <div class="input-group">
                            <input
                              type="text"
                              disabled
                              v-model="item.descricao_servico"
                              class="form-control"
                              placeholder="Descrição do Serviço"
                            />
                          </div>
                        </div>-->
                        <div class="col-md-2">
                          <label>Quantidade:</label>
                          <input
                            type="number"
                            v-model="item.quantidade"
                            @change="calcServico(item)"
                            class="form-control"
                            min="1"
                          />
                        </div>
                        <div class="col-md-2">
                          <label>Valor:</label>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">$</span>
                            </div>

                            <money
                              @change.native="calcServico(item)"
                              v-model="item.valor"
                              class="form-control"
                              v-bind="money"
                            ></money>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <label>Valor Total:</label>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">$</span>
                            </div>

                            <money
                              v-model="item.valor_total"
                              disabled
                              class="form-control"
                              v-bind="money"
                            ></money>
                          </div>
                        </div>

                        <div class="col-md-2">
                          <br />
                          <a
                            @click="deletaItem(item)"
                            class="btn btn-sm font-weight-bolder btn-light-danger"
                          >
                            <i class="la la-trash-o"></i>Delete
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-lg-2 col-form-label text-right"></label>
                      <div class="col-lg-4">
                        <a
                          @click="inserirItem()"
                          class="btn btn-sm font-weight-bolder btn-light-primary"
                        >
                          <i class="la la-plus"></i>Add
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12" style="text-align: right">
                    <table
                      class="table table-bordered table-hover table-checkable"
                      id="kt_datatable"
                      style="margin-top: 13px !important"
                    >
                      <tfoot>
                        <th>
                          <b style="margin-right: 80px; font-size: 20px">
                            Valor Total:
                            {{
                              valor_total.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })
                            }}
                          </b>
                        </th>
                      </tfoot>
                    </table>
                  </div>

                  <div class="col-lg-12">
                    <br />
                    <label>Detalhamento:</label>
                    <textarea
                      rows="5"
                      class="form-control"
                      placeholder="Detalhamento dos serviços"
                      v-model="orcamento.descricao"
                    ></textarea>
                  </div>

                  <div class="col-lg-6">
                    <br />
                    <label>Forma de Pagamento:</label>

                    <select-populado
                      :list="formasPagamento"
                      :data="orcamento.id_forma_pagamento"
                      :fieldKey="'id_forma_pagamento'"
                      :fieldText="'descricao'"
                      @onChange="atualizaPagment"
                    />
                  </div>

                  <div class="col-lg-6">
                    <br />
                    <label>Vendedor Responsável:</label>
                    <select-populado
                      :list="funcionarios"
                      :data="orcamento.id_funcionario"
                      :fieldKey="'id_funcionario'"
                      :fieldText="'pessoa.nome'"
                      @onChange="atualizaFuncionarios"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-lg-12">
                    <label>Status:</label> &nbsp;
                    <toggle-button
                      v-model="orcamento.ativo"
                      :sync="true"
                      :labels="true"
                      :font-size="15"
                      :width="80"
                      :height="40"
                    />
                    <span class="form-text text-muted"></span>
                  </div>
                </div>
              </div>

              <div class="card-footer">
                <div class="row">
                  <div class="col-lg-12 ml-lg-auto" id="kt_blockui_content">
                    <button
                      type="button"
                      @click.prevent="salvar"
                      class="btn btn-primary mr-2"
                    >
                      Salvar
                    </button>
                    <button
                      type="button"
                      @click.prevent="salvarEnviar"
                      class="btn btn-dark mr-2"
                    >
                      Salvar e Enviar
                    </button>
                    <button
                      type="button"
                      @click.prevent="voltar"
                      class="btn btn-secondary"
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--begin::Row-->

        <!--end::Row-->
        <!--end::Dashboard-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
</template>

<script>
import SelectPopulado from "../../components/forms/SelectPopulado";
import { Money } from "v-money";
import { mapActions, mapState } from "vuex";
import SubHeader from "../../components/layout/SubHeader";
export default {
  data: () => ({
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      suffix: "",
      precision: 2,
      masked: false,
    },
    orc: {
      id_orcamento: 0,
      descricao: "",
      situacao: "",
      ativo: true,
      contrato: true,
      observacao: "",
      data_orcamento: null,
      data_validade: null,
      data_entrega: null,
      id_forma_pagamento: 0,
      id_cliente: null,
      id_fornecedor: null,
      id_funcionario: 0,
      id_obj: 0,
      itens: [
        {
          id_servico: 0,
          valor: 0,
          quantidade: 1,
          valor_total: 0,
          index: 0,
        },
      ],
    },
    itemServico: {
      id_servico: 0,
      valor: 0,
      quantidade: 0,
      valor_total: 0,
    },
    fonec: [],
    funcionarios: [],
    formasPagamento: [],
    situacao: [
      { id: "Em orçamento".toUpperCase(), text: "Em orçamento".toUpperCase() },
      {
        id: "Orçamento aceito".toUpperCase(),
        text: "Orçamento aceito".toUpperCase(),
      },
      { id: "Recusado".toUpperCase(), text: "Recusado".toUpperCase() },
      { id: "Venda".toUpperCase(), text: "Venda".toUpperCase() },
    ],

    tipos: [],
    servico: [],
    buttons: [
      {
        link: "/orcamento",
        titulo: "Ver Todos Orçamento",
        color: "btn-light-primary",
      },
    ],
    titulo: "Orçamento",
  }),
  components: {
    SelectPopulado,
    Money,
    SubHeader,
  },
  computed: {
    ...mapState("auth", ["empresa","user"]),
    fornecCliente() {
      return this.fonec.map((x) => {
        return {
          id: x.id_cliente ? "C" + x.id_cliente : "F" + x.id_fornecedor,
          id_ob: x.id_cliente ? x.id_cliente : x.id_fornecedor,
          text: x.pessoa.nome,
        };
      });
    },
    orcamento: {
      get() {
        return this.orc;
      },
      set(Nvalue) {
        this.orc = Nvalue;
      },
    },
    valor_total() {
      let ximba = this.orcamento.itens.reduce((acc, value) => {
        return acc + value.valor_total;
      }, 0);
      return ximba;
    },
  },
  methods: {
    ...mapActions("servico", ["ActionGetListServicoActive"]),
    ...mapActions("cliente", ["ActionGetListClienteActive"]),
    ...mapActions("funcionario", ["ActionGetListFuncionarioActive"]),
    ...mapActions("formaPagamento", ["ActionGetListFormasPagamentoActive"]),
    ...mapActions("orcamento", [
      "ActionCreateOrcamento",
      "ActionUpdateOrcamento",
      "ActionCreateOrcamentoEnviar",
      "ActionUpdateOrcamentoEnviar",
      "ActionGetOrcamento",
    ]),
    deletaItem(item) {
      this.orcamento.itens = this.orcamento.itens.filter(
        (f) => f.index != item.index
      );
    },
    inserirItem() {
      this.orcamento.itens.push({
        id_servico: 0,
        valor: 0,
        quantidade: 1,
        valor_total: 0,
        index: this.orcamento.itens.length + 1,
      });
    },
    voltar() {
      this.$router.push("/orcamento");
    },
    salvar() {
      if (this.orcamento.id_obj == 0) {
        this.$swal.fire({
          icon: "warning",
          title: "Aviso",
          text: "Campo Clientes / Fornecedores é obrigatório",
        });
        return;
      }
      if (this.orcamento.situacao == "") {
        this.$swal.fire({
          icon: "warning",
          title: "Aviso",
          text: "Campo Status da negociação é obrigatório",
        });
        return;
      }
      if (this.orcamento.observacao == "") {
        this.$swal.fire({
          icon: "warning",
          title: "Aviso",
          text: "Campo Introdução é obrigatório",
        });
        return;
      }
      if (this.orcamento.data_validade == null) {
        this.$swal.fire({
          icon: "warning",
          title: "Aviso",
          text: "Campo Validade do Orçamento é obrigatório",
        });
        return;
      }
      if (this.orcamento.data_entrega == null) {
        this.$swal.fire({
          icon: "warning",
          title: "Aviso",
          text: "Campo Previsão de entrega é obrigatório",
        });
        return;
      }
      let dap = this.orcamento.itens.filter((f) => f.id_servico == 0);
      if (dap.length == this.orcamento.itens.length) {
        this.$swal.fire({
          icon: "warning",
          title: "Aviso",
          text: "Favor selecionar Serviço",
        });
        return;
      }
      if (this.orcamento.id_forma_pagamento == 0) {
        this.$swal.fire({
          icon: "warning",
          title: "Aviso",
          text: "Campo Forma de Pagamento é obrigatório",
        });
        return;
      }
      if (this.orcamento.id_funcionario == 0) {
        this.$swal.fire({
          icon: "warning",
          title: "Aviso",
          text: "Campo Vendedor Responsável é obrigatório",
        });
        return;
      }
      let r;
      let y = this.fornecCliente.filter(
        (x) => this.orcamento.id_obj == x.id
      )[0];
      if (y.id.includes("C")) {
        this.orc.id_cliente = y.id_ob;
      } else if (y.id.includes("F")) {
        this.orc.id_fornecedor = y.id_ob;
      }
      this.orcamento["valor_total"] = this.valor_total;
      if (this.$route.params.id == 0) {
        r = this.ActionCreateOrcamento(this.orcamento);
      } else {
        r = this.ActionUpdateOrcamento(this.orcamento);
      }
      window.KTApp.blockPage({
        overlayColor: "#000000",
        state: "primary",
        message: "Carregando...",
      });
      r.then((x) => {
        window.KTApp.unblockPage();
        if (x.sucesso == true) {
          this.$swal
            .fire({
              icon: x.sucesso == true ? "success" : "error",
              title: x.sucesso == true ? `Sucesso` : "Atenção / Erro",
              text:
                x.sucesso == true
                  ? `Orçamento ${
                      this.$route.params.id == 0 ? "adicionado" : "atualizado"
                    } com sucesso`
                  : x.error.message,
            })
            .then(() => {
              this.$router.push("/orcamento");
            });
        }
      });
    },
    salvarEnviar() {
      if (this.orcamento.id_obj == 0) {
        this.$swal.fire({
          icon: "warning",
          title: "Aviso",
          text: "Campo Clientes / Fornecedores é obrigatório",
        });
        return;
      }
      if (this.orcamento.situacao == "") {
        this.$swal.fire({
          icon: "warning",
          title: "Aviso",
          text: "Campo Status da negociação é obrigatório",
        });
        return;
      }
      if (this.orcamento.observacao == "") {
        this.$swal.fire({
          icon: "warning",
          title: "Aviso",
          text: "Campo Introdução é obrigatório",
        });
        return;
      }
      if (this.orcamento.data_validade == null) {
        this.$swal.fire({
          icon: "warning",
          title: "Aviso",
          text: "Campo Validade do Orçamento é obrigatório",
        });
        return;
      }
      if (this.orcamento.data_entrega == null) {
        this.$swal.fire({
          icon: "warning",
          title: "Aviso",
          text: "Campo Previsão de entrega é obrigatório",
        });
        return;
      }
      let dap = this.orcamento.itens.filter((f) => f.id_servico == 0);
      if (dap.length == this.orcamento.itens.length) {
        this.$swal.fire({
          icon: "warning",
          title: "Aviso",
          text: "Favor selecionar Serviço",
        });
        return;
      }
      if (this.orcamento.id_forma_pagamento == 0) {
        this.$swal.fire({
          icon: "warning",
          title: "Aviso",
          text: "Campo Forma de Pagamento é obrigatório",
        });
        return;
      }
      if (this.orcamento.id_funcionario == 0) {
        this.$swal.fire({
          icon: "warning",
          title: "Aviso",
          text: "Campo Vendedor Responsável é obrigatório",
        });
        return;
      }

      window.KTApp.blockPage({
        overlayColor: "#000000",
        state: "primary",
        message: "Carregando...",
      });
      let r;
      let y = this.fornecCliente.filter(
        (x) => this.orcamento.id_obj == x.id
      )[0];
      if (y.id.includes("C")) {
        this.orc.id_cliente = y.id_ob;
      } else if (y.id.includes("F")) {
        this.orc.id_fornecedor = y.id_ob;
      }
      this.orcamento["valor_total"] = this.valor_total;
      if (this.$route.params.id == 0) {
        r = this.ActionCreateOrcamentoEnviar(this.orcamento);
      } else {
        r = this.ActionUpdateOrcamentoEnviar(this.orcamento);
      }
      r.then((x) => {
        window.KTApp.unblockPage();
        if (x.sucesso == true) {
          this.$swal
            .fire({
              icon: x.sucesso == true ? "success" : "error",
              title: x.sucesso == true ? `Sucesso` : "Atenção / Erro",
              text:
                x.sucesso == true
                  ? `Orçamento ${
                      this.$route.params.id == 0 ? "adicionado" : "atualizado"
                    } com sucesso`
                  : x.error.message,
            })
            .then(() => {
              this.$router.push("/orcamento");
            });
        }
      });
    },
    calcServico(item) {
      item.valor_total = item.quantidade * item.valor;
    },
    atualizaServico(dado, item) {
      dado.id_servico = item;
      dado.valor = this.servico.find((f) => f.id_servico == item).valor;
      this.calcServico(dado);
    },
    ChangeFonecCliente(item) {
      this.orcamento.id_obj = item;
    },
    atualizaFuncionarios(item) {
      this.orcamento.id_funcionario = item;
    },
    atualizaPagment(item) {
      this.orcamento.id_forma_pagamento = item;
    },
    atualizaStatus(item) {
      this.orcamento.situacao = item;
    },
  },
  mounted() {
    this.ActionGetListServicoActive().then((r) => {
      this.servico = r;
    });
    this.ActionGetListClienteActive().then((r) => {
      this.fonec = r;
    });
    this.ActionGetListFuncionarioActive().then((r) => {
      this.funcionarios = r;
    });
    this.ActionGetListFormasPagamentoActive().then((r) => {
      this.formasPagamento = r;
    });
    this.orcamento.data_orcamento = this.$moment(new Date()).format(
      "YYYY-MM-DD"
    );
    if (this.$route.params.id != 0) {
      this.opcao = "E";
      window.KTApp.blockPage({
        overlayColor: "#000000",
        state: "primary",
        message: "Carregando...",
      });
      this.ActionGetOrcamento(this.$route.params.id).then((r) => {
        this.orcamento = r;
        this.orcamento["id_obj"] = r.id_cliente
          ? "C" + r.id_cliente
          : "F" + r.id_fornecedor;
      });
    }
    window.KTApp.unblockPage();
  },
  watch: {
    empresa(nValue) {
      this.empresa = nValue;
      this.orcamento.id_empresa = this.empresa.id_empresa;
      if (this.opcao == "E") {
        this.$swal
          .fire({
            icon: "warning",
            title: "Atenção",
            text:
              "Houve mudança de empresa, você será redirecionado para a lista de Orçamentos ",
          })
          .then(() => this.$router.push("/orcamento"));
      }
    },
  },
};
</script>
